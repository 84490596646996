import React, { useContext, useEffect,useState } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import {  Modal } from "react-bootstrap";
import Select from 'react-select'
import CancelButton from '../../Components/CancelButton/CancelButton'
import { permissionsContext } from '../../App'
import deleteIcon from '../../Assets/delete-icon.svg'
import Swal from 'sweetalert2'
import { TopBarTitleContext } from '../../App'
import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { MDBTooltip } from 'mdb-react-ui-kit';

var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getUserPermissionsUrl =  axiosUrls.getUserPermissions
const assignRoleAndPermissionUrl = axiosUrls.assignRoleAndPermission
const addNewUserUrl = axiosUrls.addNewUser
const deleteUser = axiosUrls.deleteUser


export default function Permissions() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [permissionsData,setPermissionsData] = useState([])
    const [errors, setErrors] = useState({})
    const [isDetailModal,setIsDetailModal] = useState(false)
    const [userData,setUserData] = useState({})

    const [isNewUser,setIsNewUser] = useState(false)
    const [newUserData,setNewUserData] = useState([])

    const permissions = useContext(permissionsContext)
    const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Settings')

    useEffect(()=>{
        getUserPermissionsApi()
    },[])

    const columns = [
        {
          label: 'Username',
          field: 'name',
        }, {
          label: 'Email Address',
          field: 'email',
        }, {
          label: 'Creation Date',
          field: 'creationDate'
        }, {
          label: 'Permissions',
          field: 'details'
        }, 
        permissions.includes('Can Delete User') && {
          label: 'Action',
          field: 'action'
        }
      ].filter(Boolean)

      const handleOnRoleChange = (e) =>{
        setUserData(prevState =>({
            ...prevState,
            ['userRoles'] : e
        }))
      }

      const handleOnPermissionChange = (e) =>{
        setUserData(prevState =>({
            ...prevState,
            ['userPermissions'] : e
        }))
      }

      const handleUserDataChange = (e) =>{
        const {name,value} =e.target
        setNewUserData(prevState =>({
          ...prevState,
          [name] : value
        }))
      }

      const moreDetail = (data) =>{
        setUserData({
        id:data.id,
        email:data.email,
        availablePermissions:data.availablePermission,
        userPermissions :data.permissions,
        availableRoles:data.availableRoles,
        userRoles:data.roles
        })
        setIsDetailModal(true)
      }


    const getUserPermissionsApi = () =>{
        setLoader(true)
        apiCounter++
        axios.get(getUserPermissionsUrl,{
            headers : {
                'Authorization' : token
            }
        }).then(response =>{
            console.log(response)
            const data = response.data
            for(let i=0 ; i<data.length;i++){
                const availablePermissions = []
                data[i].availablePermission.forEach(element => {
                    availablePermissions.push({value:element.name,label:element.name})
                });
                data[i].availablePermission = availablePermissions


                const userPermissions = []
                data[i].permissions.forEach(element => {
                    userPermissions.push({value:element.name,label:element.name})
                });

                data[i].permissions = userPermissions


                const availableRoles = []
                data[i].availableRoles.forEach(element => {
                    availableRoles.push({value:element.name,label:element.name})
                });
                data[i].availableRoles = availableRoles


                const userRoles = []
                data[i].roles.forEach(element => {
                    userRoles.push({value:element.name,label:element.name})
                });

                data[i].roles = userRoles
                
                data[i]['details'] = <button className='detail-btn' onClick={()=>{moreDetail(data[i])}}>Details</button>
                {
                  permissions.includes('Can Delete User') && (data[i]['action'] = 
                  <MDBTooltip tag='span' wrapperClass="" title="Delete Permission" placement='bottom'>

                <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteUserApi(data[i].id) }}><img src={deleteIcon} alt="" /></button>
              </MDBTooltip>)
              }

            }

            setPermissionsData({columns:columns,rows:response.data})
            apiCounter--
            if(apiCounter === 0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)
            apiCounter--
            if(apiCounter === 0){
                setLoader(false)
            }
        })
    }

    const assignRolePermissionApi = (e) =>{
        setLoader(true)
        apiCounter++
        e.preventDefault()
        
        const selectedRoles = []
        userData.userRoles.forEach(element => {
            selectedRoles.push(element.value)
        });
        const selectedPermissions = []
        userData.userPermissions.forEach(element => {
            selectedPermissions.push(element.value)
        });
        const data ={id:userData.id,selectedRoles:selectedRoles,selectedPermissions:selectedPermissions}
        axios.post(assignRoleAndPermissionUrl,data,{
            headers : {
                'Authorization' : token
            }
        }).then(response =>{
            console.log(response)
            setIsDetailModal(false)
            setUserData({})
            setErrors({})
            getUserPermissionsApi()
            Swal.fire(
              'Success',
              'Assigned successfully',
              'success'
            )
            apiCounter--
            if(apiCounter===0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)
            if(error.response.status === 422){
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if(apiCounter===0){
                setLoader(false)
            }
        })
    }

    const addNewUserApi = (e) =>{
      e.preventDefault()
      setLoader(true)
      apiCounter++
      axios.post(addNewUserUrl,newUserData,{
        headers: {
          'Authorization' : token
        }
      }).then(response=>{
        console.log(response)
        setErrors({})
        setNewUserData({})
        setIsNewUser(false)
        getUserPermissionsApi()
        Swal.fire(
          'Success',
          'User added successfully',
          'success'
        )
        apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
      }).catch(error =>{
        console.log(error)
        if(error.response.status === 422){
          setErrors(error.response.data.errors)
        }
        apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
      })
    }
    

    const deleteUserApi = (id) =>{
      if(window.confirm('Sure Want to delete user')){

      }
    }
    if (loader) {
        return <Loader />
    }
    else {

        return (
            <div className="h-100">
                <div className="row">
                    <div className="col-6 desktop">
                        <span className='font-2 semi-bold'>Authentication & Authorization</span>
                    </div>

                    <div className="col-sm-6" style={{ textAlign: 'end' }}>
                        {/* <BlackButton text="Add Category" className="mr-3 font-2" style={{padding:'7px'}}/> */}
                        {permissions.includes('Can Add New User') && <GradientButton text="Add New User" onClick={()=>{setIsNewUser(true)}}/>}
                        
                        {/* <DownloadButton className="ml-3" style={{padding:'7px'}}/> */}
                    </div>



                </div>
                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={permissionsData}/>
                </div>

                <div className="mobile row py-2">
                    <div className="col-12">

                    <p className='font-2 semi-bold mb-1'>Authentication & Authorization</p>
                    </div>

                    {
                        permissionsData.rows && permissionsData.rows.map((data, index) => {
                            return <div className="col-12 " >
                              <div className='mobile-item-box-div my-1' onClick={()=>{moreDetail(data)}}>
                              <div className="d-flex justify-content-between py-1">
                                    <span className='font-2'>{data.name}</span>
                                    <span className='font-2'>
                                        
                                        {
                                            permissions.includes('Can Delete User') && <button style={{ backgroundColor: 'white', border: 'none' }} onClick={() => { deleteUserApi(data.id) }}> <img src={deleteIcon} alt="" /></button>
                                        }

                                    </span>

                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className='font-2'>{data.email}</span>
                                    <span className='font-2'>{data.creationDate}</span>
                                </div>
                              </div>
                                
                            </div>
                        })
                    }


                </div>

                <Modal
              show={isDetailModal}
              onHide={() => { setIsDetailModal(false);setUserData({}) }}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
    
              <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>
    
                <form className='p-3 h-100' onSubmit={assignRolePermissionApi}>
                  <p className='text-center font-2 semi-bold'>Role & Permissions</p>
    
    
                  <div className="row" >
                    
    
                    <div className="col-12 py-1">
                      <label htmlFor="email" className='m-0 font-2'>Email Address</label>
                      <input type="email" id="email" name="emailAddress" value={userData.email} className={'form-control font-2 ' + (errors['emailAddress'] ? 'is-invalid' : '')}   readOnly={true}/>
                      
                    </div>
    
                    <div className="col-12 py-1">
                      <label  className='m-0 font-2'>Select Role</label>
                      {
                        console.log(userData.availablePermissions)
                      }
                      <Select isMulti options={userData.availableRoles} value={userData.userRoles} onChange={handleOnRoleChange} className='form-select is-invalid'>
                      </Select>
                      <div className="invalid-feedback">
                        {errors['selectedRoles'] && errors['selectedRoles']}
                      </div>
                    </div>

                    <div className="col-12 py-1">
                      <label  className='m-0 font-2'>Select Permissions</label>
                      <Select isMulti options={userData.availablePermissions} value={userData.userPermissions} onChange={handleOnPermissionChange}>
                      </Select>
                      <div className="invalid-feedback">
                        {errors['selectedPermissions'] && errors['selectedPermissions']}
                      </div>
                    </div>

                    
    
    
    
                    
    
    
                  </div>
    
                  <div className="d-flex justify-content-end mt-2 pt-1">
                    <CancelButton onClick={(e) => {e.preventDefault();setIsDetailModal(false);setUserData({})}} />
                    <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
                  </div>
    
    
                </form>
              </Modal.Body>
              </Modal>

              <Modal
              show={isNewUser}
              onHide={() => { setIsNewUser(false);setNewUserData({}) }}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
    
              <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>
    
                <form className='p-3 h-100' onSubmit={addNewUserApi}>
                  <p className='text-center font-2 semi-bold'>Add New User</p>
    
    
                  <div className="row" >
                    <div className="col-12">
                      <div className="row align-items-center my-2">
                        <div className="col-5"><label htmlFor="username">Username</label></div>
                        <div className="col-7">
                        <input type="text" id="username" name="username" value={newUserData.username} className={'form-control font-2 ' +(errors['username']?'is-invalid':'')} onChange={handleUserDataChange}/>
                        <div className="invalid-feedback">
                                                {errors['username'] && errors['username']}
                                            </div>
                        </div>
                      
                      </div>
                      <div className="row align-items-center my-2">
                        <div className="col-5"><label htmlFor="email">Email</label></div>
                        <div className="col-7">
                        <input type="email" id="email" name="email" value={newUserData.email} className={'form-control font-2 ' +(errors['email']?'is-invalid':'')} onChange={handleUserDataChange}/>
                        <div className="invalid-feedback">
                                                {errors['email'] && errors['email']}
                                            </div>
                        </div>
                      </div>
                      <div className="row align-items-center my-2">
                        <div className="col-5"><label htmlFor="password">Password</label></div>
                        <div className="col-7">
                        <input type="password" id="password" name="password" value={newUserData.password} className={'form-control font-2 ' +(errors['password']?'is-invalid':'')} onChange={handleUserDataChange}/>
                        <div className="invalid-feedback">
                                                {errors['password'] && errors['password']}
                                            </div>
                        </div>
                      </div>
                      <div className="row align-items-center my-2">
                        <div className="col-5"><label htmlFor="password_confirmation">Confirm Password</label></div>
                        <div className="col-7">
                        <input type="password" id="password_confirmation" name="password_confirmation" value={newUserData.password_confirmation} className={'form-control font-2 ' +(errors['password_confirmation']?'is-invalid':'')} onChange={handleUserDataChange}/>
                        </div>
                      </div>
                    </div>
    
                  </div>
    
                  <div className="d-flex justify-content-end mt-2 pt-1">
                    <CancelButton onClick={(e) => {e.preventDefault();setIsNewUser(false);setNewUserData({})}} />
                    <BlackButton text="Save" style={{ width: '70px' }} type='submit' />
                  </div>
    
    
                </form>
              </Modal.Body>
              </Modal>
            </div>
        )
    }
}
