import React, { useEffect, useState } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import editIcon from '../../Assets/edit-icon.svg'
import deleteIcon from '../../Assets/delete-icon.svg'
import Swal from 'sweetalert2'
import { Modal, Form } from 'react-bootstrap'

import { permissionsContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useContext } from 'react'
import { MDBTooltip } from 'mdb-react-ui-kit';


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getRequestableInventoryItemUrl = axiosUrls.Requestable_get
const addRequestableInventoryItemUrl = axiosUrls.Requestable_add
const editRequestableItemUrl = axiosUrls.Requestable_edit
const deleteRequestableItemUrl = axiosUrls.Requestable_delete



export default function InventoryItems() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [inventoryItems, setInventoryItems] = useState([])
    const [isAddNew, setIsAddNew] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [addNewInventoryData, setAddNewInventoryData] = useState({ itemName: '', itemPrice: 0 })
    const [editItem, setEditItem] = useState({ itemName: '', itemPrice: 0 })
    const [errors, setErrors] = useState({})

    const permissions = useContext(permissionsContext)


    useEffect(() => {
        getRequestableInventoryItem()
    }, [])



    const columns = [
        {
            label: 'Item Name',
            field: 'itemName',

        },
        {
            label: 'Date',
            field: 'date',

        },
        {
            label: 'Item Price',
            field: 'itemPrice',

        },
        (permissions.includes('Can Edit Requestable Inventory') || permissions.includes('Can Delete Requestable Inventory')) && {
            label: 'Action',
            field: 'action',

        },


    ].filter(Boolean)

    const handleNewItemChange = (e) => {

        const { name, value } = e.target

        setAddNewInventoryData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }


    const handleEditChange = (e) => {

        const { name, value } = e.target

        setEditItem(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const cancelAdd = () => {
        setIsAddNew(false)
        setAddNewInventoryData({ itemName: '', itemPrice: 0 })
    }



    const getRequestableInventoryItem = () => {
        setLoader(true);
        apiCounter++;
        axios
          .get(getRequestableInventoryItemUrl, {
            headers: {
              'Authorization': token
            }
          })
          .then(response => {
            console.log(response);
            apiCounter--;
            const data = response.data.map((item, index) => ({
              ...item,
              action: (
                <div className='d-flex justify-content-start align-items-center'>
                  {permissions.includes('Can Edit Requestable Inventory') && (
                    <MDBTooltip tag='span' wrapperClass="" title="Edit Inventory Item" placement='bottom'>
                    <button
                      style={{ backgroundColor: 'transparent', border: 'none' }}
                      onClick={() => {
                        // console.log(index, '----------------');
                        setIsEdit(true);
                        setEditItem({
                          id: item.id,
                          itemName: item.itemName,
                          itemPrice: item.itemPrice,
                        });
                      }}
                    >
                      <img src={editIcon} alt="" />
                    </button>
                  </MDBTooltip>
                    
                  )}
                  {permissions.includes('Can Delete Requestable Inventory') && (
                    <MDBTooltip tag='span' wrapperClass="" title="Delete Inventory Item" placement='bottom'>
                    <button
                      style={{ backgroundColor: 'transparent', border: 'none' }}
                      onClick={() => {
                        deleteRequestableItemApi(item.id);
                      }}
                    >
                      <img src={deleteIcon} alt="" />
                    </button>
                  </MDBTooltip>
                    
                  )}
                </div>
              ),
            }));
      
            setInventoryItems({ columns: columns, rows: data });
      
            if (apiCounter === 0) {
              setLoader(false);
            }
          })
          .catch(error => {
            console.log(error);
            apiCounter--;
            if (apiCounter === 0) {
              setLoader(false);
            }
          });
      };

    const addNewItem = () => {



        setLoader(true)
        apiCounter++
        axios.post(addRequestableInventoryItemUrl, {
            itemName: addNewInventoryData.itemName,
            itemPrice: addNewInventoryData.itemPrice
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            Swal.fire(
                'Success',
                'New inventory item addedd',
                'success'
              )
            getRequestableInventoryItem()
            setAddNewInventoryData({ itemName: '', itemPrice: 0 })
            setIsAddNew(false)
            setErrors({})
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            else{
                setErrors({})
                setIsAddNew(false)
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })


    }

    const editItemApi = () => {
        
            setLoader(true)
            apiCounter++
            axios.post(editRequestableItemUrl, editItem, {
                headers: {
                    'Authorization': token
                }
            }).then(response => {
                console.log(response)
                Swal.fire(
                    'Success',
                    'Inventory is updated successfully',
                    'success'
                  )
                getRequestableInventoryItem()
                setEditItem({ itemName: '', itemPrice: 0 })
                setIsEdit(false)

                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }

            }).catch(error => {
                console.log(error)
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors)
                }
                else{
                    setErrors({})
                    setIsAddNew(false)
                    setIsEdit(false)
                }
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }
            })
        
    }


    const deleteRequestableItemApi = (id) => {
        if (window.confirm('Are you sure to delete item')) {
            setLoader(true)
            apiCounter++
            axios.get(deleteRequestableItemUrl + '/' + id, {
                headers: {
                    'Authorization': token
                }
            }).then(response => {
                console.log(response)
                Swal.fire(
                    'Success',
                    'Inventory item is deleted successfully',
                    'success'
                  )
                getRequestableInventoryItem()
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }
            }).catch(error => {
                console.log(error)
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }
            })
        }
    }






    if (loader) {
        return <Loader />
    }
    else {


        return (
            <div className="h-100">
                <div className="row">
                    <div className="col-3 desktop">
                        <span className='font-2 semi-bold'>Inventory Items</span>
                    </div>
                    <div className="col-sm-9 ">
                        <div className="d-flex justify-content-end">
                            {permissions.includes('Can Add Requestable Inventory') && <GradientButton text="Add Item" onClick={() => { setIsAddNew(true) }} />}
                            <DownloadButton className="ml-3" data={inventoryItems} filename="InventoryItemsReport.csv" />
                        </div>
                    </div>
                </div>

                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={inventoryItems} />
                </div>

                <div className="mobile row py-2">
                    <div className="col">

                        <p className='font-2 semi-bold mb-1'>Inventory Request</p>
                    </div>

                    {
                        inventoryItems.rows && inventoryItems.rows.map((data, index) => {
                            return <div className="col-12 " >
                                <div className="mobile-item-box-div my-1">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className='font-2'>{data.itemName}</span>
                                        <span className='font-2'>
                                            {
                                                permissions.includes("Can Edit Requestable Inventory") && <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { setIsEdit(true); setEditItem({ itemName: data.itemName, itemPrice: data.itemPrice }) }}> <img src={editIcon} alt="" /></button>
                                            }
                                            {
                                                permissions.includes('Can Delete Requestable Inventory') && <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { deleteRequestableItemApi(data.id) }}> <img src={deleteIcon} alt="" /></button>
                                            }

                                        </span>

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className='font-2'>{data.date}</span>
                                        <span className='font-2'>Item Price: {data.itemPrice}</span>
                                    </div>
                                </div>

                            </div>
                        })
                    }


                </div>

                <Modal
                    show={isAddNew}
                    onHide={() => { setIsAddNew(false) }}
                    size='md'
                    centered
                >
                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }} >
                        <div className="p-3">
                            <p className='text-center font-2 semi-bold'>Add New Item</p>


                            <div className="row my-3">




                                <div className="col-12 my-2">

                                    <div className="row">
                                        <div className="col-12 my-1">

                                            <label htmlFor="itemName" className='font-2'>Item Name</label>
                                            <Form.Control type="text" className={'w-100 font-2 form-control ' + (errors['itemName'] ? 'is-invalid' : '')} name="itemName" value={addNewInventoryData.itemName} onChange={handleNewItemChange} />
                                            <div className="invalid-feedback">
                                                {errors['itemName'] && errors['itemName']}
                                            </div>

                                        </div>
                                        <div className="col-12  my-1">
                                            <label htmlFor="itemPrice" className='font-2'>Item Price</label>
                                            <Form.Control id='itemPrice' type="number" className={'w-100 font-2 form-control ' + (errors['itemPrice'] ? 'is-invalid' : '')} name="itemPrice" value={addNewInventoryData.itemPrice} onChange={handleNewItemChange} />
                                            <div className="invalid-feedback">
                                                {errors['itemPrice'] && errors['itemPrice']}
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>

                            <div className="d-flex align-items-center justify-content-end">
                                <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: 'white',
                                    width: '70px'
                                }} className='font-2 mx-1' onClick={cancelAdd}>Cancel</button> <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: '#232529', color: 'white',
                                    width: '70px'
                                }} className='font-2 mx-1' onClick={addNewItem}>Add</button>
                            </div>


                        </div>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={isEdit}
                    onHide={() => { setIsEdit(false) }}
                    size='md'
                    centered
                >
                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }} >
                        <div className="p-3">
                            <p className='text-center font-2 semi-bold'>Edit Item</p>


                            <div className="row">
                                        <div className="col-12 my-1">

                                            <label htmlFor="itemName" className='font-2'>Item Name</label>
                                            <Form.Control type="text" className={'w-100 font-2 form-control ' + (errors['itemName'] ? 'is-invalid' : '')} name="itemName" value={editItem.itemName} onChange={handleEditChange} />
                                            <div className="invalid-feedback">
                                                {errors['itemName'] && errors['itemName']}
                                            </div>

                                        </div>
                                        <div className="col-12  my-1">
                                            <label htmlFor="itemPrice" className='font-2'>Item Price</label>
                                            <Form.Control id='itemPrice' type="number" className={'w-100 font-2 form-control ' + (errors['itemPrice'] ? 'is-invalid' : '')} name="itemPrice" value={editItem.itemPrice} onChange={handleEditChange} />
                                            <div className="invalid-feedback">
                                                {errors['itemPrice'] && errors['itemPrice']}
                                            </div>
                                        </div>


                                    </div>

                            <div className="d-flex align-items-center justify-content-end">
                                <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: 'white',
                                    width: '70px'
                                }} className='font-2 mx-1' onClick={() => { setIsEdit(false); setEditItem({ itemName: '', itemPrice: 0 }) }}>Cancel</button> <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: '#232529', color: 'white',
                                    width: '70px'
                                }} className='font-2 mx-1' onClick={editItemApi}>Save</button>
                            </div>


                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        )
    }
}
