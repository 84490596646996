import React, { useEffect, useState, useContext } from 'react'
import CustomSelect from '../../Components/CustomSelect/CustomSelect'
import GradientButton from '../../Components/GradientButton/GradientButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import PlusButton from '../../Components/PlusButton/PlusButton'
import MinusButton from '../../Components/MinusButton/MinusButton'
import CrossButton from '../../Components/CrossButton/CrossButton'
import { Modal } from "react-bootstrap"
import Loader from '../../Components/Loader/Loader'
import { TopBarTitleContext } from '../../App'
import Swal from 'sweetalert2'


import { permissionsContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const addAccessoriesUrl = axiosUrls.FixedInventoryAdd
const getAccessoriesUrl = axiosUrls.FixedInventoryGet


export default function Accessories() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [isAssignAccessories, setIsAssignAccessories] = useState(false)

    const [addAccessories, setAddAccessories] = useState({})
    const [isNewItem, setIsNewItem] = useState(true)
    const [newItem, setNewItem] = useState('')

    const [selectedKiosk, setSelectedKiosk] = useState()
    const [selectedKioskAssign, setSelectedKioskAssign] = useState()

    const [assignedAccessoriesData, setAssignedAccessoriesDate] = useState([])
    const [errors, setErrors] = useState([])

    const [topBarTitle, setTopBarTitle] = useContext(TopBarTitleContext)

    const permissions = useContext(permissionsContext)


    setTopBarTitle('Inventory Management')

    useEffect(() => {
        getAccessoriesApi()
    }, [selectedKiosk])

    const columns = [
        {
            label: 'Kiosk Name',
            field: 'kioskName',

        },
        {
            label: 'Item Name',
            field: 'itemName',

        },
        {
            label: 'Accessories Assigned',
            field: 'accessoriesAssigned',

        },
        {
            label: 'Date',
            field: 'date',

        },


    ]

    const addItem = () => {
        if (newItem) {
            setAddAccessories(prevState => ({
                ...prevState,
                [newItem]: 1
            }))
            setNewItem('')
            setIsNewItem(false)
        }
    }
    const removeItem = (name) => {
        const copyaddAccessories = { ...addAccessories }

        delete copyaddAccessories[name]
        setAddAccessories(copyaddAccessories)
    }

    const removeNewItem = () => {
        setIsNewItem('')
        setIsNewItem(false)
    }

    const plusItem = (name) => {
        setAddAccessories(prevState => ({
            ...prevState,
            [name]: addAccessories[name] + 1
        }))
    }

    const minusItem = (name) => {
        if (addAccessories[name] > 1) {

            setAddAccessories(prevState => ({
                ...prevState,
                [name]: addAccessories[name] - 1
            }))
        }
    }

    const getAccessoriesApi = () => {
        setLoader(true)
        apiCounter++
        axios.post(getAccessoriesUrl, {
            kiosk: selectedKiosk
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setAssignedAccessoriesDate({ columns: columns, rows: response.data })
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    const assignAccessoriesApi = () => {

        setLoader(true)
        apiCounter++
        const data = []
        Object.keys(addAccessories).map((key, index) => {
            data.push({ item: key, quantity: addAccessories[key] })
        })

        axios.post(addAccessoriesUrl, {
            kiosk: selectedKioskAssign,
            items: data

        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setErrors([])
            setIsAssignAccessories(false)
            setSelectedKioskAssign()
            setAddAccessories([])
            getAccessoriesApi()
            Swal.fire(
                'Success',
                'Fixed accessories assigned successfully',
                'success'
              )
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.message)
            }
            else {
                setErrors([])
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })

    }

    if (loader) {
        return <Loader />
    }
    else {

        return (
            <div className="h-100">
                <div className="row reverse-col">
                    <div className="col-sm-3 my-1">
                        <CustomSelect value={selectedKiosk} handleOnChange={setSelectedKiosk} text="All Kiosks"/>
                    </div>
                    <div className="col-sm-9 my-1">
                        <div className="d-flex justify-content-end">
                            {
                                permissions.includes('Can Add Fix Accessories') && <GradientButton text="Assign Accessories" onClick={() => { setIsAssignAccessories(true); setIsNewItem(true) }} />
                            }

                            <DownloadButton className="ml-3" data={assignedAccessoriesData} filename="AccessoriesReport.csv" />
                        </div>
                    </div>
                </div>

                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={assignedAccessoriesData} />
                </div>


                <div className="mobile row py-2">
                    <div className="col">

                        <p className='font-2 semi-bold mb-1'>Accessories</p>
                    </div>

                    {
                        assignedAccessoriesData.rows && assignedAccessoriesData.rows.map((data, index) => {
                            return <div className="col-12 " >
                                <div className="mobile-item-box-div my-1">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className='font-2'>{data.kioskName}</span>

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className='font-2'>Item Name: {data.itemName}</span>
                                        <span className='font-2'>Accessories Assigned: {data.accessoriesAssigned}</span>
                                    </div>
                                </div>

                            </div>
                        })
                    }


                </div>

                <Modal
                    show={isAssignAccessories}
                    onHide={() => { setIsAssignAccessories(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered

                >

                    <Modal.Body style={{ maxHeight: "80vh", overflow: 'auto' }}>



                        <div className='h-100' >
                            <p className='text-center font-2 semi-bold'>Assign Accessories</p>
                            <div className="row">
                                <div className="col-9">
                                    <CustomSelect value={selectedKioskAssign} handleOnChange={setSelectedKioskAssign} text="Select Kiosk"/>
                                </div>
                                <div className="col-3 d-flex align-items-center">
                                    <BlackButton text="Add Item" style={{ padding: '4px' }} className="font-2" onClick={() => { setIsNewItem(true) }} />
                                </div>
                            </div>
                            <div className='my-3'>
                                {
                                    Object.keys(addAccessories).map((name, index) => {
                                        return (<div className="row my-2">
                                            <div className="col-11 ">
                                                <div className='item-box p-2'>
                                                    <div className="row">
                                                        <div className="col-1 font-2 d-flex justify-content-between align-items-center pr-0">
                                                            {index + 1}.
                                                        </div>
                                                        <div className="col-8 d-flex justify-content-between font-2">
                                                            {name}
                                                        </div>
                                                        <div className="col-3 d-flex justify-content-between align-items-center font-2">
                                                            <MinusButton onClick={() => { minusItem(name) }} />
                                                            {addAccessories[name]}
                                                            <PlusButton onClick={() => { plusItem(name) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                <CrossButton onClick={() => { removeItem(name) }} />
                                            </div>
                                        </div>)
                                    })
                                }

                            </div>
                            {
                                isNewItem && <div className='my-3'>


                                    <div className="row">
                                        <div className="col-9 ">
                                            <div className='item-box p-2'>
                                                <div className="row">
                                                    <div className="col-1 font-2 d-flex justify-content-between align-items-center">
                                                        {Object.keys(addAccessories).length + 1}.

                                                    </div>
                                                    <div className="col-9 d-flex justify-content-between font-2">
                                                        <input type="text" name="newitem" className='font-2 w-100' value={newItem} placeholder='Item Name' style={{ border: 'none' }} onChange={(e) => { setNewItem(e.target.value) }} />
                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-2 d-flex align-items-center">
                                            <BlackButton text="Add" style={{ padding: '4px' }} className="font-2" onClick={addItem} />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <CrossButton onClick={removeNewItem} />
                                        </div>
                                    </div>





                                </div>
                            }
                            {
                                errors && <div className='font-2 mb-2' style={{ color: 'red' }}>{errors}</div>
                            }

                            <div className="d-flex align-items-center justify-content-end">
                                <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: 'white',
                                    width: '70px'
                                }} className='font-2 mx-1' onClick={() => { setIsAssignAccessories(false); setAddAccessories({}) }}>Cancel</button> <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: '#232529', color: 'white',
                                    width: '70px'
                                }} className='font-2 mx-1' onClick={assignAccessoriesApi}>Add</button>
                            </div>






                        </div>







                    </Modal.Body>

                </Modal>
            </div>
        )
    }
}
