import React from 'react'
import './DownloadButton.css'
import downloadIcon from '../../Assets/download-icon.svg'
import { CSVLink } from "react-csv";
import { MDBTooltip } from 'mdb-react-ui-kit';


export default function DownloadButton(props) {

  var columns = []
  var data = []

  if(props.data.length && props.columns.length){

    data = props.data

    for (let i = 0; i < props.columns.length; i++) {

      if (props.columns[i].dataField !== 'action' && props.columns[i].dataField !== 'details' && props.columns[i].dataField !== 'productImage' && props.columns[i].dataField !== 'cnicImage' && props.columns[i].dataField !== 'status') {

        columns.push({ label: props.columns[i].dataField, key: props.columns[i].dataField })
      }
    }

  }
  else{
    if (props.data !== undefined) {
      if (props.data.columns !== undefined) {
  
        for (let i = 0; i < props.data.columns.length; i++) {
          if (props.data.columns[i].field !== 'action' && props.data.columns[i].field !== 'details' && props.data.columns[i].field !== 'productImage' && props.data.columns[i].field !== 'cnicImage' && props.data.columns[i].field !== 'status') {
  
            columns.push({ label: props.data.columns[i].label, key: props.data.columns[i].field })
          }
  
        }
  
        if (props.data.rows !== undefined) {
          data = props.data.rows;
        }
      }
  
  
  
  
  
    }
  }

  


  const csvReport = {
    data: data,
    headers: columns,
    filename: props.filename
  };
  // console.log(props.data.columns.length,'00000000000000000')
  return (


    <CSVLink  {...csvReport} >
      <MDBTooltip tag='span' wrapperClass="" title="Download Report" placement='bottom'>
        <button className={'download-button ' + props.className} style={props.style}><img src={downloadIcon} alt="" /></button>
      </MDBTooltip>
    </CSVLink>





  )
}
