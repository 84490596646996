import React, { useEffect, useState } from 'react'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import { subDays } from 'date-fns'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'
import SaleChnnelButton from '../../Components/SaleChannelButton/SaleChnnelButton'
import Loader from '../../Components/Loader/Loader'
import { Modal } from "react-bootstrap";


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const cancelSaleUrl = axiosUrls.CancelSale
export default function CancelSales() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [isDetail, setIsDetail] = useState(false)

  const [dates, setDates] = useState([subDays(new Date(), 30), new Date()])
  const [selectedChannel, setSelectedChannel] = useState('overall')
  const [cancelSaleData, setCancelSaleData] = useState([])
  const [moreDetail, setMoreDetail] = useState()



  useEffect(() => {
    getCancelSale()
  }, [selectedChannel, dates])


  const columns = [
    {
      label: 'Kiosk Name',
      field: 'kioskName',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Amount',
      field: 'amount',

    },
    {
      label: 'Item Cancelled',
      field: 'itemCancelled',

    },

    {
      label: 'Action',
      field: 'action',
      width: 100
    }
  ]


  const getCancelSale = () => {
    setLoader(true)
    apiCounter++

    axios.post(cancelSaleUrl, {
      dates: dates,
      channel: selectedChannel,
    }, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      const data = response.data
      for (var i = 0; i < data.length; i++) {
        data[i]['action'] = <button className='detail-btn font-2' value={i} onClick={(e) => { setMoreDetail(e.target.value); setIsDetail(true) }}>Details</button>
      }
      setCancelSaleData({ columns: columns, rows: response.data })
      apiCounter--
      if (apiCounter == 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter == 0) {
        setLoader(false)
      }
    })
  }

  if (loader) {
    return <Loader />
  } else {
    return (
      <div className='h-100 cancel-sales'>
        <div className="row h-100">
          <div className="col h-100">
          <div className="row reverse-col">
              <div className="col-sm-8 my-2 d-flex ">
               <SaleChnnelButton selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}/>
              </div>
              <div className="col-sm-4 my-2 ">
                <div className="row  h-100 justify-content-end">
                  <div className='col-9 h-100 '>

                    <CustomDatePicker dates={dates} setDates ={setDates} />
                  </div>
                  <div className="col-3 h-100" style={{textAlign:'end'}}>
                    
                  <DownloadButton data={cancelSaleData} filename ="CancelSaleReport.csv"/>
                  </div>

                </div>
              </div>
            </div>



            <div className='desktop' style={{ height: '95%' }}>
              <TableWithPagination data={cancelSaleData} />
            </div>

            <div className="mobile row py-2">
              <div className="col">

              <p className='font-2 semi-bold mb-1'>Cancelled</p>
              </div>

              {
                cancelSaleData.rows && cancelSaleData.rows.map((data, index) => {
                  return <div className="col-12 " onClick={(e) => { setMoreDetail(index+1); setIsDetail(true) }}>
                    <div className=" mobile-item-box-div my-1">
                      <div className="d-flex justify-content-between py-1">
                        <span className='font-2'>{data.kioskName}</span>

                      </div>
                      <div className="d-flex justify-content-between">
                        <span className='font-2'>Itme Cancelled: {data.itemCancelled}</span>
                        <span className='font-2'>Amount: {data.amount}</span>
                      </div>
                    </div>
                    
                  </div>
                })
              }


            </div>


          </div>
        </div>
        <Modal
          show={isDetail}
          onHide={() => { setIsDetail(false);setMoreDetail() }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: "80vh", overflow: 'auto' }}>


            <div className='p-3'>


              <p className='text-center font-2 semi-bold'>Items Details</p>
              {/* <p className='font-2'>Reason</p> */}
              
              {
                moreDetail && cancelSaleData.rows[moreDetail]['flavours'].map((data, index) => {
                  return <div className='row item-box my-2 p-2'>
                    <div className="col-1 d-flex align-items-center">
                      <span className='font-2'>{index + 1}</span>
                    </div>
                    <div className="col-9 d-flex align-items-center">
                      <p className='font-2 m-0'>{data.flavour_name}</p>

                    </div>
                    <div className="col-1  d-flex align-items-center" style={{ textAlign: 'end' }}>
                      <p className='font-2 m-0 '>{data.quantity}</p>
                    </div>


                  </div>
                })
              }




            </div>

            {/* {
                Object.entries(kioskdealprice).map(([key, value]) => (
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{key.replace('_', ' ')}</Form.Label>
                    <Form.Control
                      value={value}
                      name={key}
                      type="number"
                      onChange={handleDealPriceEditChange}
                    />
                  </Form.Group>
                ))

              } */}







          </Modal.Body>

        </Modal>
      </div>
    )
  }
}
