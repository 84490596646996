import React, { useEffect, useState, useContext } from 'react'
import './Franchise.css'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import { Modal } from "react-bootstrap";
import CancelButton from '../../Components/CancelButton/CancelButton'
import right_arrow_icon from '../../Assets/Arrow Right.svg'
import left_arrow_icon from '../../Assets/Arrow Left.svg'
import back_icon from '../../Assets/back.svg'
import deleteIcon from '../../Assets/delete-icon.svg'
import editIcon from '../../Assets/edit-icon.svg'
import { TopBarTitleContext } from '../../App'
import Select from 'react-select'
import { permissionsContext } from '../../App'
import Swal from 'sweetalert2';
import { MDBTooltip } from 'mdb-react-ui-kit';

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getFranchiseUrl = axiosUrls.getFranchise
const addFranchiseUrl = axiosUrls.addFranchise
const editFranchiseUrl = axiosUrls.editFranchise
const deleteFranchiseUrl = axiosUrls.deleteFranchise
const getFranchiseDetailUrl = axiosUrls.getFranchiseDetail
const saveFrachiseDetailUrl = axiosUrls.saveFrachiseDetail






export default function FranchiseList() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [errors, setErrors] = useState({})
  const [franchiseData, setFranchiseData] = useState([])
  const [editAndAddFranchise, setEditAndAddFranchise] = useState({})
  const [isEditAndAddModal, setIsEditAndAddModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [detailIndex, setDetailIndex] = useState()
  const [isDetailModal, setIsDetailModal] = useState(false)

  const [franchiseDetail, setFranchiseDetail] = useState([])
  const [availableKiosks, setAvailableKiosks] = useState([])
  const [availablePermissions, setAvailablePermissions] = useState([])
  const [franchiseDetailData, setFranchiseDetailData] = useState([])

  const [topBarTitle, setTopBarTitle] = useContext(TopBarTitleContext)
  const permissions = useContext(permissionsContext)

  

  setTopBarTitle('Franchise')





  useEffect(() => {
    getFranchiseApi()
  }, [])

  useEffect(() => {
    if (detailIndex>=0) {
      getFranchiseDetail()
    }
  }, [detailIndex])


  const columns = [
    {
      label: 'Franchise Name',
      field: 'franchiseName',
    }, {
      label: 'Email Address',
      field: 'emailAddress',
    }, {
      label: 'Date Added',
      field: 'dateAdded'
    }, {
      label: 'Permissions',
      field: 'details'
    },
    (permissions.includes('Can Edit Franchise') || permissions.includes('Can Delete Franchise')) && {
      label: 'Action',
      field: 'action'
    }
  ].filter(Boolean)

  const handleFranchiseChange = (e) => {
    const { name, value } = e.target
    setEditAndAddFranchise(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleKioskChange = (e) => {
    setFranchiseDetailData(prevState => ({
      ...prevState,
      ['chossenKiosk']: e
    }))
  }


  const handlePermissionChange = (e) => {
    setFranchiseDetailData(prevState => ({
      ...prevState,
      ['chossenPermissions']: e
    }))
  }


  const getFranchiseApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getFranchiseUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      const data = response.data
      for (let i = 0; i < data.length; i++) {
        data[i]['details'] = <button className='detail-btn' value={i} onClick={(e) => { setDetailIndex(e.target.value); setIsDetailModal(true) }}>Details</button>
        data[i]['action'] = <div className="d-flex align-items-center justify-content-start">
          {
            permissions.includes('Can Edit Franchise') && 
            <MDBTooltip tag='span' wrapperClass="" title="Edit Franchise" placement='bottom'>
                
                <button style={{ backgroundColor: 'transparent' }} onClick={() => { setIsEditAndAddModal(true); setIsEdit(true); setEditAndAddFranchise(data[i]) }}><img src={editIcon} alt="" /></button>
              </MDBTooltip>
          }
          {
            permissions.includes('Can Delete Franchise') && 
            <MDBTooltip tag='span' wrapperClass="" title="Delete Franchise" placement='bottom'>
                <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteFranchiseApi(data[i].id) }}><img src={deleteIcon} alt="" /></button>
              </MDBTooltip>
          }


        </div>
      }
      setFranchiseData({ columns: columns, rows: response.data })
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }


  const addFranchiseApi = (e) => {
    e.preventDefault()
    setLoader(true)
    apiCounter++
    axios.post(addFranchiseUrl, editAndAddFranchise, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      getFranchiseApi()
      setEditAndAddFranchise({})
      setIsEditAndAddModal(false)
      setErrors({})
      Swal.fire(
        'Success',
        'Franchise is added successfully',
        'success'
      )
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }

    }).catch(error => {
      console.log(error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }

    })
  }

  const editFranchiseApi = (e) => {
    e.preventDefault()
    setLoader(true)
    apiCounter++
    axios.post(editFranchiseUrl, editAndAddFranchise, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      getFranchiseApi()
      setEditAndAddFranchise({})
      setIsEditAndAddModal(false)
      setIsEdit(false)
      setErrors({})
      Swal.fire(
        'Success',
        'Franchise is updated successfully',
        'success'
      )
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }

    }).catch(error => {
      console.log(error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }

    })
  }

  const deleteFranchiseApi = (id) => {

    if (window.confirm('Want to delete franchise')) {
      setLoader(true)
      apiCounter++
      axios.get(deleteFranchiseUrl + '/' + id, {
        headers: {
          'Authorization': token
        }
      }).then(response => {
        console.log(response)
        getFranchiseApi()

        setErrors({})
        Swal.fire(
          'Success',
          'Franchise is deleted successfully',
          'success'
        )
        apiCounter--
        if (apiCounter === 0) {
          setLoader(false)
        }

      }).catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setErrors(error.response.data.errors)
        }
        apiCounter--
        if (apiCounter === 0) {
          setLoader(false)
        }

      })
    }


  }





  const getFranchiseDetail = () => {
    setLoader(true)
    apiCounter++
    axios.get(getFranchiseDetailUrl + '/' + franchiseData.rows[detailIndex].franchiseName, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      var kioskOptions = []
      response.data.kiosksNotAssigned.map((option) => {

        kioskOptions.push({ value: option.kiosk_name, label: option.kiosk_name })
      })

      setAvailableKiosks(kioskOptions)

      var franchiseKiosk = []

      response.data.franchiseKiosks.map((option) => {
        franchiseKiosk.push({ value: option.kiosk_name, label: option.kiosk_name })
      })




      var permissionOptions = []

      response.data.permissionsNotAssigned.map((option) => {
        permissionOptions.push({ value: option.name, label: option.name })
      })


      setAvailablePermissions(permissionOptions)


      var franchisePermissionOptions = []

      response.data.franchisePermissions.map((option) => {
        franchisePermissionOptions.push({ value: option.name, label: option.name })
      })

      // setFranchiseDetail(response.data.franchiseDetail)
      // console.log(response.data.franchiseDetail.email,)

      setFranchiseDetailData({ franchiseName: response.data.franchiseDetail.name, chossenKiosk: franchiseKiosk, chossenPermissions: franchisePermissionOptions ,id:response.data.franchiseDetail.id })


      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }


















  const saveDetail = (e) =>{
    e.preventDefault()
    
    setLoader(true)
      apiCounter++
      var chossenPermissions =[]
      franchiseDetailData.chossenPermissions.forEach(element => {
        chossenPermissions.push(element.value)
      });
      var chossenKiosk =[]
      franchiseDetailData.chossenKiosk.forEach(element => {
        chossenKiosk.push(element.value)
      });
    axios.post(saveFrachiseDetailUrl,{
      chossenPermissions:chossenPermissions,
      chossenKiosk:chossenKiosk,
        kioskId : franchiseDetailData.id
    },{
        headers:{
            'Authorization':token
        }
    }).then(response =>{
      console.log(response)
       

        setIsDetailModal(false)
        // setFranchiseDetailData([])
        setDetailIndex(-1)
        setErrors([])
        Swal.fire(
          'Success',
          'Franchise is updated successfully',
          'success'
        )
        apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
    }).catch(error =>{
      console.log(error)
      
      apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
        
        if(error.response.status === 422){
          setErrors(error.response.data.errors)
        }
        else{
          setErrors([])
        }
    })
  }





  if (loader) {
    return <Loader />
  }
  else {

    return (
      <div className="h-100">
        <div className="row">
          <div className="col-3 desktop">
            <span className='font-2 semi-bold'>Franchise List</span>
          </div>

          <div className="col-sm-9" >
            <div className="d-flex justify-content-end">
              {
                
                permissions.includes("Can Add Franchise") && <GradientButton text="Add New Franchise" onClick={() => { setIsEditAndAddModal(true) }} />
              }

              <DownloadButton className="ml-3" data={franchiseData} filename="FranchiseList.csv" />
            </div>
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={franchiseData} />
        </div>

        <div className="mobile row py-2">
          <div className="col">

            <p className='font-2 semi-bold mb-1'>Franchise List</p>
          </div>

          {
            franchiseData.rows && franchiseData.rows.map((data, index) => {
              return <div className="col-12 " >
                <div className="mobile-item-box-div my-1">
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.franchiseName}</span>
                    <span className='font-2 d-flex '>
                      {permissions.includes('Can Edit Franchise') && <button style={{ backgroundColor: 'transparent' }} onClick={() => { setIsEditAndAddModal(true); setIsEdit(true); setEditAndAddFranchise(data) }}>
                        <img src={editIcon} alt="" />
                      </button>}
                      {
                        permissions.includes('Can Delete Franchise') && <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteFranchiseApi(data.id) }}><img src={deleteIcon} alt="" /></button>
                      }

                    </span>

                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>{data.emailAddress}</span>
                    <span className='font-2'>{data.dateAdded}</span>
                  </div>
                </div>

              </div>
            })
          }


        </div>

        <Modal
          show={isEditAndAddModal}
          onHide={() => { setIsEditAndAddModal(false); setIsEdit(false); setEditAndAddFranchise({}); setErrors({}) }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <form className='p-3 h-100' onSubmit={isEdit ? editFranchiseApi : addFranchiseApi}>
              <p className='text-center font-2 semi-bold'>{isEdit ? "Edit Franchise" : "Add New Franchise"}</p>


              <div className="row" >
                <div className="col-12 py-1">
                  <label htmlFor="franchise_name" className='m-0 font-2'>Franchise Name</label>
                  <input type="text" id="franchise_name" name="franchiseName" value={editAndAddFranchise.franchiseName} className={'form-control font-2 ' + (errors['franchiseName'] ? 'is-invalid' : '')} onChange={handleFranchiseChange} />
                  <div className="invalid-feedback">
                    {errors['franchiseName'] && errors['franchiseName']}
                  </div>
                </div>

                <div className="col-12 py-1">
                  <label htmlFor="email" className='m-0 font-2'>Email</label>
                  <input type="email" id="email" name="emailAddress" value={editAndAddFranchise.emailAddress} className={'form-control font-2 ' + (errors['emailAddress'] ? 'is-invalid' : '')} onChange={handleFranchiseChange} readOnly={isEdit ? 'readOnly' : ''} />
                  <div className="invalid-feedback">
                    {errors['emailAddress'] && errors['emailAddress']}
                  </div>
                </div>

                <div className="col-12 py-1">
                  <label htmlFor="password" className='m-0 font-2'>Password</label>
                  <input type="password" id="password" name="password" value={editAndAddFranchise.password} className={'form-control font-2 ' + (errors['password'] ? 'is-invalid' : '')} onChange={handleFranchiseChange} />
                  <div className="invalid-feedback">
                    {errors['password'] && errors['password']}
                  </div>
                </div>


                <div className="col-12 py-1">
                  <label htmlFor="password_confirmation" className='m-0 font-2'>Confirm Password</label>
                  <input type="password" id="password_confirmation" name="password_confirmation" value={editAndAddFranchise.password_confirmation} className={'form-control font-2 ' + (errors['password_confirmation'] ? 'is-invalid' : '')} onChange={handleFranchiseChange} />
                  <div className="invalid-feedback">
                    {errors['password_confirmation'] && errors['password_confirmation']}
                  </div>
                </div>




              </div>

              <div className="d-flex justify-content-end mt-2 pt-1">
                <CancelButton onClick={(e) => { e.preventDefault(); setIsEdit(false); setIsEditAndAddModal(false); setEditAndAddFranchise({}); setErrors({}) }} />
                <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
              </div>


            </form>
          </Modal.Body>
        </Modal>


        <Modal
          show={isDetailModal}
          onHide={() => { setIsDetailModal(false); }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>
            
            {franchiseDetailData && <form className='p-3 h-100' onSubmit={saveDetail}>
              <p className='text-center font-2 semi-bold'>{franchiseDetailData.franchiseName} Permissions</p>


              <div className="row" >
               


                <div className="col-12 py-1">
                  <label htmlFor="franchiseNmae" className='m-0 font-2'>Franchise Name</label>
                  <input type="franchiseNmae" id="text" name="franchiseNmae" value={franchiseDetailData.franchiseName} className={'form-control font-2 ' + (errors['franchiseNmae'] ? 'is-invalid' : '')} readOnly={true} />

                </div>

                <div className="col-12 py-1">
                  <label className='m-0 font-2'>Select Kiosk</label>
                  <Select isMulti options={availableKiosks} value={franchiseDetailData.chossenKiosk} onChange={handleKioskChange} className={'form-select font-2 ' + (errors['chossenKiosk'] ? 'is-invalid' : '')}>
                  </Select>
                  <div className="invalid-feedback">
                    {errors['chossenKiosk'] && errors['chossenKiosk']}
                  </div>
                </div>

                <div className="col-12 py-1">
                  <label className='m-0 font-2'>Select Permissions</label>
                  <Select  isMulti options={availablePermissions} value={franchiseDetailData.chossenPermissions} onChange={handlePermissionChange} className={'form-select font-2 ' + (errors['chossenPermissions'] ? 'is-invalid' : '')}>
                  </Select>
                  <div className="invalid-feedback">
                    {errors['chossenPermissions'] && errors['chossenPermissions']}
                  </div>
                </div>








              </div>

              <div className="d-flex justify-content-end mt-2">
                <CancelButton onClick={(e) => { e.preventDefault(); setIsDetailModal(false) }} />
                <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
              </div>


            </form>}
            
          </Modal.Body>
        </Modal>
      </div>
    )


  }
}
