import React, { useEffect, useState } from 'react'
import './Employees.css'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import CancelButton from '../../Components/CancelButton/CancelButton'
import CustomSelect from '../../Components/CustomSelect/CustomSelect'
import { Modal } from "react-bootstrap";
import editIcon from "../../Assets/edit-icon.svg"
import imageIcon from '../../Assets/image-icon.svg'
import deleteIcon from '../../Assets/delete-icon.svg'
import changeStatusIcon from '../../Assets/change-status-icon.svg'

import { permissionsContext } from '../../App'
import { MDBTooltip } from 'mdb-react-ui-kit';


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useContext } from 'react'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getEmployeeUrl = axiosUrls.Employee
const addEmployeeUrl = axiosUrls.AddEmployee
const editEmployeeUrl = axiosUrls.EditEmployee
const deleteEmployeeUrl = axiosUrls.DeleteEmployee

export default function Employees() {

    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [employeeData, setEmpoyeeData] = useState([])
    const [errors, setErrors] = useState({})
    const [editAndAddEmployee, setEditAndAddEmployee] = useState({})
    const [isEditAndAddModal, setIsEditAndAddModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedKiosk, setSelectedKiosk] = useState('')
    const [isEmployeeDetail, setIsEmployeeDetail] = useState(false)
    const [employeeDetail, setEmployeeDetail] = useState({})
    const [isImageOpen,setIsImageOpen] = useState(false)
    const [image,setImage] = useState()

    const permissions = useContext(permissionsContext)

    useEffect(() => {
        getEmployeeApi()
    }, [])

    const columns = [
        {
            label: 'Employee Name',
            field: 'employeeName',
        }, {
            label: 'Address',
            field: 'address',
        }, {
            label: 'CNIC Image',
            field: 'cnicImage'
        }, {
            label: 'Contact No',
            field: 'contactNo'
        }, {
            label: 'Salary',
            field: 'salary'
        }, {
            label: 'Joining Date',
            field: 'joiningDate'
        }, {
            label: 'Status',
            field: 'status'
        }, 
        (permissions.includes('Can Edit Employee') || permissions.includes('Can Delete Employee')) && {
            label: 'Action',
            field: 'action'
        }

    ].filter(Boolean)

    const handleEmployeeChange = (e) => {
        const { name, value } = e.target
        setEditAndAddEmployee(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleEmployeeKioskChange = (value) => {
        
        setEditAndAddEmployee(prevState => ({
            ...prevState,
            ['assignKiosk']: value
        }))
    }

    const handleImageChange = (e) => {

        setEditAndAddEmployee(prevState => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }))
    }




    const getEmployeeApi = () => {
        setLoader(true)
        apiCounter++
        axios.get(getEmployeeUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            const data = response.data
            for (let i = 0; i < data.length; i++) {
                data[i]['cnicImage'] = <div className="d-flex justify-content-around">
                <div onClick={()=>{setIsImageOpen(true);setImage(data[i].cnic_front)}}>
           
                    <img style={{ maxWidth: '60px',maxHeight:'30px' }} src={axios.defaults.baseURL.split('/api')[0] + '/employee/' + data[i].cnic_front} alt="" />
                </div>
                <div onClick={()=>{setIsImageOpen(true);setImage(data[i].cnic_back)}}>
                    <img style={{ maxWidth: '60px',maxHeight:'30px' }} src={axios.defaults.baseURL.split('/api')[0] + '/employee/' + data[i].cnic_back} alt="" />
                </div>

            </div>
                data[i]['action'] = <div className='d-flex align-items-center justify-content-start'>
                    {
                        permissions.includes('Can Edit Employee') && 
                        <MDBTooltip tag='span' wrapperClass="" title="Edit Employee" placement='bottom'>

                        <button onClick={(e) => { setIsEditAndAddModal(true); setIsEdit(true); setEditAndAddEmployee(data[i]) }} style={{ backgroundColor: 'transparent' }} value={i}  >
                        <img src={editIcon} alt="" />
                    </button>
              </MDBTooltip>
                    }
                    {
                        permissions.includes('Can Delete Employee') && 
                        <MDBTooltip tag='span' wrapperClass="" title="Activate/Deactivate Employee" placement='bottom'>

                        <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteEmployeeApi(data[i].id,data[i].status) }}><img src={changeStatusIcon} alt="" /></button>
              </MDBTooltip>
                    }
                    
                    
                </div>
            }
            setEmpoyeeData({ columns: columns, rows: response.data })
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    const addEmployeeApi = (e) => {
        e.preventDefault()
        setLoader(true)
        apiCounter++
        console.log(editAndAddEmployee)
        const data = new FormData()
        Object.keys(editAndAddEmployee).map((key, index) => {

            data.append(key, editAndAddEmployee[key])
        })

        axios.post(addEmployeeUrl, data, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            apiCounter--
            getEmployeeApi()
            setEditAndAddEmployee({})
            setErrors({})
            setIsEditAndAddModal(false)
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }
    const editEmployeeApi = (e) => {

        e.preventDefault()
        setLoader(true)
        apiCounter++
        console.log(editAndAddEmployee)
        const data = new FormData()
        Object.keys(editAndAddEmployee).map((key, index) => {

            data.append(key, editAndAddEmployee[key])
        })

        axios.post(editEmployeeUrl , data, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            apiCounter--
            getEmployeeApi()
            setEditAndAddEmployee({})
            setErrors({})
            setIsEditAndAddModal(false)
            setIsEdit(false)
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })

    }
    const deleteEmployeeApi = (id,status) => {
        var text = ''
        if(status === 'active'){
            text = 'Sure to deactivate this account'
        }
        else{
            text = 'Sure to activate this account'
        }
        if (window.confirm(text)) {
            setLoader(true)
            apiCounter++
            axios.get(deleteEmployeeUrl + '/' + id, {
                headers: {
                    'Authorization': token
                }
            }).then(response => {
                console.log(response)
                getEmployeeApi()
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }
            }).catch(error => {
                console.log(error)
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }
            })
        }


    }
    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className="h-100">
                <div className="row">
                    <div className="col-3 desktop">
                        <span className='font-2 semi-bold'>Employees</span>
                    </div>

                    <div className="col-sm-9" >
                        <div className="d-flex justify-content-end">
                            {
                                permissions.includes('Can Add Employee') && <GradientButton text="Add New Employees" onClick={() => { setIsEditAndAddModal(true) }} />
                            }
                            
                            <DownloadButton className="ml-3" data={employeeData} filename="EmployeeReport.csv"/>
                        </div>

                    </div>



                </div>
                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={employeeData} />
                </div>

                <div className="mobile row py-2">
                    <div className="col">

                    <p className='font-2 semi-bold mb-1'>Kiosks <span className='font-2'>(No. of Kiosk = {employeeData.rows && employeeData.rows.length})</span></p>
                    </div>

                    {
                        employeeData.rows && employeeData.rows.map((data, index) => {
                            return <div className="col-12 " >
                                <div className="mobile-item-box-div my-1" onClick={() => { setEmployeeDetail(data); setIsEmployeeDetail(true) }}>
                                <div className="d-flex justify-content-between py-1">
                                    <span className='font-2'>{data.employeeName}</span>
                                    <span className='font-5 d-flex '>
                                        <button onClick={(e) => { setIsEditAndAddModal(true); setIsEdit(true); setEditAndAddEmployee(data) }} style={{ backgroundColor: 'transparent' }}   >
                                            <img src={editIcon} alt="" />
                                        </button>
                                        <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteEmployeeApi(data.id) }}><img src={changeStatusIcon} alt="" /></button>
                                    </span>

                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className='font-5'>{data.contactNo}</span>
                                    <span className='font-5' style={{ color: data.status === 'Active' ? '#59EB25' : '#DF1313' }}>{data.status}</span>
                                </div>
                                </div>
                                
                            </div>
                        })
                    }


                </div>

                <Modal
                    show={isEditAndAddModal}
                    onHide={() => { setIsEditAndAddModal(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <form className='p-3 h-100' onSubmit={isEdit ? editEmployeeApi : addEmployeeApi}>
                            <p className='text-center font-2 semi-bold'>{isEdit ? "Edit Employee" : "Add New Employee"}</p>


                            <div className="row" >
                                <div className="col-12 py-1">
                                    <label htmlFor="employeeName" className='m-0 font-2'>Employee Name</label>
                                    <input type="text" id="employeeName" name="employeeName" value={editAndAddEmployee.employeeName} className={'form-control font-2 ' + (errors['employeeName'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['employeeName'] && errors['employeeName']}
                                    </div>
                                </div>

                                <div className="col-12 py-1">
                                    <label htmlFor="email" className='m-0 font-2'>Email</label>
                                    <input type="email" id="email" name="email" value={editAndAddEmployee.email} className={'form-control font-2 ' + (errors['email'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['email'] && errors['email']}
                                    </div>
                                </div>
                                {

                                !isEdit &&<div className="col-12 py-1">
                                    <label htmlFor="password" className='m-0 font-2'>Password</label>
                                    <input type="password" id="password" name="password" value={editAndAddEmployee.password} className={'form-control font-2 ' + (errors['password'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['password'] && errors['password']}
                                    </div>
                                </div>
                                }

                                <div className="col-12 py-1">

                                    <label htmlFor="kiosk" className='m-0 font-2'>Assign Kiosk</label>
                                    <CustomSelect name="assignKiosk" value={editAndAddEmployee.assignKiosk} className={'form-select ' + (errors['assignKiosk'] ? 'is-invalid' : '')} handleOnChange={handleEmployeeKioskChange} text="Select Kiosk"/>
                                    <div className="invalid-feedback">
                                        {errors['assignKiosk'] && errors['assignKiosk']}
                                    </div>
                                </div>


                                <div className="col-6 py-1">
                                    <label htmlFor="contact_no" className='m-0 font-2'>Contact No</label>
                                    <input type="text" id="contact_no" name="contactNo" value={editAndAddEmployee.contactNo} className={'form-control font-2 ' + (errors['contactNo'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['contactNo'] && errors['contactNo']}
                                    </div>
                                </div>

                                <div className="col-6 py-1">
                                    <label htmlFor="salary" className='m-0 font-2'>Salary</label>
                                    <input type="text" id="salary" name="salary" value={editAndAddEmployee.salary} className={'form-control font-2 ' + (errors['salary'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['salary'] && errors['salary']}
                                    </div>
                                </div>

                                <div className="col-12 py-1">
                                    <label htmlFor="address" className='m-0 font-2'>Address</label>
                                    <input type="text" id="adress" name="address" value={editAndAddEmployee.address} className={'form-control font-2 ' + (errors['address'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['address'] && errors['address']}
                                    </div>
                                </div>

                                <div className="col-12 py-1">
                                    <label htmlFor="joining_date" className='m-0 font-2'>Joining Date</label>
                                    <input type="date" id="joining_date" name="joiningDate" value={editAndAddEmployee.joiningDate} className={'form-control font-2 ' + (errors['joiningDate'] ? 'is-invalid' : '')} onChange={handleEmployeeChange} />
                                    <div className="invalid-feedback">
                                        {errors['joiningDate'] && errors['joiningDate']}
                                    </div>
                                </div>

                                <div className="col-6 py-1">
                                    <label htmlFor="cnic_f" className='m-0 font-2'>CNIC Front</label>
                                    <div className='img-input-div'>
                                    {editAndAddEmployee.cnicFront ?<img id=""src={URL.createObjectURL(new Blob([editAndAddEmployee.cnicFront], { type: editAndAddEmployee.cnicFront }))} alt="" /> :<img src={imageIcon} alt="" />} <span className='font-8 pl-2'>Image should be less then 1mb</span>
                                        <div className="input-div">
                                            <input type="file" id="cnic_f" name="cnicFront" className={'form-control font-2 ' + (errors['cnicFront'] ? 'is-invalid' : '')} accept=".png, .jpg, .jpeg" maxlength="1048576" onChange={handleImageChange} required={isEdit ? false : false} />
                                            <div className="invalid-feedback">
                                                {errors['cnicFront'] && errors['cnicFront']}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 py-1">
                                    <label htmlFor="cnic_d" className='m-0 font-2'>CNIC Back</label>
                                    <div className='img-input-div'>
                                        {editAndAddEmployee.cnicBack ?<img id=""src={URL.createObjectURL(new Blob([editAndAddEmployee.cnicBack], { type: editAndAddEmployee.cnicBack }))} alt="" /> :<img src={imageIcon} alt="" />} <span className='font-8 pl-2'>Image should be less then 1mb</span>
                                        <div className='input-div'>
                                        
                                            <input type="file" id="cnic_d" name="cnicBack" className={'form-control font-2 ' + (errors['cnicBack'] ? 'is-invalid' : '')} accept=".png, .jpg, .jpeg" maxlength="1048576" onChange={handleImageChange} required={isEdit ? false : false} />
                                            <div className="invalid-feedback">
                                                {errors['cnicBack'] && errors['cnicBack']}
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>

                            <div className="d-flex justify-content-end mt-3 pt-1">
                                <CancelButton onClick={(e) => { e.preventDefault(); setIsEdit(false); setIsEditAndAddModal(false); setEditAndAddEmployee({}); setErrors({}) }} />
                                <BlackButton text={isEdit ? "Save" :"Add"} style={{ width: '70px' }} type='submit' />
                            </div>


                        </form>
                    </Modal.Body>
                </Modal>


                <Modal
                    show={isEmployeeDetail}
                    onHide={() => { setIsEmployeeDetail(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <div className='p-3 h-100' >
                            <p className='text-center font-2 semi-bold'>Employee Detail</p>

                            <div className="row" >
                                <div className="col-12">
                                    <label className='m-0 font-2'>Employee Name</label>
                                    <input type="text" value={employeeDetail.employeeName} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Address</label>
                                    <input value={employeeDetail.address} className='form-control font-2 ' readOnly={true} />

                                </div>

                                <div className="col-12">
                                    <label className='m-0 font-2'>Contact No</label>
                                    <input type="text" value={employeeDetail.contactNo} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Salary</label>
                                    <input type="text" value={employeeDetail.salary} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Joining Date</label>
                                    <input type="text" value={employeeDetail.joiningDate} className='form-control font-2' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Status</label>
                                    <input type="text" style={{ color: employeeDetail.status === 'Active' ? '#59EB25' : '#DF1313' }} value={employeeDetail.status} className='form-control font-2 ' readOnly={true} />

                                </div>

                                <div className="col-12">
                                    <label className='m-0 font-2'>CNIC Image</label>
                                    <div className="d-flex justify-content-around">
                                        <div>
                                            <p className='m-0 font-2'>Front Side</p>
                                            <img style={{ width: '60px' }} src={axios.defaults.baseURL.split('/api')[0] + '/uploads/employee/' + employeeDetail.cnic_f} alt="" />
                                        </div>
                                        <div>
                                            <p className='m-0 font-2'>Back Side</p>
                                            <img style={{ width: '60px' }} src={axios.defaults.baseURL.split('/api')[0] + '/uploads/employee/' + employeeDetail.cnic_b} alt="" />
                                        </div>

                                    </div>

                                </div>

                            </div>




                        </div>
                    </Modal.Body>
                </Modal>


                <Modal
                    show={isImageOpen}
                    onHide={() => { setIsImageOpen(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <div className='p-3 h-100' >
                            <img className='w-100' src={axios.defaults.baseURL.split('/api')[0] + '/employee/'+image} alt="" />

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
