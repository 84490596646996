import React, { useState, useEffect, useContext } from 'react'
import './Products.css'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import CancelButton from '../../Components/CancelButton/CancelButton'
import Loader from '../../Components/Loader/Loader'
import { Modal } from "react-bootstrap";
import imageIcon from '../../Assets/image-icon.svg'
import CrossButton from '../../Components/CrossButton/CrossButton'
import editIcon from '../../Assets/edit-icon.svg'
import deleteIcon from '../../Assets/delete-icon.svg'
import { TopBarTitleContext } from '../../App'
import { permissionsContext } from '../../App'
import Swal from 'sweetalert2'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { MDBTooltip } from 'mdb-react-ui-kit';


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getProductUrl = axiosUrls.Products
const addCategoryUrl = axiosUrls.AddCategory
const addProductUrl = axiosUrls.AddProducts
const editProductUrl = axiosUrls.EditProducts
const deleteProductUrl = axiosUrls.DeleteProducts
export default function Products() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [productData, setProductData] = useState([])
  const [categories, setCategories] = useState([])
  const [isModalShow, setIsModalShow] = useState(false)
  const [editAndNewProduct, setEditAndNewProduct] = useState({})
  const [isNewCategory, setIsNewCategory] = useState(false)
  const [newCategory, setNewCategory] = useState()
  const [isProduction, setIsProduction] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editIndex, setEditIndex] = useState(0)
  const [errors, setErrors] = useState([])
  const [topBarTitle, setTopBarTitle] = useContext(TopBarTitleContext)
  const permissions = useContext(permissionsContext)



  setTopBarTitle('Product Management')


  useEffect(() => {
    getProductApi()

  }, [])




  // useEffect(()=>{
  //   console.log(errors,'-----------------------------------')
  // },[errors])



  const columns = [
    {
      label: 'Product Image',
      field: 'productImage',
    }, {
      label: 'Product Name',
      field: 'productName',
    },
    {
      label: 'Product Category',
      field: 'productCategory',
    },
     {
      label: 'Date Added',
      field: 'dateAdded'
    }, {
      label: 'Price (PKR)',
      field: 'price'
    },

    (permissions.includes('Can Edit Product') || permissions.includes('Can Delete Product')) && {
      label: 'Action',
      field: 'action'
    }
  ].filter(Boolean)

  const handleEditNewChange = (e) => {
    const { name, value } = e.target
    setEditAndNewProduct(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleImageChange = (e) => {
    const { name, value } = e.target
    setEditAndNewProduct(prevState => ({
      ...prevState,
      [name]: e.target.files[0]
    }))
  }


  const getProductApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getProductUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      apiCounter--
      const data = response.data.data.map((item,index)=>{
        // for (let i = 0; i < data.length; i++) {
          item['productImage'] = <img style={{ width: '35px' }} src={axios.defaults.baseURL.split('/api')[0] + '/products/' + item['productImage']} />
          item['action'] = <div className='d-flex align-items-center justify-content-start'>
  
            {permissions.includes('Can Edit Product') &&
            <MDBTooltip tag='span' wrapperClass="" title="Edit Product" placement='bottom'>
            
             <button onClick={(e) => { setIsModalShow(true); setIsEdit(true); setEditAndNewProduct({ product_name: item.productName, category: item.productCategory, price: item.price, id: item.id }) }} style={{ backgroundColor: 'transparent' }}   >
              <img src={editIcon} alt="" />
            </button>
          </MDBTooltip>
          }
            {
              permissions.includes('Can Delete Product') && 
                <MDBTooltip tag='span' wrapperClass="" title="Delete Product" placement='bottom'>
                <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteProductApi(item.id) }}><img src={deleteIcon} alt="" /></button>
              </MDBTooltip>
            }
  
          </div>
        // }
      })

      
      setProductData({ columns: columns, rows: response.data.data })
      setCategories(response.data.categories)
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }



  const addCategoryApi = (e) => {
    e.preventDefault()
    
    
    setLoader(true)
    apiCounter++
    axios.post(addCategoryUrl, {
      category_name: newCategory,
      isProduction: isProduction
    }, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)

      setCategories(prevState =>({
        ...prevState,
        newCategory
      }))
      setCategories([...categories,newCategory])
      setErrors([])
      setIsNewCategory(false)
      setIsProduction(false)
      setNewCategory()
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (error.response.status == 422) {
        setErrors(error.response.data.errors)
      }
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const addNewProductApi = (e) => {
    e.preventDefault()
    setLoader(true)
    apiCounter++
    let data = new FormData();
    // data.append('product_name', editAndNewProduct.product_name);
    // data.append('price', editAndNewProduct.price);
    // // data.append('perct_to_divide', editAndNewProduct.percentage);
    // data.append('category', editAndNewProduct.category);
    // data.append('picture', editAndNewProduct.picture);

    Object.keys(editAndNewProduct).map((key,index)=>{
        data.append(key,editAndNewProduct[key])
    })
    // return 

    axios.post(addProductUrl, data, {
      headers: {
        'Authorization': token,
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      console.log(response)
      apiCounter--
      setErrors([])
      setEditAndNewProduct({})
      setIsModalShow(false)
      getProductApi()

      Swal.fire(
        'Success',
        'Product is added successfully',
        'success'
      )
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      if (error.response.status === 422) {

        setErrors(error.response.data.errors)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const editProductApi = (e) => {
    e.preventDefault()
    setLoader(true)
    apiCounter++
    let data = new FormData();
    // data.append('product_name', editAndNewProduct.product_name);
    // data.append('price', editAndNewProduct.price);
    // // data.append('perct_to_divide', editAndNewProduct.percentage);
    // data.append('category', editAndNewProduct.category);
    // if (editAndNewProduct.picture) {

    //   data.append('picture', editAndNewProduct.picture);
    // }
    // if (editAndNewProduct.id) {

    //   data.append('id', editAndNewProduct.id);
    // }

    Object.keys(editAndNewProduct).map((key,index)=>{
      data.append(key,editAndNewProduct[key])
  })



    axios.post(editProductUrl, data, {
      headers: {
        'Authorization': token,
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      console.log(response)
      
      Swal.fire(
        'Success',
        'Product is updated successfully',
        'success'
      )
      getProductApi()
      setIsEdit(false)
      setEditAndNewProduct({})
      setErrors([])
      setIsModalShow(false)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const deleteProductApi = (id) => {
    if (window.confirm('Sure to want delete product')) {
      setLoader(true)
      apiCounter++
      axios.get(deleteProductUrl + '/' + id, {
        headers: {
          'Authorization': token
        }
      }).then(response => {
        console.log(response)
        getProductApi()
        Swal.fire(
          'Success',
          'Product is deleted successfully',
          'success'
        )
        apiCounter--
        if (apiCounter === 0) {
          setLoader(false)
        }
      }).catch(error => {
        console.log(error)
        apiCounter--
        if (apiCounter === 0) {
          setLoader(false)
        }
      })
    }
  }








  if (loader) {

    return <Loader />

  } else {

    return (
      <div className="h-100">
        <div className="row">
          <div className="col-3 desktop">
            <span className='font-2 semi-bold'>Products List</span>
          </div>

          <div className="col-sm-9" >
            {/* <BlackButton text="Add Category" className="mr-3 font-2" style={{padding:'7px'}}/> */}
            <div className='d-flex justify-content-end'>
              {
                permissions.includes('Can Add Product') && <GradientButton text="Add New Product" onClick={() => { setIsModalShow(true) }} />
              }

              <DownloadButton className="ml-3 " data={productData} filename="ProductList.csv"  />
            </div>
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={productData} />
        </div>



        <div className="mobile row py-2">
          <div className="col">

            <p className='font-2 semi-bold mb-1'>Products List</p>
          </div>

          {
            productData.rows && productData.rows.map((data, index) => {
              return <div className="col-12 " >
                <div className="mobile-item-box-div my-1">
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.productName}</span>
                    <span className='font-2'>
                      {
                        permissions.includes('Can Edit Product') && <button onClick={(e) => { setIsModalShow(true); setIsEdit(true); setEditAndNewProduct({ product_name: data.productName, category: data.productCategory, price: data.price, id: data.id }) }} style={{ backgroundColor: 'transparent' }}   >
                          <img src={editIcon} alt="" />
                        </button>
                      }
                      {
                        permissions.includes('Can Delete Product') && <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteProductApi(data.id) }}><img src={deleteIcon} alt="" /></button>
                      }


                    </span>

                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>{data.dateAdded}</span>
                    <span className='font-2'>Price: {data.price}</span>
                  </div>
                </div>

              </div>
            })
          }


        </div>



        <Modal
          show={isModalShow}
          onHide={() => { setIsModalShow(false);setIsEdit(false) }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='w-100'

        >

          <Modal.Body style={{ maxHeight: "80vh", overflowY: 'auto' ,overflowX : 'hidden'}}>

            <form className='p-3 h-100' onSubmit={isNewCategory ? addCategoryApi : isEdit ? editProductApi : addNewProductApi}>
              <p className='text-center font-2 semi-bold'>{isEdit?'Edit New Product':'Add New Product'}</p>


              <div className="row" >
                <div className="col-12 py-1">
                  <label htmlFor="product-name" className='m-0 font-2'>Product Name</label>
                  <input type="text" id="product-name" name="product_name" value={editAndNewProduct.product_name} className={'form-control font-2 ' + (errors['product_name'] ? 'is-invalid' : '')}  required={isNewCategory ? false : true} onChange={handleEditNewChange} />
                  <div className="invalid-feedback">
                    {errors['product_name'] && errors['product_name']}
                  </div>
                </div>

                <div className="col-12 py-1">
                  <label htmlFor="product-price" className='m-0 font-2'>Product price</label>
                  <input type="number" id="product-price" name="price" value={editAndNewProduct.price} className={'form-control font-2 ' + (errors['price'] ? 'is-invalid' : '')} required={isNewCategory ? false : true} onChange={handleEditNewChange} />
                  <div className="invalid-feedback">
                    {errors['price'] && errors['price']}
                  </div>
                </div>





                <div className="col-12">
                  <div className="row">

                    <div className="col-12 py-1">
                      <label htmlFor="product-category" className='m-0 font-2'>Product Category</label>

                      <div className="row">
                        <div className="col-8">
                          <select name="category" value={editAndNewProduct.category} disabled={isEdit?true:false} id="product-category" className='form-select w-100 font-2 is-invalid' style={{ padding: '6px 10px' }} aria-describedby="validationServer05Feedback" onChange={handleEditNewChange} required={isNewCategory? false : true}>
                            <option value=''>Select product category</option>
                            {
                              categories.map((category_name, index) => {
                                return <option value={category_name}>{category_name}</option>
                              })
                            }
                          </select>



                        </div>


                        <div className="col-4 d-flex align-items-center">
                          <BlackButton text="Add Category" style={{ padding: '5px 8px' }} className="font-2" onClick={() => { setIsNewCategory(true) }} />
                        </div>
                      </div>



                    </div>
                    {errors['category'] && <small style={{ color: '#dc3545' }} className='pb-1 pl-3'>{errors['category']}</small>}
                  </div>

                  {
                    isNewCategory && <div >
                      <div className="row py-1">
                        <div className="col-8">
                          <input type="text" placeholder='Category Name' className={'form-control ' + (errors['category_name'] ? 'is-invalid' : '')} name='categoryName' value={newCategory} onChange={(e) => { setNewCategory(e.target.value) }} required />
                        </div>

                        <div className="col-3 d-flex align-items-center">
                          <BlackButton text="Add" style={{ padding: '5px 8px' }} className="font-2" type="submit" />
                        </div>
                        <div className="col-1 pl-0 d-flex align-items-center">
                          <CrossButton onClick={() => { setIsNewCategory(false); setNewCategory(''); setIsProduction(false) }} />
                        </div>
                        {errors['category_name'] && <small style={{ color: '#dc3545' }} className='pb-1 pl-3'>{errors['category_name']}</small>}
                      </div>
                      <div className='d-flex align-items-center'>
                        <input type="checkbox" name="" id="is-prodction" defaultChecked={isProduction} onChange={(e) => { setIsProduction(!isProduction) }} /> <label htmlFor="is-prodction" className='font-2 pl-1 m-0'>Is this item produced in a bakery?</label>
                      </div>
                    </div>
                  }

                </div>

                <div className="col-12 py-1">
                  <label htmlFor="picture" className='m-0 font-2'>Product Image (.png, .jpg)</label>
                  {/* {} */}
                  <div className='file-div d-flex flex-column align-items-center' style={{minHeight:'50px'}}>


                     
                     {editAndNewProduct.picture ? <img id="product-img-prview"src={URL.createObjectURL(new Blob([editAndNewProduct.picture], { type: editAndNewProduct.picture }))} alt="" />:<img src={imageIcon} alt="" />}
                    
                      {/* {editAndNewProduct.picture && <img id="product-img-prview"src={URL.createObjectURL(new Blob([editAndNewProduct.picture], { type: editAndNewProduct.picture }))} alt="" />} */}
                      
                      
                    <div>

                      
                    {editAndNewProduct.picture ? '':<span className='font-2'>Image size should be less then 1mb </span>}
                    <div className='input-div'>
                      <input type="file" id="picture" name="picture" className={'form-control w-100 h-100 ' + (errors['picture'] ? 'is-invalid' : '')} accept=".png, .jpg, .jpeg" maxlength="1048576" onChange={handleImageChange} required={isNewCategory|| isEdit ? false : true}/>
                      <div className="invalid-feedback">
                        {errors['picture'] && errors['picture']}
                      </div>
                    </div>
                    </div>

                    
                  </div>


                </div>


              </div>

              <div className="d-flex justify-content-end mt-2 pt-1">
                <CancelButton onClick={() => { setIsModalShow(false); setIsEdit(false); setEditAndNewProduct({}) }} />
                <BlackButton text={isEdit? "Save":"Add"} style={{ width: '70px' }} type='submit' />
              </div>


            </form>







          </Modal.Body>

        </Modal>
      </div>
    )
  }
}
