import React, { useContext } from 'react'
import './Sidebar.css'


import SubSideBar from './SubSideBar.js'


import dashboarIcon from '../../Assets/dashboard.svg'
import saleStatsIcon from '../../Assets/sale-stats-icon.svg'
import inventoryIcon from '../../Assets/inventory-icon.svg'
import productIcon from '../../Assets/products.svg'
import franchiseIcon from '../../Assets/franchise-icon.svg'
import liveIcon from '../../Assets/live-icon.svg'
import userManagementIcon from '../../Assets/user-management-icon.svg'
import settingsIcon from '../../Assets/settings-icon.svg'
import logoutIcon from '../../Assets/logout-icon.svg'
import notificationIcon from '../../Assets/bell-icon-sidebar.svg'
import productionIcon from '../../Assets/production_icon.svg'
import movementIcon from '../../Assets/movement-icon.svg'
import productionHistoryIocn from '../../Assets/production-history-icon.svg'
import franchiseSaleOverviewIcon from '../../Assets/franchise-sale-overview-icon.svg'
import addSaleIcon from '../../Assets/add-sale-icon.svg'
import logoPng from '../../Assets/logowhitepng.png'


import { MDBTooltip } from 'mdb-react-ui-kit';

import { NavLink } from 'react-router-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { permissionsContext } from '../../App'
import { createdRouteContext } from '../../App'
export default function Sidebar(props) {
    const permissions = useContext(permissionsContext)
    const createdRoutes = useContext(createdRouteContext)


    const logout = () => {
        localStorage.removeItem('dwf-token')
        window.location.href = '/'
    }

    const setTo = (string) => {
        return (createdRoutes.filter((str) => str.includes(string)))[0]
    }




    return (
        <div className='h-100' style={{ position: 'relative' }}>
            <div id='sidebar' >
            
                <div className="icon-bar ">
                    <div className='text-center sidebar-logo'><img src={logoPng} className='w-50' alt="" />
                        <p className='font-5' style={{ color: 'white' }}>DWTF</p>
                    </div>

                    {permissions.includes('Can View Admin Dashboard') && <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Dashboard" placement='right'>
                        <NavLink to='/dashboard' className='nav-link' onClick={props.changeSidebarWidth}>
                            <img src={dashboarIcon} alt=""  />
                            
                        </NavLink>
                    </MDBTooltip>}

                    

                    {(permissions.includes('Can View Sale') || permissions.includes('Can View Cancel Sale') || permissions.includes('Can View Damage and Lost')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Sales Channel" placement='right'>

                            <NavLink to={setTo('sales')} className={window.location.href.includes('sales') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={saleStatsIcon} alt="" />
                                
                                
                            </NavLink>

                        </MDBTooltip>}

                    {(permissions.includes('Can View Fix Accessories') || permissions.includes('Can View Daily Inventory') || permissions.includes('Can View Inventory Request') || permissions.includes('Can View Requestable Inventory')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Inventory Management" placement='right'>

                            <NavLink to={setTo('inventory')} className={window.location.href.includes('inventory') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={inventoryIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can View Products') || permissions.includes('Can View Deals')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Product Management" placement='right'>

                            <NavLink to={setTo('products')} className={window.location.href.includes('products') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={productIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }
                    {(permissions.includes('Can View Franchise')) &&
                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Franchise" placement='right'>

                            <NavLink to={setTo('franchise')} className={window.location.href.includes('franchise') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={franchiseIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }


                    {(permissions.includes('Can View Live Deals Sale') || permissions.includes('Can View Live Products Sale') || permissions.includes('Can View Live Stats')) &&
                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Live Sale Stats" placement='right'>
                            <NavLink to={setTo('live')} className={window.location.href.includes('live') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={liveIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can View Kiosk') || permissions.includes('Can Add Kiosk') || permissions.includes('Can Edit Kiosk') || permissions.includes('Can Delete Kiosk') || permissions.includes('Can View Employee') || permissions.includes('Can View Attendance')) &&
                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="User Management" placement='right'>

                            <NavLink to={setTo('user-management')} className={window.location.href.includes('user-management') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={userManagementIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can View Bakery Production') || permissions.includes('Can Add Bakery Production')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Production" placement='right'>

                            <NavLink to={setTo('production')} className={window.location.href.includes('production') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={productionIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can View Bakery Production History')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Production History" placement='right'>

                            <NavLink to={setTo('produced-history')} className={window.location.href.includes('produced-history') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={productionHistoryIocn} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can Make Movement Kiosk to Kiosk') || permissions.includes('Can View Movement History')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Movement" placement='right'>

                            <NavLink to={setTo('movement')} className={window.location.href.includes('movement') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={movementIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can Add Sales') ) &&

                    <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Add Sale" placement='right'>

                        <NavLink to={setTo('/add/sale')} className={window.location.href.includes('/add/sale') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                            <img src={addSaleIcon} alt="" />
                        </NavLink>

                    </MDBTooltip>
                    }

                    {(permissions.includes('Franchise Can View Sale Overview') || permissions.includes('Franchise Can View Leftovers')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Sale Overview" placement='right'>

                            <NavLink to={setTo('reports')} className={window.location.href.includes('reports') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={franchiseSaleOverviewIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }

                    {(permissions.includes('Can View Admin Notifications') || permissions.includes('Can View Bakery Notifications')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Notitifications" placement='right'>

                            <NavLink to={setTo('notifications')} className={window.location.href.includes('notifications') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={notificationIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }


                    {(permissions.includes('Can View User Permissions and Roles') || permissions.includes('Can View Groups') || permissions.includes('Can View Log History') || permissions.includes('Change Password For Users')) &&

                        <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Settings" placement='right'>

                            <NavLink to={setTo('settings')} className={window.location.href.includes('setting') ? 'nav-link active-1' : 'nav-link'} onClick={props.changeSidebarWidth}>
                                <img src={settingsIcon} alt="" />
                            </NavLink>

                        </MDBTooltip>
                    }





                    <MDBTooltip tag='span' wrapperClass="d-flex align-items-center justify-content-center" title="Logout" placement='right'>

                        <NavLink  to="" className={'nav-link'} onClick={logout}>
                            <img src={logoutIcon} alt="" />
                        </NavLink>

                    </MDBTooltip>






                </div>



            </div>

            <SubSideBar changeSidebarWidth={props.changeSidebarWidth} />
        </div>
    )
}
