import React, { useEffect, useState,useContext } from 'react'
import './AddSale.css'
import CustomSelect from '../../Components/CustomSelect/CustomSelect'
import CancelButton from '../../Components/CancelButton/CancelButton'
import Loader from '../../Components/Loader/Loader'
import PlusButton from '../../Components/PlusButton/PlusButton'
import MinusButton from '../../Components/MinusButton/MinusButton'
import GradientButton from '../../Components/GradientButton/GradientButton'
import editCartIcon from '../../Assets/cart-edit.svg'
import deletCarIcon from '../../Assets/cart-delete.svg'
import cartIcon from '../../Assets/cart-icon.svg'
import noDataIcon from '../../Assets/no-sale-icon.png'
import { TopBarTitleContext } from '../../App'
import Swal from 'sweetalert2'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const getProductsAndCategoryUrl = axiosUrls.Products
const getSaleLeftoversUrl = axiosUrls.getSaleLeftovers
const addSaleUrl = axiosUrls.addSale


export default function AddSale() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [categories, setCategories] = useState([])
    const [deals, setDeals] = useState([])
    const [dealNames, setDealNames] = useState();
    const [leftovers, setLeftovers] = useState({})
    const [inventory, setInventory] = useState({})
    const [productPrice, setProductPrice] = useState({})
    const [inventoryPrice, setInventoryPrice] = useState({})
    const [saleData, setSaleData] = useState({ totalPrice: 0 })
    const [cart, setCart] = useState([])
    const [cartInventory, setCartInventory] = useState({ inventoryPrice: 0 })
    const [buttonText, setButtonText] = useState('Add To Cart')
    const [dealQuantity, setDealQuantity] = useState(1)
    const [orderNumber, setOrderNumber] = useState('')
    const [orderType, setOrderType] = useState('kiosk')
    const [discount, setDiscount] = useState()
    const [name, setName] = useState()

    const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Add Sale')

    useEffect(() => {
        getProductsAndCategoryApi()
    }, [])

    useEffect(() => {
        console.log(saleData, '---------------------')
    }, [saleData])

    useEffect(() => {
        var isExists = false
        cart.map((item) => {
            if (saleData.category === item.category) {
                setSaleData(item)
                isExists = true
                setButtonText('Update Cart')
            }

        })
        if (!isExists) {
            setSaleData({ kiosk: saleData.kiosk, category: saleData.category, date: saleData.date, totalPrice: 0, type: saleData.type })
            setButtonText('Add To Cart')
        }
        if (dealNames && deals && dealNames.includes(saleData.category) && saleData.category) {
            setSaleData(prevState => ({
                ...prevState,
                ['totalPrice']: deals[saleData.category].price
            }))
        }
    }, [saleData.category])


    useEffect(() => {
        if (dealNames) {

            const categ = [...categories]
            dealNames.map((key, index) => {
                categ.push(key)
            })
            setCategories(categ)
        }
    }, [dealNames])

    useEffect(() => {
        if (dealNames && deals && saleData.category) {

            setSaleData(prevState => ({
                ...prevState,
                ['totalPrice']: deals[saleData.category].price * dealQuantity
            }))
        }
    }, [dealQuantity])


    useEffect(() => {
        console.log(saleData.kioks, saleData.date)
        if (saleData.kiosk && saleData.date) {
            getSaleLeftoversApi()
        }
    }, [saleData.kiosk, saleData.date])

    const setSelectedKiosk = (e) => {
        setSaleData(prevState => ({
            ...prevState,
            ['kiosk']: e
        }))

    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setSaleData(prewState => ({
            ...prewState,
            [name]: value

        }))

        if (name === "category") {
            if (value) {
                console.log('test')
                setSaleData(prevState => ({
                    ...prevState,
                    [value]: []
                }))
            }
            cart.map((data, index) => {
                if (data.category === value) {
                    setSaleData(data)
                    // if(dealNames.includes(value)){

                    // }
                    setButtonText('Update Cart')
                }
            })
        }
    }

    useEffect(() => {
        console.log(saleData, '..................')
    }, [saleData])

    const handleAddItem = (productName) => {

        if (leftovers[saleData.category][productName] > 0) {
            if (saleData[saleData.category] && saleData[saleData.category][productName]) {

                setSaleData(prevState => ({
                    ...prevState,
                    [saleData.category]: { ...prevState[saleData.category], [productName]: saleData[saleData.category][productName] + 1 },
                    ['totalPrice']: saleData.totalPrice + productPrice[productName]
                }))

                setLeftovers(prevState => ({
                    ...prevState,
                    [saleData.category]: { ...prevState[saleData.category], [productName]: leftovers[saleData.category][productName] - 1 }
                }))


            }
            else {
                setSaleData(prevState => ({
                    ...prevState,
                    [saleData.category]: { ...prevState[saleData.category], [productName]: 1 },
                    ['totalPrice']: saleData.totalPrice + productPrice[productName]
                }))
                setLeftovers(prevState => ({
                    ...prevState,
                    [saleData.category]: { ...prevState[saleData.category], [productName]: leftovers[saleData.category][productName] - 1 }
                }))
            }
        }
        else {
            alert('NO leftovers')
        }

    }

    const handleMinusItem = (productName) => {
        if (saleData[saleData.category] && saleData[saleData.category][productName] > 1) {
            console.log('test')
            setSaleData(prevState => ({
                ...prevState,
                [saleData.category]: { ...prevState[saleData.category], [productName]: saleData[saleData.category][productName] - 1 },
                ['totalPrice']: saleData.totalPrice - productPrice[productName]
            }))
            setLeftovers(prevState => ({
                ...prevState,
                [saleData.category]: { ...prevState[saleData.category], [productName]: leftovers[saleData.category][productName] + 1 }
            }))
        }
        else if (saleData[saleData.category] && saleData[saleData.category][productName] === 1) {

            const prevData = { ...saleData }
            delete prevData[saleData.category][productName]
            prevData.totalPrice = prevData.totalPrice - productPrice[productName]

            setSaleData(prevData)
            setLeftovers(prevState => ({
                ...prevState,
                [saleData.category]: { ...prevState[saleData.category], [productName]: leftovers[saleData.category][productName] + 1 }
            }))
        }
    }

    const handleDealAddItem = (productName, categoryName) => {
        var categoryQuantity = 0

        if (saleData[categoryName]) {
            Object.keys(saleData[categoryName]).map((key) => {
                categoryQuantity = categoryQuantity + saleData[categoryName][key]
            })
        }

        if (categoryQuantity < (deals[saleData.category].items[categoryName].quantity * dealQuantity)) {

            if (leftovers[categoryName][productName] > 0) {
                if (saleData[categoryName] && saleData[categoryName][productName]) {


                    setSaleData(prevState => ({
                        ...prevState,
                        [categoryName]: { ...prevState[categoryName], [productName]: saleData[categoryName][productName] + 1 },
                        // ['totalPrice']: saleData.totalPrice + productPrice[productName]
                    }))

                    setLeftovers(prevState => ({
                        ...prevState,
                        [categoryName]: { ...prevState[categoryName], [productName]: leftovers[categoryName][productName] - 1 }
                    }))


                }
                else {

                    setSaleData(prevState => ({
                        ...prevState,
                        [categoryName]: { ...prevState[categoryName], [productName]: 1 },
                        // ['totalPrice']: saleData.totalPrice + productPrice[productName]
                    }))
                    setLeftovers(prevState => ({
                        ...prevState,
                        [categoryName]: { ...prevState[categoryName], [productName]: leftovers[categoryName][productName] - 1 }
                    }))
                }
            }
            else {
                alert('NO leftovers')
            }
        }
        else {
            alert('No more item can be selected')
        }



    }
    const handleDealMinusItem = (productName, categoryName) => {

        if (saleData[categoryName] && saleData[categoryName][productName] > 1) {

            setSaleData(prevState => ({
                ...prevState,
                [categoryName]: { ...prevState[categoryName], [productName]: saleData[categoryName][productName] - 1 },
                // ['totalPrice']: saleData.totalPrice - productPrice[productName]
            }))
            setLeftovers(prevState => ({
                ...prevState,
                [categoryName]: { ...prevState[categoryName], [productName]: leftovers[categoryName][productName] + 1 }
            }))
        }
        else if (saleData[categoryName] && saleData[categoryName][productName] === 1) {

            const prevData = { ...saleData }
            delete prevData[categoryName][productName]
            // prevData.totalPrice = prevData.totalPrice - productPrice[productName]

            setSaleData(prevData)
            setLeftovers(prevState => ({
                ...prevState,
                [categoryName]: { ...prevState[categoryName], [productName]: leftovers[categoryName][productName] + 1 }
            }))
        }
    }
    useEffect(() => {
        console.log(cartInventory, 'pppppppppppppppppppp')
    }, [cartInventory])


    const handleAddInventory = (inventoryName) => {

        if (inventory[inventoryName] > 0) {
            if (cartInventory[inventoryName]) {
                setCartInventory(prevState => ({
                    ...prevState,
                    [inventoryName]: cartInventory[inventoryName] + 1,
                    inventoryPrice: cartInventory['inventoryPrice'] + inventoryPrice[inventoryName]
                }))
                setInventory(prevState => ({
                    ...prevState,
                    [inventoryName]: inventory[inventoryName] - 1
                }))
            }
            else {
                setCartInventory(prevState => ({
                    ...prevState,
                    [inventoryName]: 1,
                    inventoryPrice: cartInventory['inventoryPrice'] + inventoryPrice[inventoryName]
                }))
                setInventory(prevState => ({
                    ...prevState,
                    [inventoryName]: inventory[inventoryName] - 1
                }))
            }
        }
        else {
            alert('no inventory')
        }


    }

    const handleMinusInventory = (inventoryName) => {


        if (cartInventory[inventoryName] > 1) {
            setCartInventory(prevState => ({
                ...prevState,
                [inventoryName]: cartInventory[inventoryName] - 1,
                inventoryPrice: cartInventory['inventoryPrice'] - inventoryPrice[inventoryName]
            }))
            setInventory(prevState => ({
                ...prevState,
                [inventoryName]: cartInventory[inventoryName] + 1
            }))
        }
        else if (cartInventory[inventoryName] === 1) {
            // console.log('tests')
            const prevData = { ...cartInventory }
            delete prevData[inventoryName]
            prevData['inventoryPrice'] -= inventoryPrice[inventoryName]
            setCartInventory(prevData)
            setInventory(prevState => ({
                ...prevState,
                [inventoryName]: inventory[inventoryName] + 1
            }))
        }
    }

    const addToCart = () => {

        // if(!saleData.inventory){
        //     alert('please add inventory')
        //     return 
        // }
        if (dealNames.includes(saleData.category)) {
            // Object.keys(deals[saleData.category].items).forEach()
            // Object.keys(deals[saleData.category].items).forEach((category) => {
            //     var selectedQuantity = 0;
            //     var categoryQuantity = (deals[saleData.category].items[category].quantity) * dealQuantity;

            //     // Use the map function to iterate over products within the category
            //     if(Object.keys(saleData).includes(category)){
            //         Object.keys(saleData[category]).map((product) => {
            //             selectedQuantity += saleData[category][product];


            //           });
            //           if (selectedQuantity > categoryQuantity) {
            //             console.log('Please reduce ' + category + ' quantity');
            //           } else if (selectedQuantity < categoryQuantity) {
            //             console.log('Please add ' + category + ' quantity');
            //           }
            //     }
            //     else{
            //         console.log('please add')
            //     }

            //   });
            //   return 
            try {
                Object.keys(deals[saleData.category].items).forEach((category) => {
                    var selectedQuantity = 0;
                    var categoryQuantity = (deals[saleData.category].items[category].quantity) * dealQuantity;

                    // Use the map function to iterate over products within the category

                    if (Object.keys(saleData).includes(category)) {
                        Object.keys(saleData[category]).map((product) => {
                            selectedQuantity += saleData[category][product];


                        });
                        if (selectedQuantity > categoryQuantity) {
                            throw new Error('Please reduce ' + category + ' to ' + categoryQuantity);
                        } else if (selectedQuantity < categoryQuantity) {
                            throw new Error('Please add ' + categoryQuantity + ' ' + category);
                        }
                    } else {
                        throw new Error('Please select ' + categoryQuantity + ' ' + category);
                    }
                });
                setCart((prevState) => {
                    const updatedCart = [...prevState];
                    const index = updatedCart.findIndex(item => item ? item.category === saleData.category : -1);

                    if (index !== -1) {
                        updatedCart[index] = saleData;
                    } else {

                        updatedCart.push(saleData);
                    }

                    return updatedCart;
                });
                setSaleData({ kiosk: saleData.kiosk, category: "", date: saleData.date, totalPrice: 0, })
                setDealQuantity(1)
                setButtonText('Add To Cart')
            } catch (error) {
                // alert(error.message);
                Swal.fire(
                    'warning',
                    error.message,
                    'warning'
                )
            }
        }
        else {
            
            if(saleData[saleData.category]){
                setCart((prevState) => {
                    const updatedCart = [...prevState];
                    const index = updatedCart.findIndex(item => item ? item.category === saleData.category : -1);
                    console.log(index)
                    if (index !== -1) {
                        updatedCart[index] = saleData;
                    } else {
    
                        updatedCart.push(saleData);
                    }
    
                    return updatedCart;
                });
                setSaleData({ kiosk: saleData.kiosk, category: "", date: saleData.date, totalPrice: 0, })
                setButtonText('Add To Cart')
            }
        }

    }

    useEffect(()=>{
        console.log(saleData,'--------------------')
    },[saleData])


    const editCartItem = (index) => {
        setSaleData(cart[index])
        setButtonText('Update Cart')
    }

    const deleteCartItem = async (index) => {
        const cartData = [...cart];

        if (dealNames.includes(cartData[index].category)) {

            await Promise.all(
                Object.keys(deals[cartData[index].category].items).map(async (category) => {
                    await Promise.all(
                        Object.keys(cartData[index][category]).map(async (product) => {

                            await setLeftovers((prevState) => ({
                                ...prevState,
                                [category]: {
                                    ...prevState[category],
                                    [product]: prevState[category][product] + cartData[index][category][product],
                                },
                            }));
                        })
                    );
                })
            );
        } else {

            await Promise.all(
                Object.keys(cartData[index][cartData[index].category]).map(async (product) => {

                    await setLeftovers((prevState) => ({
                        ...prevState,
                        [cartData[index].category]: {
                            ...prevState[cartData[index].category],
                            [product]: prevState[cartData[index].category][product] + cartData[index][cartData[index].category][product],
                        },
                    }));
                })
            );
        }


        // delete cartData[index];
        cartData.pop(index)


        setCart(cartData);

    };



    const getProductsAndCategoryApi = () => {
        setLoader(true)
        apiCounter++
        axios.get(getProductsAndCategoryUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setCategories(response.data.categories)
            setDealNames(response.data.dealNames)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    const getSaleLeftoversApi = () => {
        setLoader(true)
        apiCounter++
        axios.post(getSaleLeftoversUrl, {
            kiosk: saleData.kiosk,
            date: saleData.date
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            if (response.data !== 'No Production') {

                setLeftovers(response.data.leftovers)
                setInventory(response.data.inventory)
                setProductPrice(response.data.productPrice)
                setInventoryPrice(response.data.inventoryPrice)
                setDeals(response.data.deals)
            }
            else {
                setLeftovers({})
                setInventory({})
                setProductPrice({})
                setInventoryPrice({})
                setDeals({})
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    const addSaleApi = () => {
        setLoader(true)
        apiCounter++

        axios.post(addSaleUrl, { data: cart, inventory: cartInventory, type: orderType, orderNumber: orderNumber,discount:discount ,name:name}, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            Swal.fire(
                'Success',
                'Sale is add successfully',
                'success'
            )
            setCart([])
            setCartInventory({ inventoryPrice: 0 })
            setSaleData({ totalPrice: 0 })
            setOrderNumber('')
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire(
                    'warning',
                    error.response.data.message,
                    'warning'
                )
            }

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }
    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className='h-100 p-sm-4'>
                <div className="row">
                    <div className="col-4 d-flex align-items-center">
                        <span className='font-4'>Select Kiosk</span><div className='w-75 px-3'><CustomSelect value={saleData.kiosk} handleOnChange={setSelectedKiosk} text="Select Kiosk" disabled={cart.length ? true : false} /></div>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <span className='font-4'>Select Date</span><div className='w-75 px-3'>
                            <input type="date" style={{ padding: '2px 0' }} className='h-100 w-100 px-2' name="date" id="" value={saleData.date} onChange={handleOnChange} disabled={cart.length ? true : false} />
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <span className='font-4'>Select Category</span><div className='w-75 px-3'>
                            <select name="category" value={saleData.category} onChange={handleOnChange} className='h-100 w-100 px-2' id="" style={{ padding: '5px 0' }} disabled={Object.keys(leftovers).length ? false : true}>
                                <option value="">Select Category</option>

                                {
                                    categories.map((name, index) => {
                                        return <option value={name}>{name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-8">
                        <div className="row align-items-center">
                            <div className="col-12 d-flex">
                                <div className='mr-5'>
                                    <label htmlFor="kiosk" className=' font-4 m-0'><input type="radio" value='kiosk' name='type' id='kiosk' onChange={(e) => { setOrderType(e.target.value) }} checked={orderType === 'kiosk' ? true : false} disabled={cart.length ? true : false} />  Kiosk Sale</label>
                                </div>

                                <div className='mr-5'>
                                    <label htmlFor="foodpanda" className=' font-4 m-0'><input type="radio" value='foodpanda' name='type' id='foodpanda' onChange={(e) => { setOrderType(e.target.value) }} checked={orderType === 'foodpanda' ? true : false} disabled={cart.length ? true : false} />  Foodpanda Sale</label>
                                </div>
                                <div className='mr-5'>
                                    <label htmlFor="online" className=' font-4 m-0'><input type="radio" value='online' name='type' id='online' onChange={(e) => { setOrderType(e.target.value) }} checked={orderType === 'online' ? true : false} disabled={cart.length ? true : false} />  Online Sale</label>
                                </div>
                            </div>
                            <div className="col-1 mt-2">
                                <div className="d-flex">
                                    <input type="text" value={orderNumber} className='font-2' onChange={(e) => { setOrderNumber(e.target.value) }} placeholder='Order Number' disabled={orderType === "foodpanda" || orderType === "online" ? false : true} />
                                    <input type="text" value={discount} className='font-2 ml-3' onChange={(e) => { setDiscount(e.target.value) }} placeholder='Discount' />
                                    <input type="text" value={name} className='font-2 ml-3' onChange={(e) => { setName(e.target.value) }} placeholder='Name' />
                                </div>
                            </div>
                        </div>
                        {
                                    leftovers.length == 0 && <div style={{textAlign:'center'}}> 
                                    <img className='w-25' src={noDataIcon}/>
                                    <p className='font-2 semi-bold'>No production for kiosk</p>
                                    </div>
                                }

                        {
                            saleData.category && dealNames.includes(saleData.category) === false && <div className="row mt-4">
                                <div className="col-12">
                                    {saleData.category && <p className='m-0 font-4 mb-2'>{saleData.category}</p>}
                                </div>

                                {leftovers && saleData.category && leftovers[saleData.category] && Object.keys(leftovers[saleData.category]).map((key, index) => {
                                    return <div className='col-4 mb-3'>
                                        <div className=' d-flex sale-input-div justify-content-between align-items-center'>
                                            <p className='m-0 font-5'>{key}</p>
                                            <div>
                                                <MinusButton onClick={() => { handleMinusItem(key) }} />
                                                <span className='m-0 font-5 mx-1'>{saleData[saleData.category] && saleData[saleData.category][key] ? saleData[saleData.category][key] : 0}</span>
                                                <PlusButton onClick={() => { handleAddItem(key) }} />
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                        {
                            saleData.category && dealNames.includes(saleData.category) === true && <div className='row mt-4'>
                                <div className='col-4 mb-3'>
                                    <div className=' d-flex sale-input-div justify-content-between align-items-center'>
                                        <p className='m-0 font-5'>{saleData.category}</p>
                                        <div>
                                            <MinusButton onClick={() => { if (dealQuantity > 1) { setDealQuantity(dealQuantity - 1) } }} />
                                            <span className='m-0 font-5 mx-1'>{dealQuantity}</span>
                                            <PlusButton onClick={() => { setDealQuantity(dealQuantity + 1) }} />
                                        </div>
                                    </div>
                                </div>
                                
                                {


                                    Object.keys(deals[saleData.category].items).map((key, index) => {

                                        return <div className="col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p className='m-0 font-4 mb-2'>{key}</p>
                                                    <div className="row">
                                                        {
                                                            deals[saleData.category].items[key].products.map((item, index) => {
                                                                if (categories.includes(item)) {
                                                                    
                                                                    return Object.keys(leftovers[item]).map((productName) => {
                                                                        return <div className='col-4 mb-3'>
                                                                            <div className=' d-flex sale-input-div justify-content-between align-items-center'>
                                                                                <p className='m-0 font-5'>{productName}</p>
                                                                                <div>
                                                                                    <MinusButton onClick={() => { handleDealMinusItem(productName, key) }} />
                                                                                    <span className='m-0 font-5 mx-1'>{saleData[key] && saleData[key][productName] ? saleData[key][productName] : 0}</span>
                                                                                    <PlusButton onClick={() => { handleDealAddItem(productName, key) }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                                else {
                                                                  
                                                                        return <div className='col-4 mb-3'>
                                                                        <div className=' d-flex sale-input-div justify-content-between align-items-center'>
                                                                            <p className='m-0 font-5'>{item}</p>
                                                                            <div>
                                                                                <MinusButton onClick={() => { handleDealMinusItem(item, key) }} />
                                                                                    <span className='m-0 font-5 mx-1'>{saleData[key] && saleData[key][item] ? saleData[key][item] : 0}</span>
                                                                                    <PlusButton onClick={() => { handleDealAddItem(item, key) }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }

                        {saleData.category && <div className="row mt-2">
                            <div className="col-12">
                                <div className='d-flex align-items-center'><p className='font-4 m-0'>Total Price</p> <div className='font-4  mx-3 total-price text-center'>
                                    {saleData.totalPrice}
                                </div>
                                    <GradientButton text={buttonText} className="font-4" onClick={addToCart} />
                                </div>
                            </div>
                        </div>}

                        {/* {
                            console.log(Object.keys(saleData).length,saleData,'----------hjhjhjhjhjhjhjhjhjhjhjhjhjhjhjhj')
                        } */}




                        <div className="row mt-4">
                            <div className="col-12">
                                {inventory && <p className='m-0 font-4 mb-2'>Select Inventory</p>}
                            </div>

                            {inventory && Object.keys(inventory).map((key, index) => {
                                return <div className='col-4 mb-3'>
                                    <div className=' d-flex sale-input-div justify-content-between align-items-center'>
                                        <p className='m-0 font-5'>{key}</p>
                                        <div>
                                            <MinusButton onClick={() => { handleMinusInventory(key) }} />
                                            <span className='m-0 font-5 mx-1'>{cartInventory[key] ? cartInventory[key] : 0}</span>
                                            <PlusButton onClick={() => { handleAddInventory(key) }} />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        {Object.keys(cartInventory).length > 1 && <div className="row mt-2">
                            <div className="col-12">
                                <div className='d-flex align-items-center'><p className='font-4 m-0'>Inventory Price</p> <div className='font-4  mx-3 total-price text-center'>
                                    {cartInventory.inventoryPrice}
                                </div>
                                    {/* <GradientButton text={buttonText} className="font-4" onClick={addToCart} /> */}
                                </div>
                            </div>
                        </div>}


                    </div>
                    <div className="col-4">
                        <div className='cart-div h-100 p-3'>
                            <div><img src={cartIcon} alt="" /> <span className='pl-2 font-2 semi-bold'>Cart</span></div>
                            {
                                cart.map((data, index) => {
                                    if (data.category !== 'inventory') {
                                        return <div className="row mt-2 mb-2">
                                            <div className="col-10">
                                                <div className='cart p-2'>
                                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                                        <p className='m-0 font-2'>Kiosk: {data.kiosk}</p>
                                                        <p className='m-0 font-2'>{data.date}</p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <p className='m-0 font-2'>Category: {data.category}</p>
                                                        <p className='m-0 font-2'>Total Price: {data.totalPrice}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 pl-0">
                                                <div className='w-100 d-flex flex-column justify-content-around  h-100 align-items-center'>

                                                    <img className='img-btn' src={editCartIcon} alt="" onClick={() => { editCartItem(index) }} />
                                                    <img className='img-btn' src={deletCarIcon} alt="" onClick={() => { deleteCartItem(index) }} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                })
                            }

                            {cart.length > 0 && <div className="row mt-4 mb-4">
                                <div className="col-6">
                                    <button id='clear-cart' className='w-100 h-100' onClick={() => { setCart([]); setCartInventory({ 'inventoryPrice': 0 }); setOrderNumber('') }}>Clear Cart</button>
                                </div>
                                <div className="col-6">
                                    <GradientButton text="Add Sale" style={{maxWidth:'100%'}} onClick={() => { addSaleApi() }} />
                                </div>
                            </div>
                            }





                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
