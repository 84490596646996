import React,{useContext, useEffect} from 'react'
import './CustomSelect.css'
import { kioskContext,selectedKioskContext } from '../../App'

export default function CustomSelect(props) {
  const kiosk = useContext(kioskContext)
  // const [selectedKiosk,setSelectedKiosk] = useContext(selectedKioskContext)
  
 
  return (
            <select name={props.name} id="custom-select" className={'w-100 font-2 '+ props.className} value={props.value} onChange={(e)=>(props.handleOnChange(e.target.value))} disabled={props.disabled}>
                <option value="">{props.text}</option>
                {
                  kiosk.map((key,index)=>{
                 
                    return <option value={key.kioskName}>{key.kioskName}</option>
                  })
                }
              </select>
  )
}
