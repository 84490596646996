
//Bakery

// Production
export const getProductionColumns = 'production/get-production-columns'
export const assignProduction = 'production/assign-production'
export const getProduction = 'production/get-production'
export const submitProduction = 'production/submit-production'
export const clearProduction = 'production/clear-production'


// production history
export const productionHistory = 'production/history'

// Movement
export const getKioskLeftover = 'movement/get-kiosk-leftover'
export const addMovement = 'movement/add-movement'
export const getMovement = 'movement/get-movement-history'


// Add Sale
export const getSaleLeftovers = 'sale/get-leftovers'
export const addSale = 'sale/add-sale'



export const Edit = 'bakery/data/edit';

export const Edit_Add_Prod = '/bakery/data/edit/add/prod';

export const Bakery_Data = 'bakery/data';

export const Add_prod_Bakery_Data = 'bakery/data/add/prod';

export const Bakery_Data_Edit = 'bakery/data/edit';

export const Bakery_Data_Edit_Add_Prod = '/bakery/data/edit/add/prod';

export const Bakery_Table = 'bakery/table';

export const clear_production = 'bakery/table/clear';
export const clear_add_production = '/bakery/table/clear/add/prod';

export const flav_name = 'bakery/flav/quat';

export const branch_name = 'bakery/branch/names';

export const Add_Movement = '/bakery/movement/add';

export const Get_perct = 'bakery/percentage/get';

export const Set_perct = 'bakery/percentage/set';

export const Clear_perct = 'bakery/percentage/clear';

export const bakery_Kiosk = 'bakery/kiosk/get';

export const bakery_movement_Kiosk = 'bakery/kiosk/movement/get';

export const History = 'bakery/history';

// export const Notifications = '/bakery/notifications/get';

export const Notifications = 'notification/get';
export const Unread_Notification = 'unreadnotification/get';
export const ReadNotifications = 'notification/read';

export const Submit = '/bakery/today/submit';

export const Submit_Add_Prod = '/bakery/today/submit/add/prod';

export const liveSales = '/today/live/stats';


//Admin
export const Login = 'auth/login';


//Dashboard
export const Dashboard = 'dashboard/get';
export const topFlavours = '/admin/dashboard/top-flavours';


// Kiosk
export const Kiosk = 'kiosk/get';
export const AddKiosk = 'kiosk/add';
export const EditKiosk = 'kiosk/edit';
export const DeleteKiosk = 'kiosk/delete';

// kiosk prices 
export const GetKioskPrice = 'kiosk/getproductkprices';
export const ChangeKioskPrice = 'kiosk/editproductprice';
export const GetKioskDealPrice = 'kiosk/getdealprice';
export const SetKioskDealPrice = 'kiosk/setkdealprice';

//Employee
export const Employee = 'employee/get';
export const AddEmployee = 'employee/add';
export const EditEmployee = 'employee/edit';
export const DeleteEmployee = 'employee/delete';

export const Attendance = 'attendance/get';

//Product
export const Products = 'products/get';
export const AddProducts = 'products/add';
export const EditProducts = 'products/edit';
export const DeleteProducts = 'products/delete';
export const AddCategory = 'products/addcategory';
export const getCategory = 'admin/products/getcategory';

// Deals
export const AddDeal = 'products/adddeal';
export const EditDeal = 'products/editdeal';
export const GetDeal = 'products/getdeal';
export const DeleteDeal = 'products/deletedeal';

// set percentage
export const getPercentage = 'products/get-percentage';
export const setPercentage = 'products/set-percentage';

//Requestable Accessories
export const Requestable_get = 'inventory/requestable/get';
export const Requestable_add = 'inventory/requestable/add';
export const Requestable_edit = 'inventory/requestable/edit';
export const Requestable_delete = 'inventory/requestable/delete';

// Accessories Category
export const AddItemsCategory = 'admin/inventory/addcategory';
export const GetItemsCategory = 'admin/inventory/getcategory';


// Inventory
//Daily
export const DailyInventoryGet = 'inventory/daily/get';
export const DailyInventoryAdd = 'inventory/daily/add';
export const DailyRequestedInv = 'inventory/requested/get';

//Fixed
export const FixedInventoryGet = 'inventory/fixed/get';
export const FixedInventoryAdd = 'inventory/fixed/add';



//Sale Channel
export const SaleData = 'salechannel/get';
// export const SaleFoodPanda = '/admin/salechannel/food_panda/get';
// export const SaleOnlineStore = '/admin/salechannel/online/get';
// export const SaleBaskyt = '/admin/salechannel/baskyt/get';
// export const SalePandaGo = '/admin/salechannel/pandago/get';
// export const SaleBykea = '/admin/salechannel/bykea/get';
// export const OverAll = '/admin/salechannel/overall/get';
export const OrderDetail = 'salechannel/getordersdetail'
export const CancelSale = 'salechannel/cancel-sales'
export const Demage = 'salechannel/demagelost/get';

//settings
export const ChangePass = '/admin/settings/change/pass';

export const getBatchPrice = '/admin/settings/getbatch';
export const ChangeBatchPrice = '/admin/settings/changebatch';

// admin Franchise
export const getFranchise = 'franchise/get'
export const addFranchise = 'franchise/add'
export const editFranchise = 'franchise/edit'
export const deleteFranchise = 'franchise/delete'
export const getFranchiseDetail = 'franchise/detail'
export const saveFrachiseDetail = 'franchise/detail/save'

// franchise
export const getFranchiseKiosk = 'franchise/getKiosks'
export const getFranchiseData = 'franchise/getdata'
export const getFranchiseLeftover = 'franchise/get-leftover'
export const getFranchiseLiveSale = 'franchise/livesales'

// Live Sale

export const liveDealSale = 'livesale/dealsale'
export const liveProductSale = 'livesale/product-sale'


// Log History

export const aLogHistory = 'log-history'


// Permissions 
export const getUserPermissions = '/get-user-permissions'
export const assignRoleAndPermission = '/assign-user-role-permission'
export const addNewUser = 'add-new-user'
export const deleteUser = 'delete-user'


// Groups

export const getGroups = 'get-groups'
export const addGroup = 'add-group'
export const editGroup = 'edit-group'
export const deleteGroup = 'delete-group'


export const getUserGroupAndPermissions = 'get-user-group-permissions'


