import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import saleIcon from '../../Assets/sales-icon.svg'
import canceledSaleIcon from '../../Assets/cancel-sale-icon.svg'
import demageLostIcon from '../../Assets/demage-lost-icon.svg'

import accessoriesIcon from '../../Assets/accessories-icon.svg'
import InventorySubIcon from '../../Assets/inventory-sub-icon.svg'
import inventoryRequestIcon from '../../Assets/inventory-request.svg'
import inventoryItemsIcon from '../../Assets/inventory-items-icon.svg'


import productsSubIcon from '../../Assets/products-sub-icon.svg'
import dealsIcon from '../../Assets/deals-icon.svg'
import setPercentageIocn from '../../Assets/set-percentage-icon.svg'

import franchiseListIcon from '../../Assets/franchise-list-icon.svg'


import dealSaleIcon from '../../Assets/deal-sale-icon.svg'
import productSaleIcon from '../../Assets/product-sale-icon.svg'
import liveStatsIcon from '../../Assets/live-status-icon.svg'


import kioskIcon from '../../Assets/kiosk-icon.svg'
import employeesIcon from '../../Assets/employee-icon.svg'
import attendanceIcon from '../../Assets/attendance-icon.svg'


import notificationCenter from '../../Assets/notification-center-iocn.svg'


import permissionsIcon from '../../Assets/permissions-icon.svg'
import logHistoryIcon from '../../Assets/log-history-icon.svg'
import settingsIcon from '../../Assets/settings-sub-icon.svg'


import addMovementIcon from '../../Assets/add-movement.svg'
import movementHistoryIcon from '../../Assets/movement-history.svg'

import { permissionsContext } from '../../App'


export default function SubSideBar(props) {

    const permissions = useContext(permissionsContext)



    if (window.location.href.includes('sales')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Can View Sale') && <NavLink to='/sales/sales' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={saleIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Sales</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Cancel Sale') && <NavLink to='/sales/canceled-sales' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img src={canceledSaleIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Canceled Sales</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Damage and Lost') && <NavLink to='/sales/demage-lost' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img src={demageLostIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Damage & Lost</span>
                </NavLink>
            }



        </div>
    }
    else if (window.location.href.includes('inventory')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Can View Fix Accessories') && <NavLink to='/inventory/accessories' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={accessoriesIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Accessories</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Daily Inventory') && <NavLink to='/inventory/inventory' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={InventorySubIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Inventory</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Inventory Request') && <NavLink to='/inventory/inventory-request' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={inventoryRequestIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Inventory Request</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Requestable Inventory') && <NavLink to='/inventory/inventory-items' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={inventoryItemsIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Inventory Items</span>
                </NavLink>
            }



        </div>
    }
    else if (window.location.href.includes('products')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Can View Products') && <NavLink to='/products/products' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={productsSubIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Products</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Deals') && <NavLink to='/products/deals' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={dealsIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Deals</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Production Percentage of Product') && <NavLink to='/products/set-percentage' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={setPercentageIocn} alt="" /> <span className='font-1 semi-bold pl-1'>Set Percentage</span>
                </NavLink>
            }


        </div>
    }
    else if (window.location.href.includes('franchise')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Can View Franchise') && <NavLink to='/franchise/franchise-list' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={franchiseListIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Franchise</span>
                </NavLink>
            }


        </div>
    }
    else if (window.location.href.includes('live')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Can View Live Deals Sale') && <NavLink to='/live/deal-sale' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={dealSaleIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Live Deals Sale</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Live Products Sale') && <NavLink to='/live/product-sale' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={productSaleIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Live Products Sale</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Live Stats') && <NavLink to='/live/stats' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={liveStatsIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Live Stats</span>
                </NavLink>
            }



        </div>
    }

    else if (window.location.href.includes('reports')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Franchise Can View Sale Overview') && <NavLink to='/reports/sale-overview' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={dealSaleIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Sale Overview</span>
                </NavLink>
            }
            {/* {
                permissions.includes('Franchise Can View Leftovers') && <NavLink to='/live/product-sale' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={productSaleIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Live Products Sale</span>
                </NavLink>
            } */}
            



        </div>
    }

    else if (window.location.href.includes('user-management')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes("Can View Kiosk") && <NavLink to='/user-management/kiosk' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={kioskIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Kiosk</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Employee') && <NavLink to='/user-management/employees' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={employeesIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Employees</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Attendance') && <NavLink to='/user-management/attendance' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={attendanceIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Attendance</span>
                </NavLink>
            }

        </div>
    }
    else if (window.location.href.includes('notifications')) {
        return <div className='sub-sidebar h-100'>
            {
                (permissions.includes('Can View Admin Notifications') || permissions.includes('Can View Bakery Notifications')) && <NavLink to='/notifications/notification-center' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={notificationCenter} alt="" /> <span className='font-1 semi-bold pl-1'>Notification Center</span>
                </NavLink>
            }
        </div>
    }

    else if (window.location.href.includes('movement')) {
        return <div className='sub-sidebar h-100'>
            {
                (permissions.includes('Can Make Movement Kiosk to Kiosk') ) && <NavLink to='/movement/add-movement' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={addMovementIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Add Movement</span>
                </NavLink>
            }
            {
                (permissions.includes('Can View Movement History') ) && <NavLink to='/movement/movement-history' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={movementHistoryIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Movement History</span>
                </NavLink>
            }
        </div>
    }

    else if (window.location.href.includes('settings')) {
        return <div className='sub-sidebar h-100'>
            {
                permissions.includes('Can View User Permissions and Roles') && <NavLink to='/settings/permissions' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={permissionsIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Permissions</span>
                </NavLink>
            }
            {
                permissions.includes('Can View Groups') && <NavLink to='/settings/group-permissions' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={permissionsIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Group Permissions</span>
                </NavLink>
            }

            {
                permissions.includes('Can View Log History') && <NavLink to='/settings/log-history' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={logHistoryIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Log History</span>
                </NavLink>
            }
            {
                permissions.includes('Change Password For Users') && <NavLink to='/settings/settings' className='sub-nav-link' onClick={props.changeSidebarWidth}>
                    <img className="h-75" src={settingsIcon} alt="" /> <span className='font-1 semi-bold pl-1'>Settings</span>
                </NavLink>
            }



        </div>
    }
}
