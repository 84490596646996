import React, { useState, useEffect,useContext } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import { Modal } from "react-bootstrap";
import { TopBarTitleContext } from '../../App'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const getLiveDealSaleUrl = axiosUrls.liveDealSale

export default function DealSale() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [liveSaleData, setLiveSaleData] = useState([])
  const [isDetail, setIsDetail] = useState(false)
  const [detailIndex, setDetailIndex] = useState()

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)
  

  setTopBarTitle('Live Sale Stats')

  const columns = [
    {
      label: 'Deal Name',
      field: 'dealName',
    }, {
      label: 'Kiosk Name',
      field: 'kioskName',
    }, {
      label: 'Time',
      field: 'time'
    }, {
      label: 'Items',
      field: 'details'
    }, {
      label: 'Quantity',
      field: 'quantity'
    }
  ]

  useEffect(() => {
    getLiveDealSaleApi()
  }, [])

  const getLiveDealSaleApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getLiveDealSaleUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      const data = response.data
      for (let i = 0; i < data.length; i++) {
        data[i]['details'] = <button className='detail-btn' value={i} onClick={(e) => { setIsDetail(true); setDetailIndex(e.target.value) }}>Details</button>
      }
      setLiveSaleData({ columns: columns, rows: response.data })
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }
  if (loader) {
    return <Loader />
  }
  else {
    return (
      <div className="h-100">
        <div className="row align-items-center">
          <div className="col-9">
            <span className='font-2 semi-bold'>Live Deals Sale</span>
          </div>

          <div className="col-3" style={{ textAlign: 'end' }}>
            {/* <BlackButton text="Add Category" className="mr-3 font-6" style={{padding:'7px'}}/> */}
            {/* <GradientButton text="Add New Product"/> */}
            <DownloadButton className="ml-3"  data={liveSaleData} filename="LiveDealSaleReport.csv" />
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={liveSaleData} />
        </div>

        <div className="mobile row py-2">
          <div className="col">
              <p className='font-2 semi-bold mb-1'>Deals List</p>
          </div>

              {
                liveSaleData.rows && liveSaleData.rows.map((data, index) => {
                  return <div className="col-12 " >
                    <div className="mobile-item-box-div my-1" onClick={() => { setIsDetail(true); setDetailIndex(index) }}>
                    <div className="d-flex justify-content-between py-1">
                      <span className='font-2'>{data.dealName}</span>
                      <span className='font-2 d-flex '>
                          Qunatity: {data.quantity}
                        </span>

                    </div>
                    <div className="d-flex justify-content-between">
                      <span className='font-2'>{data.kioskName}</span>
                      <span className='font-2'>{data.time}</span>
                    </div>
                    </div>
                    
                  </div>
                })
              }


            </div>



        <Modal
          show={isDetail}
          onHide={() => { setIsDetail(false) }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <div className='p-3'>

              <p className='text-center font-3'>Items Details</p>
              
              {
                // console.log(detailIndex && liveSaleData.rows[detailIndex].items,'--------------------')

                detailIndex>=0 && liveSaleData.rows[detailIndex].items.map((data, index) => {
                  return <div key={index} className='row item-box my-2 p-2 align-items-center'>
                    
                    <div className="col-1 pr-0">
                      <span className='font-2'>{index + 1}.</span>
                    </div>
                    <div className="col-9">
                      <p className='font-2 m-0'>{data.name}</p>

                    </div>
                    <div className="col-2 ">
                      <p className='font-2 m-0 '>{data.quantity}</p>
                    </div>
                  </div>
                })
              }
            </div>









          </Modal.Body>

        </Modal>
      </div>
    )
  }
}
