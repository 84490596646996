import React, { useState ,useContext} from 'react'
import './Setting.css'
import Loader from '../../Components/Loader/Loader'
import BlackButton from '../../Components/BlackButton/BlackButton'
import Swal from 'sweetalert2';
import { TopBarTitleContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const changePasswordUrl = axiosUrls.ChangePass

export default function Settings() {



  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [settingData,setSettingData] = useState({})
  const [errors,setErrors] = useState({})

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Settings')


  const handleOnChange = (e) =>{
    const {name,value} = e.target
    setSettingData(prevState =>({
      ...prevState,
      [name] :value
    }))
  }

  const changePasswordApi = () =>{
      setLoader(true)
      apiCounter++
      axios.post(changePasswordUrl,settingData,{
        headers:{
          'Authorization' : token
        }
      }).then(response =>{
        console.log(response)
        setErrors({})
        setSettingData({})
        Swal.fire(
          'Success',
          'Password is updated successfully',
          'success'
        )
       
        apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
      }).catch(error =>{
        console.log(error)
        if(error.response.status = 422){
          setErrors(error.response.data.errors)
        }
        apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
      })
  }

  if (loader) {
    return <Loader />
  }
  else {


    return (
      <div className='h-100 p-4'>
        <p className='font-2 semi-bold'>General Setting</p>
        <div className="row">
          <div className="col-sm-9 setting-box p-3">
            <div className="row">
              <div className="col-12">
                <span className='font-2'>Admin</span>
                <hr className='my-3' />
              </div>


              <div className="col-12 py-1">
                <label htmlFor="email" className='m-0 font-2'>Email Address</label>
                <input type="email" id="email" name="emailAddress" value={settingData.emailAddress} className={'form-control font-2 ' + (errors['emailAddress'] ? 'is-invalid' : '')} required  onChange={handleOnChange}/>
                <div className="invalid-feedback">
                  {errors['emailAddress'] && errors['emailAddress']}
                </div>
              </div>


              <div className="col-sm-6 py-1">
                <label htmlFor="password" className='m-0 font-2'>New Password</label>
                <input type="password" id="password" name="password" value={settingData.password} className={'form-control font-2 ' + (errors['password'] ? 'is-invalid' : '')} required  onChange={handleOnChange}/>
                <div className="invalid-feedback">
                  {errors['password'] && errors['password']}
                </div>
              </div>


              <div className="col-sm-6 py-1">
                <label htmlFor="password_confirmation" className='m-0 font-2'>Confirm Password</label>
                <input type="password" id="password_confirmation" name="password_confirmation" value={settingData.password_confirmation} className={'form-control font-2 ' + (errors['password_confirmation'] ? 'is-invalid' : '')} required  onChange={handleOnChange}/>
                <div className="invalid-feedback">
                  {errors['password_confirmation'] && errors['password_confirmation']}
                </div>
              </div>

              <div className="col-12 my-3">

              <div className="d-flex justify-content-center">
                <BlackButton text="Save Changes"  style={{padding:'4px'}} onClick={changePasswordApi}/>
              </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    )
  }
}
