import React, { useEffect, useState,useContext } from 'react'
import Loader from '../../Components/Loader/Loader'
import BlackButton from '../../Components/BlackButton/BlackButton'
import CancelButton from '../../Components/CancelButton/CancelButton'
import Swal from 'sweetalert2'
import { TopBarTitleContext } from '../../App'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getKioskLeftoverUrl = axiosUrls.getKioskLeftover
const addMovementUrl =  axiosUrls.addMovement


export default function Movement() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [kiosks, setKiosks] = useState([])
    const [flavoruData, setFlavourData] = useState([])
    const [movementData, setMovementData] = useState([])

    const [errors,setErrors] =useState([])

    const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Movement')
    
    useEffect(() => {
        getKioskLeftoverApi()
    }, [])
    

    // useEffect(()=>{
    //     setMovementData([])
    // },[movementData.movingFrom])



    const handleMovementChange = (e) => {
        const { name, value } = e.target; 
        console.log(value)
        
        // if(name === 'movingFrom'){
        //     setMovementData([])
        //     const updatedState = { ...prevState };
        // }

        setMovementData((prevState) => {
            const updatedState = { ...prevState };
            if (value === 0 || value === '') {
                delete updatedState[name];
              } else {
                updatedState[name] = value;
              }
            
              return updatedState;
        });

        
    };

    // const handleMovementProductChange = (e) =>{
    //     const { name, value } = e.target; 
    //     console.log(name)
    //     setMovementData((prevState) => ({
    //         ...prevState,
    //         ['productData']: {...prevState.productData,[name]:value},
    //     }));
    // }




    const getKioskLeftoverApi = () => {
        setLoader(true)
        apiCounter++
        axios.get(getKioskLeftoverUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)

            setKiosks(response.data.kiosks)
            setFlavourData(response.data.kioskLeftovers)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)

            

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        })
    }

    const addMovementApi = (e) => {
        setLoader(true)
        apiCounter++ 
        axios.post(addMovementUrl, {data:movementData},{
            headers :{
                'Authorization' : token,
            }
        }).then(response=>{
            console.log(response)
            Swal.fire(
                'Success',
                'New movement is made',
                'success'
              )
            setMovementData([])
            getKioskLeftoverApi()
            apiCounter--
            if(apiCounter ===0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)

            if(error.response.status === 422){
                setErrors(error.response.data.errors)
            }

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className='h-100'>
                <div className="row">
                    <div className="col">
                        <p className='font-2 semi-bold'>Add New Movement</p>
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center">
                                        <label htmlFor="moving-from" className='font-2 semi-bold m-0'>Moving From</label>
                                        <div className="w-50 ml-4">
                                            <select name="movingFrom" id="moving-from" className={'w-100 form-control ' + (errors['movingFrom']?'is-invalid':'')} value={movementData.movingFrom} onChange={handleMovementChange}>
                                                <option value=''>Select from kiosk</option>
                                                {
                                                    kiosks.map((key, index) => {
                                                        return <option value={key}>{key}</option>
                                                    })
                                                }
                                            </select>
                                            <div className="invalid-feedback">
                                                {errors['movingFrom'] && errors['movingFrom']}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center">
                                        <label htmlFor="moving-to" className='font-2 semi-bold m-0'>Moving To</label>
                                        <div className="w-50 ml-4">
                                            <select name="movingTo" id="moving-to" className={'w-100 form-control ' + (errors['movingTo']?'is-invalid':'')} value={movementData.movingTo} onChange={handleMovementChange}>
                                                <option >Select to kiosk</option>
                                                {
                                                    kiosks.map((key, index) => {
                                                        return <option value={key}>{key}</option>
                                                    })
                                                }
                                            </select>
                                            <div className="invalid-feedback">
                                                {errors['movingTo'] && errors['movingTo']}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {
                            movementData.movingFrom && <div className="row">
                                <div className="col">
                                    <p className='font-2 semi-bold mt-4'>Enter Required Product Amount :</p>
                                    {errors['products'] = <div style={{color:'red'}}>{errors['products']}</div>}
                                    <div className="row">
                                        {
                                            // console.log(flavoruData[movementData.movingFrom])
                                            Object.keys(flavoruData[movementData.movingFrom]).map((key, index) => {
                                                return key !=='movingFrom' && key !== 'movingTo' && <div className="col-6 mt-2">
                                                    
                                                <div className="row align-items-center">
                                                    <div className="col-5">
                                                        <span className='font-2'>{key} (Qty: {flavoruData[movementData.movingFrom][key]})</span>
                                                    </div>
                                                    <div className="col-7">
                                                        <input type="text" value={movementData[key]} className={'form-control font-2 ' + (errors[key]?'is-invalid':'') } placeholder='Enter Quantity' name={key} onChange={handleMovementChange} />
                                                        <div className="invalid-feedback">
                                                            {errors[key] && errors[key]}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            })

                                        }


                                    </div>
                                    <div className="row mt-3">
                                        <div className="col text-end" style={{textAlign:'end'}}>
                                            <CancelButton text="Cancel" style={{width:'170px'}}/>
                                            <BlackButton text="Move Products" style={{padding:'6px'}} onClick = {addMovementApi}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>
        )
    }
}
