import React, { useEffect, useState,useContext } from 'react'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import { TopBarTitleContext } from '../../App'
import { subDays } from 'date-fns'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const logHistoryUrl = axiosUrls.aLogHistory

export default function LogHistory() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [logData, setLogData] = useState([])
  const [dates, setDates] = useState([subDays(new Date(), 30), new Date()])

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Settings')

  useEffect(() => {
    logHistoryApi()
  }, [dates])


  const columns = [
    {
      label: 'User',
      field: 'user',

    },
    {
      label: 'Module',
      field: 'module',

    },
    {
      label: 'Action',
      field: 'action',

    },

    {
      label: 'Date',
      field: 'date',

    }, {
      label: 'Time',
      field: 'time',
    }
  ]


  const logHistoryApi = () => {
    setLoader(true)
    apiCounter++
    axios.post(logHistoryUrl, {
      dates: dates
    }, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      setLogData({ columns: columns, rows: response.data })
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  if (loader) {
    return <Loader />
  }
  else {



    return (
      <div className="h-100">
        <div className="row">
          <div className="col-8 desktop">
            <span className='font-2 semi-bold'>Log History</span>
          </div>
          <div className="col-sm-4">
            <div className='row'>
              <div className="col-sm-10 pr-0 col-9">

                <CustomDatePicker dates={dates} setDates={setDates} />
              </div>
              <div className="col-sm-2 col-3" style={{ textAlign: 'end' }}>

                <DownloadButton data={logData} filename="logHistory.csv" />
              </div>
            </div>
          </div>




        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={logData} />
        </div>

        <div className="mobile py-2 row">
          <div className="col">

            <p className='font-2 semi-bold mb-1'>Log History</p>
          </div>

          {
            logData.rows && logData.rows.map((data, index) => {
              return <div className="col-12 " >
                <div className="mobile-item-box-div my-1">
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.user}</span>
                    <span className='font-2'>action: {data.action}</span>

                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>Module: {data.module}</span>
                    <span className='font-2'>{data.date} - {data.time}</span>
                  </div>
                </div>

              </div>
            })
          }


        </div>
      </div>
    )
  }
}
