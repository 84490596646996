import logo from './logo.svg';
import './App.css';
import { useEffect, useState, createContext, useContext } from 'react';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BrowserRouter, Routes, Route,useNavigate } from 'react-router-dom';



// Components
import Topbar from './Components/Topbar/Topbar';
import Sidebar from './Components/Sidebar/Sidebar';


// Pages
import Login from './Pages/Login/Login';




import Dashboard from './Pages/Dashboard/Dashboard';
import Sales from './Pages/Sales/Sales';
import CancelSales from './Pages/Sales/CancelSales';
import DemageAndLost from './Pages/Sales/DemageAndLost';
import Accessories from './Pages/Inventory/Accessories';
import Inventory from './Pages/Inventory/Inventory';
import InventoryRequest from './Pages/Inventory/InventoryRequest';
import InventoryItems from './Pages/Inventory/InventoryItems';
import Products from './Pages/Products/Products';
import Deals from './Pages/Products/Deals';
import SetPercentage from './Pages/Products/SetPercentage';
import FranchiseList from './Pages/Franchise/FranchiseList';
import DealSale from './Pages/Live/DealSale';
import ProductSale from './Pages/Live/ProductSale';
import LiveStats from './Pages/Live/LiveStats';
import Kiosk from './Pages/UserManagement/Kiosk';
import Employees from './Pages/UserManagement/Employees';
import Attendance from './Pages/UserManagement/Attendance';
import Permissions from './Pages/Settings/Permissions';
import LogHistory from './Pages/Settings/LogHistory';
import Settings from './Pages/Settings/Settings'
import GroupPermissions from './Pages/Settings/GroupPermissions';
import Notifications from './Pages/Notifications/Notifications';
import BakeryProduction from './Pages/BakeryProduction/BakeryProduction';
import ProductionHistory from './Pages/BakeryProduction/ProductionHistory';
import Movement from './Pages/Movement/Movement';
import MovementHistory from './Pages/Movement/MovementHistory';
import SaleOverview from './Pages/Franchise/SaleOverview';
import AddSale from './Pages/AddSale/AddSale';
import { useLocation } from 'react-router-dom';


import noPermissions from './Assets/no-permissions.png'

import Loader from './Components/Loader/Loader';

import axios from './Api/Axios'
import * as axiosUrls from './Api/AxiosUrls'

var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getKioskUrl = axiosUrls.Kiosk
const getUserGroupAndPermissionsUrl = axiosUrls.getUserGroupAndPermissions

export const kioskContext = createContext()
export const selectedKioskContext = createContext();

export const TopBarTitleContext = createContext()

export const permissionsContext = createContext()
export const groupsContext = createContext()

export const createdRouteContext  = createContext()
function App() {
  // const navigate = useNavigate()
  const [sidebarWidth, setSidebarWidth] = useState(false)
  const [kiosk, setKiosk] = useState([])
  const [selectedKiosk, setSelectedKiosk] = useState()
  const [topBarTitle, setTopBarTitle] = useState('Dashboard')

  const [permissions, setPermissions] = useState([])
  const [groups, setGroups] = useState([])

  const [loader ,setLoader] = useState(false)
  const [back,setBack] = useState(true)
  const [isKioskChange,setIsKioskChange]  = useState(false)
  const [loadUnread,setloadUnread] = useState(false)
  const [scale,setScale] = useState(1)

  let apiCounter = 0





 




  useEffect(() => {
    
    var styles = document.documentElement.style;
    if (window.location.pathname !== '/dashboard' && window.location.pathname !== '/bakery/production' && window.location.pathname !== '/produced-history' &&  window.location.pathname !== '/add/sale') {
     

      if (window.innerWidth > 1500) {
        styles.setProperty('--sub-sidebar-width', '275px');
        styles.setProperty('--sub-sidebar', 'block');
        styles.setProperty('--left', '275px');
      }
      else if (window.innerWidth < 576) {
        styles.setProperty('--sub-sidebar-width', '235px');
        styles.setProperty('--left', '0px');
        styles.setProperty('--sub-sidebar', 'block');
        styles.setProperty('--sub-sidebar-position', 'absolute');
      }
      else {
        styles.setProperty('--sub-sidebar-width', '235px')
        styles.setProperty('--sub-sidebar', 'block')
        styles.setProperty('--left', '235px');
      }
    }
    else {
      
      styles.setProperty('--sub-sidebar-width', '65px')
      styles.setProperty('--sub-sidebar', 'none')
      styles.setProperty('--left', '0px');
    }
    const handlePopstate = () => {
      setBack(!back)
    };

    window.addEventListener('popstate', handlePopstate);
  }, [sidebarWidth,back])



 



  const changeSidebarWidth = () => {
    setSidebarWidth(!sidebarWidth)
    if (window.innerWidth < 576) {
      var styles = document.documentElement.style;
      // styles.getItem
      // console.log(styles.getPropertyValue('--sub-sidebar-left'),'----------------------1111111')
      // if(styles.getPropertyValue('--sub-sidebar-left')==="65px"){
      styles.setProperty('--sub-sidebar-left', '-235px');
      // }
      // else{

      //   styles.setProperty('--sub-sidebar-left', '65px');
      // }
    }



  }



  useEffect(() => {
    if(window.location.pathname != '/' && localStorage.getItem('dwf-token') === null){
      window.location = '/'
    }
    setloadUnread(!loadUnread)
  }, [window.location.pathname]);

  useEffect(() => {
    // if (permissions.includes('Can View Kiosk')) {

      // getKioskApi();
    // }


    getUserGroupAndPermissionsApi();

  }, [])

  useEffect(() => {

      getKioskApi();


  }, [])

  const hideSubSidebar = () => {
    if (window.innerWidth < 576) {
      var styles = document.documentElement.style;
      styles.setProperty('--sub-sidebar-left', '-235px');
    }
  }



  const getKioskApi = () => {
      
      setLoader(true)
      apiCounter++
    if (localStorage.getItem('dwf-token')) {
      axios.get(getKioskUrl, {
        headers: {
          'Authorization': token,
        }
      }).then(response => {
        console.log(response)
        setKiosk(response.data)

        apiCounter--
        if(apiCounter === 0){
          setLoader(false)
        }

      }).catch(error => {
        console.log(error,)

        
        apiCounter--
        if(apiCounter === 0){
          setLoader(false)
        }
      })
    }


  }

  const getUserGroupAndPermissionsApi = () => {
    if (localStorage.getItem('dwf-token')) {
      setLoader(true)
      apiCounter++
      axios.get(getUserGroupAndPermissionsUrl, {
        headers: {
          'Authorization': token,
        }
      }).then(response => {
        console.log(response)
        setPermissions(response.data.permissions)
        setGroups(response.data.groups)


        apiCounter--
        if(apiCounter === 0){
          setLoader(false)
        }


      }).catch(error => {
        if(error.response.status === 401){
          window.location.reload()
          localStorage.removeItem('dwf-token')
        }
        console.log(error)

        apiCounter--
        if(apiCounter === 0){
          setLoader(false)
        }
      })
    }
  }


  if (localStorage.getItem('dwf-token') === null) {


    return <div>
      <BrowserRouter >






        <Routes>
          <Route path='/' element={<Login />} />

        </Routes>




      </BrowserRouter>
    </div>
  }
  else {


    if(loader){
      return <Loader/>
    }

    
    
    if (permissions.length === 0) {
      return <div style={{height:'100vh'}} className='d-flex justify-content-center align-items-center'>
        <img className='w-25' src={noPermissions} alt="" style={{minWidth:'100px'}} />
      </div>
    }
    else {
      const routes = [
        permissions.includes('Can View Admin Dashboard') && { path: '/dashboard', element: <Dashboard /> },
        permissions.includes('Can View Sale') && { path: '/sales/sales', element: <Sales /> },
        permissions.includes('Can View Cancel Sale') && { path: '/sales/canceled-sales', element: <CancelSales /> },
        permissions.includes('Can View Damage and Lost') && { path: '/sales/demage-lost', element: <DemageAndLost /> },
        permissions.includes('Can View Fix Accessories') && { path: '/inventory/accessories', element: <Accessories /> },
        permissions.includes('Can View Daily Inventory') && { path: '/inventory/inventory', element: <Inventory /> },
        permissions.includes('Can View Inventory Request') && { path: '/inventory/inventory-request', element: <InventoryRequest /> },
        permissions.includes('Can View Requestable Inventory') && { path: '/inventory/inventory-items', element: <InventoryItems /> },
        permissions.includes('Can View Products') && { path: '/products/products', element: <Products /> },
        permissions.includes('Can View Deals') && { path: '/products/deals', element: <Deals /> },
        permissions.includes('Can View Deals') && { path: '/products/set-percentage', element: <SetPercentage /> },
        permissions.includes('Can View Franchise') && { path: '/franchise/franchise-list', element: <FranchiseList /> },
        permissions.includes('Can View Live Deals Sale') && { path: '/live/deal-sale', element: <DealSale /> },
        permissions.includes('Can View Live Products Sale') && { path: '/live/product-sale', element: <ProductSale /> },
        permissions.includes('Can View Live Stats') && { path: '/live/stats', element: <LiveStats /> },
        permissions.includes('Franchise Can View Sale Overview') && { path: '/reports/sale-overview', element: <SaleOverview /> },
        (permissions.includes('Can View Kiosk') || permissions.includes('Can Add Kiosk')) && { path: '/user-management/kiosk', element: <Kiosk setKiosk ={setKiosk} /> },
        permissions.includes('Can View Employee') && { path: '/user-management/employees', element: <Employees /> },
        permissions.includes('Can View Attendance') && { path: '/user-management/attendance', element: <Attendance /> },
        permissions.includes('Can View User Permissions and Roles') && { path: '/settings/permissions', element: <Permissions /> },
        permissions.includes('Can View Groups') && { path: '/settings/group-permissions', element: <GroupPermissions /> },
        permissions.includes('Can View Log History') && { path: '/settings/log-history', element: <LogHistory /> },
        permissions.includes('Change Password For Users') && { path: '/settings/settings', element: <Settings /> },
        (permissions.includes('Can View Admin Notifications')  || permissions.includes('Can View Bakery Notifications')) && { path: '/notifications/Notification-Center', element: <Notifications setloadUnread={setloadUnread} loadUnread={loadUnread}/> },
        permissions.includes('Can View Bakery Production') && { path: '/bakery/production', element: <BakeryProduction />},
        permissions.includes('Can View Bakery Production History') && { path: '/produced-history', element: <ProductionHistory />},
        permissions.includes('Can Make Movement Kiosk to Kiosk') && { path: '/movement/add-movement', element: <Movement/>},
        permissions.includes('Can View Movement History') && { path: '/movement/movement-history', element: <MovementHistory/>},
        permissions.includes('Can Add Sales') && { path: '/add/sale', element: <AddSale/>},
       
        // true && { path: '/bakery/production', element: <BakeryProduction /> },
      ].filter(Boolean);

      console.log(window.location)
      if(window.location.pathname === '/'){
        window.location.href = routes[0].path
      }

      
      
      

      const createdRoutes = []
      routes.forEach(element => {
        createdRoutes.push(element.path)
      });
      return (
        
        permissions && <div>
          <kioskContext.Provider value={kiosk}>
            <selectedKioskContext.Provider value={[selectedKiosk, setSelectedKiosk]}>
              <TopBarTitleContext.Provider value={[topBarTitle, setTopBarTitle]} >
                <permissionsContext.Provider value={permissions}>
                  <createdRouteContext.Provider value = {createdRoutes}>
                  <BrowserRouter>
                    <div>
                      <Topbar loadUnread={loadUnread}/>
                      <div id='main' className=''>
                        <Sidebar changeSidebarWidth={changeSidebarWidth} />
                        <div  className="content-div p-4" onClick={hideSubSidebar}>

                          <Routes>

                            {routes.map((route, index) => (
                              <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                              />
                            ))}
                            

                            

                           

                          </Routes>


                        </div>

                      </div>
                    </div>

                  </BrowserRouter>
                  </createdRouteContext.Provider>
                </permissionsContext.Provider>
              </TopBarTitleContext.Provider>
            </selectedKioskContext.Provider>
          </kioskContext.Provider>
        </div>

      );

    }


  }
}

export default App;
