import React from 'react'
import SwitchButton from '../SwitchButton/SwitchButton'
export default function SaleChnnelButton(props) {
  return (
   
      <div className=' align-items-center row'>
        <div className="col">
          <div className="row">

          
          <div className="col-6 col-sm-3 p-0" >

          <SwitchButton name="sale-channel" id='overall' text="Overall Sale" defaultChecked={props.selectedChannel === "overall" ? true : false} cwidth="150px" setSelectedChannel={props.setSelectedChannel} />
        </div>
        <div className="col-6 col-sm-3 p-0">

          <SwitchButton name="sale-channel" id="kiosk" text="Kiosk Sale" defaultChecked={props.selectedChannel === "kiosk" ? true : false} cwidth="150px" setSelectedChannel={props.setSelectedChannel} />
        </div>
        <div className="col-6 col-sm-3 p-0">

          <SwitchButton name="sale-channel" id="foodpanda" text="Food Panda Sale" defaultChecked={props.selectedChannel === "foodpanda" ? true : false} cwidth="150px" setSelectedChannel={props.setSelectedChannel} />
        </div>
        <div className="col-6 col-sm-3 p-0">

          <SwitchButton name="sale-channel" id="online" text="Online Sale" defaultChecked={props.selectedChannel === "online" ? true : false} cwidth="150px" setSelectedChannel={props.setSelectedChannel} />
          </div>
        </div>
        </div>
      </div>


  )
}
