import React from 'react'
import './SwitchButton.css'
export default function SwitchButton(props) {
  return (
    <div className='switch-button'>
      <input type="radio" name={props.name} id={props.id} defaultChecked={props.defaultChecked} onClick={()=>{props.setSelectedChannel(props.id)}}/>
       <label htmlFor={props.id} className='font-2 semi-bold d-inline-block text-center' style={{width:props.cwidth}}>
        {props.text}</label> 
         </div>
  )
}
