import React, { useEffect, useState, useContext } from 'react'
import CustomSelect from '../../Components/CustomSelect/CustomSelect'
import GradientButton from '../../Components/GradientButton/GradientButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'

import BlackButton from '../../Components/BlackButton/BlackButton'
import PlusButton from '../../Components/PlusButton/PlusButton'
import MinusButton from '../../Components/MinusButton/MinusButton'
import CrossButton from '../../Components/CrossButton/CrossButton'

import Swal from 'sweetalert2'

import Loader from '../../Components/Loader/Loader'
import { subDays } from 'date-fns'

import { Modal, Form } from 'react-bootstrap'

import { permissionsContext } from '../../App'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const getDailyInventoryUrl = axiosUrls.DailyInventoryGet
const getRequestableInventoryItemUrl = axiosUrls.Requestable_get
const addRequestableInventoryItemUrl = axiosUrls.Requestable_add
const assignInventoryUrl = axiosUrls.DailyInventoryAdd


export default function Inventory() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [dates, setDates] = useState([subDays(new Date(), 30), new Date()])
    const [inventoryData, setInventoryData] = useState([])
    const [isAddInventory, setIsAddInventory] = useState(false)
    const [assignInventory, setAssignInventory] = useState({})
    const [inventoryItems, setInventoryItems] = useState([])
    const [isAddNew, setIsAddNew] = useState(false)
    const [addNewInventoryData, setAddNewInventoryData] = useState({ itemName: '', itemPrice: '' })

    const [selectedKiosk, setSelectedKiosk] = useState('')
    const [selectedKioskAssign, setSelectedKioskAssign] = useState(sessionStorage.getItem('kiosk'))

    const [errors, setErrors] = useState([])

    const [inventoryRequestId ,setInventoryRequestId] = useState()

    const permissions = useContext(permissionsContext)



// useEffect(()=>{
//     console.log(selectedKioskAssign,'uuuuuuuuuuuuuuuuuuuuuuuu')
// },[selectedKioskAssign])


    useEffect(() => {
        getDailyInventory()
        getRequestableInventoryItem()
    }, [dates, selectedKiosk])

    useEffect(() => {
        let requestInventoryItems = sessionStorage.getItem('items')
        // return 'test'
        if (requestInventoryItems) {
            setInventoryRequestId(sessionStorage.getItem('inv_id'))
            setSelectedKioskAssign(sessionStorage.getItem('kiosk'))
            requestInventoryItems = JSON.parse(requestInventoryItems)
            let data = {}
            for (let i = 0; i < requestInventoryItems.length; i++) {
                if(requestInventoryItems[i].Quantity >0){

                    data[requestInventoryItems[i].item_Name] = requestInventoryItems[i].Quantity
                }


            }
            setAssignInventory(data)
            setIsAddInventory(true)
            sessionStorage.removeItem('items')
            sessionStorage.removeItem('inv_id')
            sessionStorage.removeItem('kiosk')
        }

    }, [])





    const columns = [
        {
            label: 'Item Name',
            field: 'itemName',

        },
        {
            label: 'Kiosk Name',
            field: 'kioskName',

        },
        {
            label: 'Inventory Assigned',
            field: 'inventoryAssigned',

        },
        {
            label: 'Inventory Used',
            field: 'used',

        },
        {
            label: 'Time',
            field: 'time',

        },


    ]




    const plusInventory = (name) => {

        if (assignInventory[name] === undefined) {
            setAssignInventory(prevState => ({
                ...prevState,
                [name]: 1
            }))
        }
        else {
            setAssignInventory(prevState => ({
                ...prevState,
                [name]: assignInventory[name] + 1
            }))
        }

    }

    const minusInventory = (name) => {


        if (assignInventory[name] !== undefined) {
            if (assignInventory[name] <= 1) {

                const prevAssignInventory = { ...assignInventory }
                delete prevAssignInventory[name]

                setAssignInventory(prevAssignInventory)
            }
            else {

                setAssignInventory(prevState => ({
                    ...prevState,
                    [name]: assignInventory[name] - 1
                }))
            }
        }


    }


    const handleInventory = (event) =>{
        var {name,value} = event.target
        value  = parseInt(value)
        
        if (value < 1) {
            
            const prevAssignInventory = { ...assignInventory }
            delete prevAssignInventory[name]

            setAssignInventory(prevAssignInventory)
        }
        else{
           
            setAssignInventory(prevState => ({
                ...prevState,
                [name]: value
            }))
        }
    }

    const handleNewItemChange = (e) => {

        const { name, value } = e.target

        setAddNewInventoryData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const removeNewItem = () => {
        setAddNewInventoryData({ itemName: '', itemPrice: '' })
        setIsAddNew(false)
    }

    const cancelAssign = () => {
        setIsAddInventory(false)
        setSelectedKiosk()
        setInventoryRequestId()
        setAssignInventory({})

    }

    const addNewItem = () => {
        setLoader(true)
        apiCounter++
        if (addNewInventoryData.itemName && addNewInventoryData.itemPrice >= 0) {
            axios.post(addRequestableInventoryItemUrl, {
                itemName: addNewInventoryData.itemName,
                itemPrice: addNewInventoryData.itemPrice
            }, {
                headers: {
                    'Authorization': token
                }
            }).then(response => {
                console.log(response)

                getRequestableInventoryItem()
                setAddNewInventoryData({ itemName: '', itemPrice: '' })
                setIsAddNew(false)
                setErrors([])

                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }

            }).catch(error => {
                console.log(error)
                if (error.response.status === 422) {
                    setErrors(error.response.data.message)
                }
                else {
                    setErrors([])
                }
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }
            })
        }
    }




    const getDailyInventory = () => {
        setLoader(true)
        apiCounter++
        axios.post(getDailyInventoryUrl, {
            dates: dates,
            kiosk:selectedKiosk
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setInventoryData({ columns: columns, rows: response.data })

            apiCounter--
            if (apiCounter == 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter == 0) {
                setLoader(false)
            }
        })
    }

    const getRequestableInventoryItem = () => {
        setLoader(true)
        apiCounter++
        axios.get(getRequestableInventoryItemUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            apiCounter--
            const data = response.data
            for (let i = 0; i < data.length; i++) {
                data[i]['quantity'] = 0

            }
            setInventoryItems(response.data)
            if (apiCounter == 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter == 0) {
                setLoader(false)
            }
        })
    }

    const assignInventoryApi = () => {
        const data = []
        Object.keys(assignInventory).map((key, index) => {
            data.push({ item: key, quantity: assignInventory[key] })
        })

        
        setLoader(true)
        apiCounter++
        axios.post(assignInventoryUrl, {
            kiosk: selectedKioskAssign,
            items: data,
            invId : inventoryRequestId
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setAssignInventory({})
            setIsAddInventory(false)
            setErrors([])
            getDailyInventory()
            setInventoryRequestId()
            setSelectedKioskAssign()
            Swal.fire(
                'Success',
                'Inventory assigned successfully',
                'success'
                )
            apiCounter--
            if (apiCounter == 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)

            if (error.response.status === 422) {
                setErrors(error.response.data.message)
            }
            else {
                setErrors([])
            }
            apiCounter--
            if (apiCounter == 0) {
                setLoader(false)
            }
        })

    }


    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className="h-100">
                <div className="row reverse-col">
                    <div className="col-sm-3 py-1">
                        <CustomSelect value={selectedKiosk} handleOnChange={setSelectedKiosk} text="All Kiosks"/>
                    </div>
                    <div className="col-sm-3 py-1">
                        <CustomDatePicker dates={dates}  setDates={setDates}/>
                    </div>
                    <div className="col-sm-6 py-1">
                        <div className="d-flex justify-content-end">
                            {
                                permissions.includes('Can Add Daily Inventory') && <GradientButton text="Assign Inventory" onClick={() => { setIsAddInventory(true) }} />
                            }

                            <DownloadButton className="ml-3" data={inventoryData} filename="dailyInventory.csv" />
                        </div>
                    </div>
                </div>

                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={inventoryData} />
                </div>

                <div className="mobile row py-2">
                    <div className="col-12">

                        <p className='font-2 semi-bold mb-1'>Inventory</p>
                    </div>

                    {
                        inventoryData.rows && inventoryData.rows.map((data, index) => {
                            return <div className="col-12 " >
                                <div className="mobile-item-box-div my-1">
                                    <div className="d-flex justify-content-between py-1">
                                        <span className='font-2'>{data.itemName}</span>
                                        <span className='font-2'>Inventory Assigned: {data.inventoryAssigned}</span>

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className='font-2'>{data.kioskName}</span>
                                        <span className='font-2'>Time: {data.time}</span>
                                    </div>
                                </div>

                            </div>
                        })
                    }


                </div>








                <Modal
                    show={isAddInventory}
                    onHide={() => { setIsAddInventory(false) }}
                    size='xl'
                    centered
                >
                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }} >
                        <div className="p-3">
                            <p className='text-center font-2 semi-bold'>Assign Inventory</p>
                            <div className="row">
                                <div className="col-8">
                                    {!inventoryRequestId && <CustomSelect value={selectedKioskAssign} handleOnChange={setSelectedKioskAssign} text="Select Kiosk"/>}
                                    
                                </div>
                                <div className="col-4" style={{ textAlign: 'end' }}>
                                    <BlackButton text="Add Inventory Items" className="font-2" style={{ padding: '6px' }} onClick={() => { setIsAddNew(true) }} />
                                </div>
                            </div>

                            <div className="row my-3">

                                {
                                    inventoryItems.map((data, index) => {
                                        return <div className="col-sm-6 my-1">
                                            <div className="item-box px-2 py-2">
                                                <div className="row">
                                                    <div className="col-1 d-flex align-items-center pr-0 font-2 ">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col-7 font-2 d-flex">
                                                        {data.itemName}  (Rs. {data.itemPrice})
                                                        
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-between align-items-center font-2">
                                                        <MinusButton onClick={() => { minusInventory(data.itemName) }} />
                                                        {/* {assignInventory[data.itemName] !== undefined ? assignInventory[data.itemName] : data.quantity} */}
                                                        <input type='number' name={data.itemName} value={assignInventory[data.itemName] !== undefined ? assignInventory[data.itemName] : ''} style={{width:"40%"}} onChange={handleInventory}/>
                                                        <PlusButton onClick={() => { plusInventory(data.itemName) }} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    })
                                }

                                {
                                    isAddNew && <div className="col-6 my-2">
                                        <div className="item-box p-2">
                                            <div className="row">
                                                <div className="col-5 pr-0">


                                                    <Form.Control type="text" placeholder="Inventory Name" className='w-100 font-2' name="itemName" value={addNewInventoryData.itemName} onChange={handleNewItemChange} />


                                                </div>
                                                <div className="col-4 pr-0">
                                                    <Form.Control type="number" placeholder="Inventory Price" className='w-100 font-2' name="itemPrice" value={addNewInventoryData.itemPrice} onChange={handleNewItemChange} />
                                                </div>
                                                <div className="col-2 d-flex align-items-center">
                                                    <BlackButton text="add" className="font-2" style={{ padding: '4px' }} onClick={addNewItem} />
                                                </div>
                                                <div className="col-1 ">
                                                    <CrossButton onClick={removeNewItem} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }





                            </div>
                            {
                                errors && <div className='font-2 mb-2' style={{ color: 'red' }}>{errors}</div>
                            }

                            <div className="d-flex align-items-center justify-content-center">
                                <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: 'white'
                                }} className='font-2 mx-1' onClick={cancelAssign}>Cancel</button> <button style={{
                                    boxShadow: ' 0px 0px 2px #00000026',
                                    border: '0.5px solid #232529',
                                    borderRadius: '2px', padding: '7px 10px', backgroundColor: '#232529', color: 'white'
                                }} className='font-2 mx-1' onClick={assignInventoryApi}>Assign</button>
                            </div>


                        </div>
                    </Modal.Body>

                </Modal>



            </div>
        )
    }
}
