import React, { useEffect, useState,useContext } from 'react'
import './BakeryProduction.css'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import Loader from '../../Components/Loader/Loader';
import BootstrapTable, { Type } from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import Swal from 'sweetalert2';
import { TopBarTitleContext } from '../../App'




import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getProductionColumnsUrl = axiosUrls.getProductionColumns
const assignProductionUrl = axiosUrls.assignProduction
const getProductionUrl = axiosUrls.getProduction;
const submitProductionUrl = axiosUrls.submitProduction;
const clearProductionUrl = axiosUrls.clearProduction



export default function BakeryProduction() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [render, setRender] = useState(false)
  const [productionButtonText, setProductionButtonText] = useState('Assign')
  const [isNewProduction,setIsNewProduction] = useState(false)

  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [productPercentage, setProductPercentage] = useState([])
  const [isPendingProduction, setIsPendingProduction] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const [totalProduction, setTotalProduction] = useState(0)
  const [assignedProduction, setAssignedProduction] = useState(0);

  const [productionColumns,setProductionColumns] = useState([])
  const [productionData,setProductionData] = useState([])

  const [selectProductionNo,setSelectProductionNo] = useState(0)
  const [selectedProduction,setSelectProduction] = useState([])

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Production')
  


  useEffect(() => {
    if(isNewProduction){

      getProductionColumnsApi()
      
     
      // setAssignedProduction(productionData[selectProductionNo].assignedProduction)
    }
    else{
      getProductionApi()
    }
  }, [isNewProduction])

  useEffect(() => {

  }, [render])

  useEffect(()=>{
    if(productionData.length ){

      setTotalProduction(productionData[selectProductionNo].totalProduction)
      setAssignedProduction(productionData[selectProductionNo].assignedProduction)
      
    }
  },[selectProductionNo,productionData])

  useEffect(() => {

    if (isPendingProduction && !isUpdated) {
      setProductionButtonText("Submit")
    }
    else if (isPendingProduction && isUpdated) {
      setProductionButtonText('Update')
    }
    else if (!isPendingProduction) {
      setProductionButtonText('Assign')
    }
  }, [isPendingProduction, isUpdated])


  useEffect(()=>{
    let sum =0
    data.forEach(element => {
        sum =sum+parseInt(element.quantity)
    });
    setAssignedProduction(sum)
    
  },[data])

  useEffect(()=>{
 
    
    if(productionData.length>0){
      handleProductionNo()
    }

  },[selectProductionNo])

  const handleProductionNo = ()=>{
    
    
    setSelectProduction(productionData[selectProductionNo].kiosk_data)
      setTotalProduction(productionData[selectProductionNo].totalProduction)
      setAssignedProduction(productionData[selectProductionNo].assignedProduction)
  }



  const handleProductionChange = (e) => {
    setTotalProduction(e.target.value)
  }

  const handleBeforeSave = (oldValue, newValue, row, column) => {

    

    if (column.dataField === "quantity") {
      const updatedRow = { ...row }; // Create a copy of the row
      const newQuantity = parseFloat(newValue); // Ensure newValue is a number


      if (!isNaN(newQuantity)) {
        if(oldValue != newValue){
          Object.keys(productPercentage).forEach((key) => {
            updatedRow[key] = (newQuantity / 100) * productPercentage[key];
          });
          updatedRow['quantity'] = parseInt(newValue)
  
  
  
          setData((prevData) => {
            const updatedData = prevData.map((item) => {
              if (item.kiosk === updatedRow.kiosk) {
                return updatedRow;
              }
            
              return item;
            });
            return updatedData;
          });
        }
        
      }
    }
    else{
      // const updatedRow = { ...row };
      // Object.keys(updatedRow).forEach((key) => {
      //   if(key != 'kiosk'){

      //     updatedRow[key] = parseInt(updatedRow[key]);
      //   }
      // });
      // setData((prevData) => {
      //   const updatedData = prevData.map((item) => {
      //     if (item.kiosk === updatedRow.kiosk) {
      //       return updatedRow;
      //     }
        
      //     return item;
      //   });
      //   return updatedData;
      // });
    }
  
    
    setRender(!render)
    return true; // Allow saving

  }

  const handleAfterSave = (oldValue, newValue, row, column) => {
   
    setIsUpdated(true)

  }



  const rowStyle2 = (row, rowIndex, column) => {


    const style = {};


    var sum = 0

    for (let i = 4; i < columns.length; i++) {
      sum = sum + parseInt(row[columns[i].dataField])

      if (isNaN(row[columns[i].dataField]) || row[columns[i].dataField].toString().indexOf('.') != -1) {
        style.backgroundColor = '#c2172e';
        style.color = '#FFF';

      }
      row[columns[i].dataField] = parseInt(row[columns[i].dataField])
    }

    if(Math.sign(totalProduction) === -1){
      style.backgroundColor = '#c2172e';
    }

    if(Math.sign(row['quantity']) === -1){
      style.backgroundColor = '#c2172e';
    }


    
    
    
    row['sum'] = sum
    
    if (row['quantity'] != sum.toString()) {
      style.backgroundColor = '#c2172e';
    }

    if(totalProduction < assignedProduction)
    {
      style.backgroundColor = '#c2172e';
    }
    
    
    style.textAlign = "center";
    style.verticalAlign = "middle"
    
    return style;
  };





  const getProductionApi = () =>{
    setLoader(true)
    apiCounter++
    axios.get(getProductionUrl,{
      headers : {
        'Authorization' : token,
      }
    }).then(response =>{
      console.log(response)
      if(response.data === 'no production'){
        
        setIsNewProduction(true)
      }
      else{
        setProductionColumns(response.data.columns)
        setProductionData(response.data.productionData)
        if(response.data.isPendingProduction){
          setIsNewProduction(true)
        }
      }
      
      apiCounter--
      if(apiCounter===0){
        setLoader(false)
      }
    }).catch(error =>{
      console.log(error)
      apiCounter--
      if(apiCounter===0){
        setLoader(false)
      }
    })
  }

  const getProductionColumnsApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getProductionColumnsUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      const col = response.data.columns
     


      var newCol = [];
      for (var j = 0; j < col.length; j++) {
        var newdata = {
          'text': col[j].text,
          dataField: col[j].dataField,
          editable: col[j].editable,
          headerAlign: 'center',
          editorClasses: 'editing-name',
          width :'100px',
          
          validator: (newValue, row, column) => {
            
            if (isNaN(newValue))
              return {
                valid: false,
                message: "Please Enter Numeric Value"
              }
            if (newValue === "" || newValue === null)
              return {
                valid: false,
                message: "Field can't be left blank"
              }
            if (newValue.toString().includes('.'))
              return {
                valid: false,
                message: "Please enter non decimal value"
              }

              if(newValue.toString().includes(' ')){
                return {
                  valid: false,
                  message: "Please remove space"
                }
              }
          }
        }
        newCol.push(newdata)
      }
      setData(response.data.data)
      setColumns(newCol)
      setProductPercentage(response.data.productPercentage)
      setIsPendingProduction(response.data.isPendingProduction)
     

      setTotalProduction(response.data.totalProduction)
      
      setAssignedProduction(response.data.assignedProduction)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)

      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const handleProductionButton = (e) => {
    if (productionButtonText === "Assign" || productionButtonText === "Update") {
      assignProductionApi()
    }
    else if(productionButtonText === "Submit"){
      submitProductionApi()
    }
  }

  const assignProductionApi = () => {
    setLoader(true)
    apiCounter++
    axios.post(assignProductionUrl, {
      data: data,
      totalProduction : totalProduction 
    }, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      if(response.data === 'success'){
        getProductionColumnsApi();
        setIsUpdated(false)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      // let validationError = error.response.data.errors
      // text = ''
      // Object.keys(validationError).map((key,index)=>{
      //   text = text + '\n'+validationError
      // })
      Swal.fire(
        'Error',
        error.response.data.message,
        'error'
      )
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const submitProductionApi = () => {
    setLoader(true)
    apiCounter++

    axios.get(submitProductionUrl,{
      headers : {
        'Authorization' : token
      }
    }).then(response =>{
      console.log(response)
      if(response.data === 'success'){
        setIsNewProduction(false)
        getProductionApi();
        setData([])
      }
      apiCounter--
      if(apiCounter === 0){
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if(apiCounter === 0){
        setLoader(false)
      }
    })
  }

  const cleaTableApi = () =>{
    setLoader(true)
    apiCounter++
    axios.get(clearProductionUrl,{
      headers : {
        'Authorization' : token
      }
    }).then(response =>{
      console.log(response)
      if(response.data === 'success'){
        getProductionColumnsApi()
      }
      apiCounter--
      if(apiCounter === 0){
        setLoader(false)
      }
    }).catch(error =>{
      console.log(error)
      apiCounter--
      if(apiCounter === 0){
        setLoader(false)
      }
    })
  }


  if (loader) {
    return <Loader />
  }
  else {


    return (
      <div className='h-100 production-page'>
        <div className="row h-100">
          <div className="col h-100 ">
            <div className="row my-2">
              <div className="col-6">
                <span className='font-2 semi-bold'>Total Production</span>
                
                  <input type="text" className='form-control w-25 ml-2' style={{ display: "inline-block" }} value={totalProduction} onChange={handleProductionChange} readOnly={isNewProduction ?false:true} />

                  

              </div>
              <div className="col-6" style={{ textAlign: 'end' }}>
                {isNewProduction && <BlackButton text="Clear Table" className="h-100"  onClick={cleaTableApi}/>}
                {!isNewProduction && <BlackButton text="New Production" className="h-100" onClick={()=>{setIsNewProduction(true);}} />}
                {!isNewProduction && <DownloadButton className="ml-3 h-100"  data= {productionData.length?productionData[selectProductionNo].kiosk_data:''} columns= {productionData.length?productionData[selectProductionNo].columns:''} filename="ProductionReport.csv"/>}
              </div>
            </div>
            <div className="d-flex my-4">
              <span className='font-2'>Assigned Production = {assignedProduction}</span>
              <span className='font-2 ml-5'>Remaining Production = {totalProduction - assignedProduction}</span>
            </div>

            {!isNewProduction && <div className="row">
              <div className="col-12 d-flex flex-wrap">
                <div style={{width:'18%',padding:'5px 5px'}}>
                  <input type="radio" name="productionNo" id="overallProduction" className='d-none' defaultChecked={true}/>
                  <label htmlFor='overallProduction' className='producton-no-lable w-100 text-center' onClick={() => { setSelectProductionNo(0)}}>
                    Overall Production
                  </label>
                </div>

                
              {
                
                productionData.map((data,index)=>{
                  return index !==0 && <div key={index} className="" style={{width:'18%',padding:'5px 5px'}}>
                  <input type="radio" name="productionNo" id={"production"+index} className='d-none' />
                  <label htmlFor={"production"+index} className='producton-no-lable w-100 text-center' onClick={() => {setSelectProductionNo(index)}}>
                    Production {index}
                  </label>
                </div>
                })
              }
              </div>

            </div>}
            
            {!isNewProduction && productionData.length !== 0 && <div className="table">



              {
                productionData.length && <BootstrapTable
                  id="toCsv"
                  keyField="id"
                  data={productionData[selectProductionNo].kiosk_data}
                  columns={productionData[selectProductionNo].columns}
                  striped
                  hover
                  condensed
                />


              }
              
              




            </div>}




            {isNewProduction && <div className="table">



              {
                data.length !== 0 && <BootstrapTable
                  id="toCsv"
                  keyField="kiosk"
                  data={data}
                  columns={columns}
                  striped
                  hover
                  condensed
                  rowStyle={rowStyle2}
                  cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                    beforeSaveCell: handleBeforeSave,
                    afterSaveCell: handleAfterSave,

                    // nonEditableRows: () => [1, 2, 3],
                  })}

                />


              }




            </div>}

            {
              isNewProduction && data.length !== 0 && <div className="d-flex justify-content-end">
                {/* <button onClick={handleProductionButton}>{productionButtonText}</button> */}
                <BlackButton text={productionButtonText} onClick={handleProductionButton} style={{padding:'7px'}}/>
              </div>
            }


          </div>
        </div>
      </div>
    )
  }
}
