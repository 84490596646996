import React, { useState, useContext, useEffect } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import { TopBarTitleContext } from '../../App'
import { permissionsContext } from '../../App'





import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getNotificationsUrl = axiosUrls.Notifications
const readNotificationsUrl = axiosUrls.ReadNotifications





export default function Notifications(props) {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0


  const [notificationData, setNotificationData] = useState([])
  const [notificationId,setNotificationId] = useState([])


  const [topBarTitle, setTopBarTitle] = useContext(TopBarTitleContext)
  const permissions = useContext(permissionsContext)



  setTopBarTitle('Notifications')

  useEffect(() => {
    getNotificationsApi()
  }, [])


  const columns = [
    {
      label: '',
      field: 'action',
    },
    {
      label: 'Kiosk',
      field: 'kiosk',

    },
    {
      label: 'Category',
      field: 'category',

    },
    {
      label: 'Type',
      field: 'type',

    },
    {
      label: 'Description',
      field: 'description',

    },

    {
      label: 'date',
      field: 'date',

    },
    // {
    //   label: 'Read',
    //   field: 'isRead'
    // },
    (permissions.includes('Can View Admin Notificaions') || permissions.includes('Can View Bakery Notifications')) && {
      label: 'User',
      field: 'forRole'
    }
  ].filter(Boolean)


  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setNotificationId((prevState) => [...prevState, e.target.value]);
    }
    else{
      const data = [...notificationId]
      delete data[e.target.value]
      setNotificationId(data)
    }
  };


  const getNotificationsApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getNotificationsUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      const data = response.data

      for(var i=0 ;i<data.length;i++){
        console.log(data[i])
        data[i]['action'] = <input type="checkbox" value={data[i].id} onChange={handleCheckboxChange} disabled={data[i].isRead =='yes'? true:false}/>
      }
      
      setNotificationData({ columns: columns, rows: response.data })

      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const readNotificationsApi = () => {
    setLoader(true)
    apiCounter++
    axios.post(readNotificationsUrl,
      {
        notificationIds : notificationId
      }, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      // alert('All notifcations are marked as read')
      getNotificationsApi()
      props.setloadUnread(!props.loadUnread)
      apiCounter--
      setNotificationId([])
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  if (loader) {
    return <Loader />
  }
  else {

    return (
      <div className="h-100">
        <div className="row">
          <div className="col-3 desktop">
            <span className='font-2 semi-bold'>All Notifications</span>
          </div>
          

          <div className="col-sm-9">
            <div className="d-flex justify-content-end">

              <GradientButton text={notificationId.length?"Mark as read": "Mark all as read"} onClick={readNotificationsApi} />
              



              <DownloadButton className="ml-3" data={notificationData} filename="NotificationsReport.csv" />
            </div>
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={notificationData} />
        </div>

        <div className="mobile row">
          <div className="col-12">

            <p className='font-2 semi-bold mb-1'>All Notifications</p>
          </div>
          {
            notificationData.rows && notificationData.rows.map((data, index) => {
              return <div className="col-12 "  >
                <div className='mobile-item-box-div my-1'>
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.kiosk}</span>
                    <span className='font-2'>Category: {data.category}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>Type: {data.type}</span>
                    <span className='font-2'>Date: {data.date}</span>
                  </div>
                </div>

              </div>
            })
          }
        </div>
      </div>
    )
  }
}
