import React,{useState,useContext} from 'react'
import Loader from '../../Components/Loader/Loader'
import { TopBarTitleContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useEffect } from 'react'
import BootstrapTable, { Type } from "react-bootstrap-table-next";

var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getProductionHistoryUrl = axiosUrls.productionHistory


export default function ProductionHistory() {

    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const[date,setDate] = useState(new Date())
    const[historyData,setHistoryData] = useState([])
    const[column,setColumn] = useState([])

    const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Production History')


    useEffect(()=>{
        if(date){
            getProductionHistoryApi()
        }
    },[date])


    const dateChange = (e) => {
        const selectedDate = e.target.value;
        setDate(new Date(selectedDate));
      };


    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };


      const getProductionHistoryApi = () =>{
        setLoader(true)
        apiCounter++
        axios.get(getProductionHistoryUrl+'/'+formatDate(date),{
            headers:{
                'Authorization' : token
            }
        }).then(response => {
            console.log(response)
            setHistoryData(response.data.data)
            setColumn(response.data.columns)
            apiCounter--
            if(apiCounter === 0){
                setLoader(false)
            }
        }).catch(error=>{
            console.log(error)

            apiCounter--
            if(apiCounter === 0){
                setLoader(false)
            }

        })
      }



    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className='h-100'>
                <div className="row">
                    <div className="col-6 desktop">
                        <span className='font-2 semi-bold'>History</span>
                    </div>

                    <div className="col-sm-6" style={{ textAlign: 'end' }}>
                        {/* <BlackButton text="Add Category" className="mr-3 font-6" style={{padding:'7px'}}/> */}
                        <div className='d-flex justify-content-end'>
                            {/* <DownloadButton className="ml-3" /> */}
                            <input type="date" value={formatDate(date)} onChange={dateChange} style={{padding:'3px 10px'}}/>
                        </div>
                    </div>



                </div>
                {historyData.length !==0 && <div className="table my-3">



              
                 <BootstrapTable
                  id="toCsv"
                  keyField="id"
                  data={historyData}
                  columns={column}
                  striped
                  hover
                  condensed
                />

            </div>}

            {
                !historyData.length && <div className='d-flex h-100 w-100 align-items-center justify-content-center font-1 semi-bold' style={{minHeight:'100vh'}}> No production activity reported today</div>
            }
            </div>
        )
    }
}
