import React,{ useEffect, useState} from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'

import Loader from '../../Components/Loader/Loader'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'



var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const liveSaleStatsUrl = axiosUrls.liveSales

export default function LiveStats() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [liveStatsData,setLiveStatsData] = useState([])


  useEffect(()=>{
      liveSaleStatsApi()
  },[])


  const liveSaleStatsApi = () =>{
    setLoader(true)
    apiCounter++
    axios.get(liveSaleStatsUrl,{
      headers:{
        'Authorization' : token
      }
    }).then(response =>{
        console.log(response)
        setLiveStatsData({columns:response.data.columns,rows:response.data.data})
        apiCounter--
        if(apiCounter===0){
          setLoader(false)
        }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if(apiCounter===0){
        setLoader(false)
      }
    })
  }
  if(loader){
    return <Loader/>
  }
  else{
  return (
    <div className="h-100">
        <div className="row">
            <div className="col-3">
                <span className='font-2 semi-bold'>Live Stats</span>
            </div>

            <div className="col-9" style={{textAlign:'end'}}>
                {/* <BlackButton text="Add Category" className="mr-3 font-6" style={{padding:'7px'}}/> */}
                {/* <GradientButton text="Add New Product"/> */}
                <DownloadButton className="ml-3" data={liveStatsData} filename="LiveStatsReport.csv"/>
            </div>

            
          
        </div>
        <div style={{height:'95%'}}>
                <TableWithPagination data={liveStatsData}/>
            </div>
    </div>
  )
  }
}
