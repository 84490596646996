import React, { useContext, useEffect, useState } from 'react'
import './Topbar.css'
import { TopBarTitleContext } from '../../App'
import logoPng from '../../Assets/LogoPng.png'
import bellIcon from '../../Assets/bellIcon.svg'
import verticalLine from '../../Assets/verti-line.png'
import menuIcon from '../../Assets/menu-icon.svg'
import { NavLink } from 'react-router-dom'

import { permissionsContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getUnreadNotificationUrl = axiosUrls.Unread_Notification
export default function Topbar(props) {
    const [unreadNotification,setUnreadNotification] = useState(0)

    const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)
    const permissions = useContext(permissionsContext)


    useEffect(()=>{
      if(permissions.includes('Can View Bakery Notifications') || permissions.includes('Can View Admin Notifications')){
        getUnreadNotifictionCountApi()
      }
    },[props.loadUnread])

    

    const getUnreadNotifictionCountApi =()=>{
      axios.get(getUnreadNotificationUrl,{
        headers:{
          'Authorization' : token
        }
      }).then(response =>{
        console.log(response,'notifcation')
        setUnreadNotification(response.data)
      }).catch(error=>{
        console.log(error)
      })
    }

    const hideAndShowSubSidebar = () =>{
        if(window.location !=='/'){

        
        if(window.innerWidth<576){
            var styles = document.documentElement.style;
            
            if(styles.getPropertyValue('--sub-sidebar-left')==="65px"){
              styles.setProperty('--sub-sidebar-left', '-235px');
            }
            else{
      
              styles.setProperty('--sub-sidebar-left', '65px');
            }
          }
        }
    }
    return (
        <div className='top-bar d-flex p-2 justify-content-between'>
            <div className='d-flex'>
                <span className='align-items-center topbar-logo'>

                <img src={logoPng} className='h-75 ' alt="" />
                <p className='font-16px semi-bold m-0 ' style={{paddingLeft:'10px'}}>Dough With The Flow</p>
                <img src={verticalLine} alt="" className='h-100 pr-4 pl-4' />
                </span>
                <div className='d-flex align-items-center'>
                <img className='mx-2 mobile h-50' src={menuIcon} alt="" onClick={hideAndShowSubSidebar}/>
                <p className='font-16px semi-bold m-0'>{topBarTitle}</p>
                </div>
            </div>
            <div className='notification-div mr-3'>
            <NavLink to='/notifications/Notification-Center' >
                
                  <img className='h-75 bell-img' src={bellIcon} alt="" />
            </NavLink>
              
              
              {unreadNotification != 0 && <div className="number">
                {unreadNotification}
              </div>}

            </div>
        </div>
    )
}
