import React,{useEffect, useState,useContext} from 'react'
// import GradientButton from '../../Components/GradientButton/GradientButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import { Modal } from "react-bootstrap";
import { TopBarTitleContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'



var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const getMovementUrl =  axiosUrls.getMovement

export default function MovementHistory() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  


  const [movementHistoryData,setMovementHistoryData] = useState([])
  const [isDetail, setIsDetail] = useState(false)
  const [detailIndex, setDetailIndex] = useState()

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Movement')
    

    useEffect(()=>{
      getMovementHistoryApi()
    },[])

    const columns =[
      {
        label : 'Moved From',
        field : 'movedFrom',
      },
      {
        label : 'Moved To',
        field : 'movedTo',
      },
      {
        label : 'Time',
        field : 'time',
      },
      {
        label : 'Date',
        field : 'date',
      },
      {
        label : 'Items',
        field : 'details',
      },
      {
        label : 'Status',
        field : 'status',
      },
    ]


    const getMovementHistoryApi = () =>{
      setLoader(true)
      apiCounter++
      axios.get(getMovementUrl,{
        headers:{
          'Authorization' : token,
        }
      }).then(response =>{
        console.log(response)
        var data = response.data
        for (let i = 0; i < data.length; i++) {
          data[i]['details'] = <button className='detail-btn' value={i} onClick={(e) => { setIsDetail(true); setDetailIndex(e.target.value) }}>Details</button>
          data[i]['status'] = <div style={{color:data[i].status === 'yes'?'#5ED819':'#FC526E'}}>{data[i].status === 'yes'?'Confirmed':'Not Confirmed'}</div>
        } 

        setMovementHistoryData({columns:columns,rows:response.data})

        apiCounter--
        if(apiCounter === 0){
          setLoader(false)
        }
      }).catch(error =>{
        console.log(error)

        apiCounter--
        if(apiCounter === 0){
          setLoader(false)
        }
      })
    }

  if(loader){
    return <Loader />
  }
  else{

  
  return (
    <div className='h-100'>
      <div className="row">
          <div className="col-3 desktop">
            <span className='font-2 semi-bold'>Products List</span>
          </div>

          <div className="col-sm-9" style={{ textAlign: 'end' }}>
            {/* <BlackButton text="Add Category" className="mr-3 font-6" style={{padding:'7px'}}/> */}
            <div className='d-flex justify-content-end'>
              <DownloadButton className="ml-3" data={movementHistoryData} filename="MovementHistory.csv" />
            </div>
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={movementHistoryData}/>
        </div>

        <Modal
          show={isDetail}
          onHide={() => { setIsDetail(false);setDetailIndex(-1) }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <div className='p-3'>

              <p className='text-center font-2 semi-bold'>Items Details</p>
              {
                detailIndex>=0 && movementHistoryData.rows[detailIndex]['items'].map((data,index)=>{
                  return <div key={index} className='row item-box my-2 p-2 align-items-center'>
                    
                  <div className="col-1 pr-0">
                    <span className='font-2'>{index + 1}.</span>
                  </div>
                  <div className="col-9">
                    <p className='font-2 m-0'>{data.product_name}</p>

                  </div>
                  <div className="col-2 ">
                    <p className='font-2 m-0 '>{data.quantity}</p>
                  </div>
                </div>
                })
              }
              
              {/* {

                detailIndex>=0 && Object.keys(liveSaleData.rows[detailIndex].items).map((key, index) => {
                  return <div key={index} className='row item-box my-2 p-2 align-items-center'>
                    
                    <div className="col-1 pr-0">
                      <span className='font-2'>{index + 1}.</span>
                    </div>
                    <div className="col-9">
                      <p className='font-2 m-0'>{key}</p>

                    </div>
                    <div className="col-2 ">
                      <p className='font-2 m-0 '>{liveSaleData.rows[detailIndex].items[key]}</p>
                    </div>
                  </div>
                })
              } */}
            </div>









          </Modal.Body>

        </Modal>
    </div>
  )
  }
}
