import React, { useState } from 'react'
import './Franchise.css'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'
import Loader from '../../Components/Loader/Loader'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useEffect } from 'react'
import { subDays } from 'date-fns'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getFranchiseKioskUrl = axiosUrls.getFranchiseKiosk
const getFranchiseDataUrl = axiosUrls.getFranchiseData


export default function SaleOverview() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [dates, setDates] = useState([subDays(new Date(), 30), new Date()])

    const [kiosks, setKiosk] = useState([])
    const [selectedKiosk, setSelectedKiosk] = useState()
    const [saleOverviewData, setSaleOverviewData] = useState([])
    const [totalSales, setTotalSales] = useState(0)
    const [itemProduced, setItemProduced] = useState(0)
    const [itemSold, setItemSold] = useState(0)

    useEffect(() => {
        getFrachiseKisokApi();
    }, [])
    useEffect(() => {
        getFranchiseDataApi();
    }, [selectedKiosk])

    const columns = [
        {
            label : 'product',
            field : 'product',
        },
        {
            label : 'Item Produced',
            field : 'itemProduced',
        },
        {
            label : 'Item Sold',
            field : 'itemSold',
        },
        // {
        //     label : 'Sale Amount (Rs)',
        //     field : 'saleAmount',
        // },
    ]

    const getFrachiseKisokApi = () => {
        setLoader(true)
        apiCounter++
        axios.get(getFranchiseKioskUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setKiosk(response.data.kiosks)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    const getFranchiseDataApi = () => {
        setLoader(true)
        apiCounter++
        axios.post(getFranchiseDataUrl, {
            dates: dates,
            selectedKiosk: selectedKiosk
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            const data = response.data
            setSaleOverviewData({columns:columns, rows:data.saleData})
            setTotalSales(data.totalSales)
            setItemProduced(data.itemProduced)
            setItemSold(data.itemSold)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }

    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className='h-100'>
                <div className="row">
                    <div className="col-3 desktop">
                        <select name="kiosk" id="" className='font-2' value={selectedKiosk} onChange={(e)=>{setSelectedKiosk(e.target.value)}}>
                            <option value="">Select kiosk</option>
                            
                            {
                                kiosks.map((name, index) => {
                                    return <option value={name}>{name}</option>

                                })
                            }
                        </select>
                    </div>

                    <div className="col-sm-9" style={{ textAlign: 'end' }}>

                        <div className='d-flex justify-content-end'>
                            <CustomDatePicker dates={dates} setDates={setDates} />
                            <DownloadButton className="ml-3" data={saleOverviewData} filename="SaleOverviewReport" />
                        </div>
                    </div>



                </div>
                <div className="d-flex my-3">

                    <div className='tab-box text-center'>
                        <p className='font-2 m-0'>Total Sales</p>
                        <p className='font-2 m-0'>Rs. {totalSales}</p>
                    </div>



                    <div className='tab-box text-center'>
                        <p className='font-2 m-0'>Item Produced</p>
                        <p className='font-2 m-0'>{itemProduced}</p>
                    </div>
                    <div className='tab-box text-center'>
                        <p className='font-2 m-0'>Item Sold</p>
                        <p className='font-2 m-0'>{itemSold}</p>
                    </div>

                </div>
                <div className='desktop' style={{ height: '80%' }}>
                    <TableWithPagination data={saleOverviewData}/>
                </div>
            </div>
        )

    }
}
