import React, { useState,useEffect, useContext } from 'react'
import './Sales.css'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import SaleChnnelButton from '../../Components/SaleChannelButton/SaleChnnelButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'
import { Modal } from "react-bootstrap";
import Loader from '../../Components/Loader/Loader'
import {subDays} from 'date-fns'
import noDataIcon from '../../Assets/no-sale-icon.png'
import { TopBarTitleContext } from '../../App'




import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const saleDataUrl = axiosUrls.SaleData
const orderDetailUrl  = axiosUrls.OrderDetail


export default function Sales() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0
  const [isDetail, setIsDetail] = useState(false)

  const [dates ,setDates] = useState([subDays(new Date(),30) , new Date()])
  const [selectedChannel,setSelectedChannel] = useState('overall')
  const [saleData,setSaleData] = useState([])
  const [selectedKiosk,setSelectedKiosk] = useState('')

  const [orderDetail,setOrderDetail] = useState([]) 

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)
  

  setTopBarTitle('Sales Channel')



  // useEffect(()=>{
  //   console.log(saleData)
  // },[saleData])


  const columns= [
      {
        label: 'Kiosk Name',
        field: 'kioskName',
        
      },
      {
        label: 'Total Sale',
        field: 'totalSale',
        
      },
      {
        label : 'Discount',
        field : 'discount'
      },
      {
        label: 'Total Leftover',
        field: 'totalLeftover',
        
      },
      {
        label: 'Item Sold',
        field: 'itemSold',
        
      },
      
      {
        label: 'Action',
        field: 'action',
     
      }
    ]

  useEffect(()=>{
    

      getSaleData()
    
  },[dates,selectedChannel])

  useEffect(()=>{
    if(selectedKiosk ){
        getOrderDetail()
     
    }
  },[selectedKiosk])

  const getSaleData = ()=>{
    setLoader(true)
      apiCounter++
      axios.post(saleDataUrl,{
        dates:dates,
        channel : selectedChannel,
      },{
        headers:{
          'Authorization':token
        }
      }).then(response =>{
        console.log(response)
        apiCounter--
        const data = response.data.data
    
        for (var i=0;i<data.length;i++){
          data[i]['action'] = <button className='detail-btn font-2' value={data[i].kioskName} onClick={(e)=>{setSelectedKiosk(e.target.value);setIsDetail(true)}}>Details</button>
        }
        console.log(data)
        setSaleData({columns:columns,rows:response.data.data})
        if(apiCounter==0){
          setLoader(false)
        }
      }).catch(error => {
        console.log(error)
        apiCounter--
        if(apiCounter==0){
          setLoader(false)
        }
      })
  }


  const getOrderDetail = () =>{
        setLoader(true)
        apiCounter++
        axios.post(orderDetailUrl,{
          dates : dates,
          channel : selectedChannel,
          kiosk : selectedKiosk
        },{
          headers : {
            'Authorization' : token
          }
        }).then(response => {
          console.log(response)
          setOrderDetail(response.data)
          apiCounter--
          if(apiCounter ===0){
            setLoader(false)
          }
        }).catch(error=>{
          console.log(error)
          apiCounter--
          if(apiCounter ===0){
            setLoader(false)
          }
        })
  }



  if (loader) {
    return (
      <Loader/>
    )
  }
  else {
    return (
      <div className='h-100 sales'>
        <div className="row h-100">
          <div className="col-12 h-100">
          <div className="row reverse-col">
              <div className="col-sm-8 my-2">
               <SaleChnnelButton selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}/>
              </div>
              <div className="col-sm-4 my-2">
                <div className="row  h-100 justify-content-end">
                  <div className='col-9 h-100 '>

                    <CustomDatePicker dates={dates} setDates ={setDates} />
                  </div>
                  <div className="col-3 h-100" style={{textAlign:'end'}}>
                    
                  <DownloadButton data={saleData} filename="SaleReport.csv"/>
                  </div>

                </div>
              </div>
            </div>
                
              
            <div className='desktop' style={{ height: '95%' }}>
              <TableWithPagination data={saleData} setSelectedKiosk = {setSelectedKiosk}/>
            </div>

           

                <div className="mobile row">
                  <div className="col-12">

                    <p className='font-2 semi-bold mb-1'>Sales</p>
                  </div>
                  {
                    saleData.rows && saleData.rows.map((data,index)=>{
                     return  <div className="col-12 "  onClick={(e)=>{setSelectedKiosk(data.kioskName);setIsDetail(true);}}>
                      <div className='mobile-item-box-div my-1'>
                      <div className="d-flex justify-content-between py-1">
                      <span className='font-2'>{data.kioskName}</span>
                      <span className='font-2'>Total Sold: {data.itemSold}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className='font-2'>Total Sale: {data.totalSale}</span>
                      <span className='font-2'>Total Leftover: {data.totalLeftover}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className='font-2'>Discount: {data.discount}</span>
                      {/* <span className='font-2'>Total Leftover: {data.totalLeftover}</span> */}
                    </div>
                      </div>
                    
                  </div>
                    })
                  }
                </div>
           


          </div>
        </div>
        <Modal
          show={isDetail}
          onHide={() => { setIsDetail(false);setSelectedKiosk() }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          
        >

          <Modal.Body style={{maxHeight:"80vh",overflow : 'auto'}}>

            <div className='p-3 h-100' >
              <p className='text-center font-2 semi-bold'>Sale Details</p>
              {
                orderDetail.length ===0 && <div>
                  <img className='w-100' src={noDataIcon} alt="" />
                  <p className='font-2 semi-bold text-center' style={{textAlign:'center'}}>No Sale Record Available</p>
                  </div>
              }
              
              {orderDetail.map((data,index)=>{
                return <div className='row item-box my-2 p-2'>
                <div className="col-1">
                  <span className='font-2'>{index+1}</span>
                </div>
                <div className="col-5">
                  <p className='font-2 m-0'>Rs. {data.price}({data.inventoryPrice})</p>
                  <p className='font-2 m-0 '>Order # {data.order_number}</p>
                  <p className='font-2 m-0 '>Discount: {data.discount}</p>
                </div>
                <div className="col-5" style={{ textAlign: 'end' }}>
                  <p className='font-2 m-0 '>{data.time}</p>
                  <p className='font-2 m-0'>Item Sold : {data.item_sold}</p>
                  <p className='font-2 m-0'>Name : {data.name?data.name:'NULL'}</p>
                </div>


              </div>
              })}
              

              
            </div>

            





          </Modal.Body>

        </Modal>
      </div>
    )
  }
}
