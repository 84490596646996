import React,{useEffect, useState} from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import {  Modal } from "react-bootstrap";
import Select from 'react-select'
import CancelButton from '../../Components/CancelButton/CancelButton'
import BlackButton from '../../Components/BlackButton/BlackButton';
import deleteIcon from '../../Assets/delete-icon.svg'
import { permissionsContext } from '../../App';
import Swal from 'sweetalert2';
import { TopBarTitleContext } from '../../App'
import { MDBTooltip } from 'mdb-react-ui-kit';

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useContext } from 'react';


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getGroupsUrl  = axiosUrls.getGroups
const addGroupUrl = axiosUrls.addGroup
const editGroupUrl = axiosUrls.editGroup
const deleteGroupUrl = axiosUrls.deleteGroup

export default function GroupPermissions() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [groupData,setGroupData] = useState([])
    const [errors, setErrors] = useState({})
    const [isDetailModal,setIsDetailModal] = useState(false)
    const [groupDetail,setGroupDetail] = useState({})
    const [userOptions,setUserOptions] = useState([])
    const [permissionsOptions,setPermissionsOptions] = useState([])
    const [isEdit,setIsEdit] = useState(false)

    const permissions = useContext(permissionsContext)

    const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Settings')

    useEffect(()=>{
        getGroupsApi()
    },[])

    const columns = [
        {
          label: 'Group Name',
          field: 'name',
        }, {
          label: 'Creation Date',
          field: 'creationDate'
        }, {
          label: 'Details',
          field: 'details'
        }, 
        
        (permissions.includes('Can Edit Group') || permissions.includes('Can Delete Group')) && {
          label: 'Action',
          field: 'action'
        }
      ].filter(Boolean)

      const handleOnChange = (e) =>{
        const {name,value} = e.target
        setGroupDetail(prevState=>({
            ...prevState,
            [name] : value
        }))
      }
      const onRolePermissionChange = (e) =>{
        setGroupDetail(prevState =>({
            ...prevState,
            ['rolePermissions'] : e
        }))
      }

      const onRoleUserChange = (e) =>{
        setGroupDetail(prevState =>({
            ...prevState,
            ['roleUsers'] : e
        }))
      }

      const moreDetail = (data) =>{
            const rolePermissions = []
            data.permissions.forEach(element => {
                rolePermissions.push({value:element.name,label:element.name})
            });
            const roleUsers = []
            data.users.forEach(element => {
                roleUsers.push({value:element.name,label:element.name})
            });

            setGroupDetail({id:data.id,groupName:data.name,rolePermissions:rolePermissions,roleUsers:roleUsers})
      }

    const getGroupsApi = () =>{
        setLoader(true)
        apiCounter++
        axios.get(getGroupsUrl,{
            headers : {
                'Authorization' : token
            }
        }).then(response =>{
            console.log(response)
            const data = response.data.groups
            for(let i=0 ; i<data.length;i++){
                
                
                data[i]['details'] = <button className='detail-btn' onClick={()=>{moreDetail(data[i]);setIsDetailModal(true);setIsEdit(true)}}>Details</button>
                
                data[i]['action'] = <div className='d-flex align-items-center justify-content-start'>

                {/* <button  style={{backgroundColor:'transparent'}} value={i}  >
                  <img src={editIcon} alt="" />
                  </button> */}
                  {permissions.includes('Can Delete Group') && (
                    <MDBTooltip tag='span' wrapperClass="" title="Delete Group" placement='bottom'>
      
                    <button style={{backgroundColor:'transparent'}} onClick={()=>{deleteGroupApi(data[i].id)}}><img src={deleteIcon} alt="" /></button>
                  </MDBTooltip>
                  )}
                
              </div>

            }
            const userOptions =[]
            const userData = response.data.allUsers
            for(let i=0;i<userData.length;i++){
                userOptions.push({value:userData[i].name,label:userData[i].name})
            }
            setUserOptions(userOptions)
            
            const permissionsOptions =[]
            const permissionsData = response.data.allPermissions
            for(let i=0;i<permissionsData.length;i++){
                permissionsOptions.push({value:permissionsData[i].name,label:permissionsData[i].name})
            }
            setPermissionsOptions(permissionsOptions)
            setGroupData({columns:columns,rows:response.data.groups})
            apiCounter--
            if(apiCounter===0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)
            apiCounter--
            if(apiCounter===0){
                setLoader(false)
            }
        })
    }

    const addGroupApi = (e) =>{
        e.preventDefault()
        setLoader(true)
        apiCounter++
        
        const $users= []
        if(groupDetail.roleUsers){

          groupDetail.roleUsers.forEach(element => {
              $users.push(element.value)
          });
        }

        const permissions= []
        if(groupDetail.rolePermissions){
          groupDetail.rolePermissions.forEach(element => {
            permissions.push(element.value)
        });
        }
        
        const data= {groupName:groupDetail.groupName,roleUsers:$users,rolePermissions:permissions}
        
        
        axios.post(addGroupUrl,data,{
            headers : {
                'Authorization' : token
            }
        }).then(response =>{
            console.log(response)
            setIsDetailModal(false)
            setErrors({})
            setGroupDetail({})
            getGroupsApi()
            Swal.fire(
              'Success',
              'Group is added successfully',
              'success'
            )
            apiCounter--
            if(apiCounter ===0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)
            if(error.response.status === 422){
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if(apiCounter ===0){
                setLoader(false)
            }
        })
    }
    const editGroupApi = (e) =>{
        e.preventDefault()
        setLoader(true)
        apiCounter++
        
        const $users= []
        if(groupDetail.roleUsers){

          groupDetail.roleUsers.forEach(element => {
              $users.push(element.value)
          });
        }

        const permissions= []
        if(groupDetail.rolePermissions.length){
          groupDetail.rolePermissions.forEach(element => {
              permissions.push(element.value)
          });
        }
        
        const data= {id:groupDetail.id,groupName:groupDetail.groupName,roleUsers:$users,rolePermissions:permissions}
        
        
        axios.post(editGroupUrl,data,{
            headers : {
                'Authorization' : token
            }
        }).then(response =>{
            console.log(response)
            setIsDetailModal(false)
            setIsEdit(false)
            setErrors({})
            setGroupDetail({})
            getGroupsApi()
            Swal.fire(
              'Success',
              'Group is updated successfully',
              'success'
            )
            apiCounter--
            if(apiCounter ===0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)
            if(error.response.status === 422){
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if(apiCounter ===0){
                setLoader(false)
            }
        })
    }

    const deleteGroupApi = (id) =>{
        if(window.confirm('Sure want to delete group')){
            setLoader(true)
            apiCounter++

            axios.get(deleteGroupUrl+'/'+id,{
                headers : {
                    'Authorization' : token
                }
            }).then(response =>{
                console.log(response)
                apiCounter--
                getGroupsApi()
                Swal.fire(
                  'Success',
                  'Group is deleted successfully',
                  'success'
                )
                if(apiCounter==0){
                    setLoader(false)
                }
            }).catch(error =>{
                console.log(error)
                apiCounter--
                if(apiCounter==0){
                    setLoader(false)
                }
            })
        }
    }

    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className="h-100">
                <div className="row">
                    <div className="col-6 desktop">
                        <span className='font-2 semi-bold'>Group Permissions</span>
                    </div>

                    <div className="col-6" style={{ textAlign: 'end' }}>
                        {/* <BlackButton text="Add Category" className="mr-3 font-2" style={{padding:'7px'}}/> */}
                        {
                            permissions.includes('Can Add Group') && <GradientButton text="Add New Group" onClick={()=>{setIsDetailModal(true)}}/>
                        }
                        
                        
                        {/* <DownloadButton className="ml-3" style={{padding:'7px'}}/> */}
                    </div>



                </div>
                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={groupData}/>
                </div>

                <div className="mobile py-2 row">
          <div className="col">

            <p className='font-2 semi-bold mb-1'>Group Permissions</p>
          </div>

          {
            groupData.rows && groupData.rows.map((data, index) => {
              return <div className="col-12 " >
                <div className="mobile-item-box-div my-1" onClick={()=>{moreDetail(data);setIsDetailModal(true);setIsEdit(true)}}>
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.name}</span>
                    <span className='font-2'>
                    {
                                            permissions.includes('Can Delete Group') && <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { deleteGroupApi(data.id) }}> <img src={deleteIcon} alt="" /></button>
                     }
                    </span>

                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>{data.creationDate}</span>
                    {/* <span className='font-2'>{data.date} - {data.time}</span> */}
                  </div>
                </div>

              </div>
            })
          }


        </div>

                <Modal
              show={isDetailModal}
              onHide={() => { setIsDetailModal(false);setGroupDetail({});setIsEdit(false) }}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
    
              <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>
    
                <form className='p-3 h-100' onSubmit={isEdit?editGroupApi:addGroupApi}>
                  <p className='text-center font-2 semi-bold'>Role & Permissions</p>
    
    
                  <div className="row" >
                    
    
                    <div className="col-12 py-1">
                      <label  className='m-0 font-2'>Group Name</label>
                      <input type="text" name="groupName" value={groupDetail.groupName} className={'form-control font-2 ' + (errors['groupName'] ? 'is-invalid' : '')} readOnly={isEdit?true:false} onChange={handleOnChange}/>
                      <div className="invalid-feedback">
                        {errors['groupName'] && errors['groupName']}
                      </div>
                    </div>
    
                    <div className="col-12 py-1">
                      <label  className='m-0 font-2'>Select Permissions</label>
                      <Select isMulti options={permissionsOptions} value={groupDetail.rolePermissions} onChange={onRolePermissionChange} className='form-select is-invalid'>
                      </Select>
                      <div className="invalid-feedback">
                        {errors['selectedRoles'] && errors['selectedRoles']}
                      </div>
                    </div>

                    <div className="col-12 py-1">
                      <label  className='m-0 font-2'>Select User</label>
                      <Select isMulti options={userOptions} value={groupDetail.roleUsers} onChange={onRoleUserChange}>
                      </Select>
                      <div className="invalid-feedback">
                        {errors['selectedPermissions'] && errors['selectedPermissions']}
                      </div>
                    </div>

                    
    
    
    
                    
    
    
                  </div>
    
                  <div className="d-flex justify-content-end mt-2 pt-1">
                    
                    <CancelButton onClick={(e) => {e.preventDefault();setIsDetailModal(false);setGroupDetail({});setIsEdit(false)}} />
                    {
                        permissions.includes('Can Edit Group') && <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
                    }
                    
                  </div>
    
    
                </form>
              </Modal.Body>
              </Modal>
            </div>
        )
    }
}
