import React,{useState,useContext} from 'react'
import './Login.css'
import leftCircle from '../../Assets/leftcircle.svg'
import rightCircle from '../../Assets/rightcirlcle.svg'
import axios from '../../Api/Axios'
import * as axiosUrl from '../../Api/AxiosUrls'
import { useNavigate } from 'react-router-dom'



const loginUrl = axiosUrl.Login
export default function Login() {
    const [formData,setFormData] = useState({
        'email':'',
        'password':''
    })

    const [showPassword,setShowPassword] = useState(false)


    const navigate = useNavigate()

    

    const[errors,setErrors] = useState({})

    const handleFormChange = e =>{

        const {name,value} = e.target
        setFormData(prevState => ({
            ...prevState,
            [name] :value
        }))
    }


    const loginApi = (e) =>{
            e.preventDefault()
            const remember = document.getElementById('remember-checkbox')
            
            axios.post(loginUrl,{
                email:formData.email,
                password:formData.password
            }).then(response =>{
                console.log(response)
                localStorage.setItem('dwf-token',response.data.token)
                // if(remember.checked){
                    localStorage.setItem('dwf-remember',true)
                // }
                // navigate('/')
                
                window.location.href="/"
                
                setErrors({})
            }).catch(error =>{
                console.log(error)
                setErrors({})
                // if()
                if(error.response.status ===401){
                    alert(error.response.data.error)
                }
                else if(error.response.status ===422){
                    setErrors(error.response.data.errors)
                }
            
                   
                
            })

            
    }

  return (
    <div className='login'>
       <img src={leftCircle} className='left-circle-img' alt="" />
       <img src={rightCircle} className='right-circle-img' alt="" />

       <div className="row h-100 align-items-center justify-content-center">
        <div className="col-sm-3">
            <div className="row">
                <div className="col">
                    <form onSubmit={loginApi}>
                    <p className='font-1 semi-bold'>Login in to DWTF</p>
                    {/* <p className='font-3'>Login in to DWTF</p> */}
                    <div className='input mb-3'>
                        <label htmlFor="email" className='font-2 semi-bold pb-2'>Email Address</label><br />
                        <input type="email" name="email"   className={'email' in errors ? 'p-3 form-control is-invalid':'p-3 form-control'} value={formData.email} onChange={handleFormChange} placeholder='i.e. example@gmail.com' />
                        <div  class="invalid-feedback">
                        {errors.email}
                        </div>
                    </div>
                    
                    <div className='input mb-3' >
                        <label htmlFor="password" className='font-2 semi-bold pb-2'>Password</label><br />
                        <div  style={{position:'relative'}}>

                        <input type={showPassword?'text':"password"} name='password' value={formData.password} onChange={handleFormChange} className={'password' in errors ? 'p-3 form-control is-invalid':'p-3 form-control'} placeholder='********' />
                        <i id="eye" className={ showPassword? 'fas fa-eye-slash':'fas fa-eye'} onClick={()=>{setShowPassword(!showPassword)}}></i>
                        <div  class="invalid-feedback">
                        {errors.password}
                        </div>
                        </div>
                    </div>
                    
                        <div>
                            {/* <input type="checkbox" name="remember" id="remember-checkbox" /><label htmlFor="remember">Remember me</label> */}
                        </div>
                        <div className="row">
                            <div className="col-6 offset-3">
                                <button className='font-2 semi-bold w-100 p-1 sign-in-btn mt-3' type='submite'>Sign In</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
       </div>
    </div>
  )
}
