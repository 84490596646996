import React,{useState,useEffect,useContext} from 'react'
import './Dashboard.css'

import {subDays} from 'date-fns'

import { TopBarTitleContext } from '../../App'


// Components
import Sidebar from '../../Components/Sidebar/Sidebar'
import Topbar from '../../Components/Topbar/Topbar'
import CustomPieChart from '../../Components/CustomPieChart/CustomPieChart'
import CustomLineChart from '../../Components/CustomLineChart/CustomLineChart'
import CustomBarChart from '../../Components/CustomBarChart/CustomBarChart'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../Components/CustomSelect/CustomSelect'
import Loader from '../../Components/Loader/Loader'


// Asset
import bestPerformer from '../../Assets/best-performer-icon.svg'
import pieIcon from '../../Assets/pie-icon.svg'
import saleChannelPieIcon from '../../Assets/sale-channel-pie-icon.svg'
import saleStatIcon from '../../Assets/sale-stat-icon.svg'
import increaseIcon from '../../Assets/Increase-icon.svg'
import decreaseIcon from '../../Assets/decrease-icon.svg'
import activeKioskIcon from '../../Assets/active-kiosk-icon.svg'
import productCardIcon from '../../Assets/product-card-icon.svg'
import employeeCardIcon from '../../Assets/employee-card-icon.svg'
import statsCardIcon from '../../Assets/stats-card-icon.svg'

import dwfAnimation from '../../Assets/dwf-animation.gif'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const adminDashboardUrl = axiosUrls.Dashboard


export default function Dashboard() {
  const [loader ,setLoader] = useState(false)
  let apiCounter = 0
  const [dates ,setDates] = useState([subDays(new Date(),30) , new Date()])

  const [topBarTitle,setTopBarTitle] = useContext(TopBarTitleContext)

  setTopBarTitle('Dashboard')
  
  // const [kiosk,setKiosk] = useState([])


  const [selectedKiosk,setSelectedKiosk] = useState('')
  const [topSeller,setTopSeller] = useState([])
  const [soldAndLeftover,setSoldAndLeftover] = useState([])
  const [overallSaleAnalysis,setOverallSaleAnalysis] = useState([])
  const [saleStats,setSaleStats] = useState([])
  const [monthlyPerformance,setMonthlyPerformance] = useState([])
  const [topFlavours,setTopFlavours] = useState([])
  const [activeKiosk,setActiveKiosk] = useState(0)
  const [activeProduct,setActiveProduct] = useState(0)
  const [totalEmployee,setTotalEmployee] = useState(0)



  


  useEffect(()=>{
    
     
      //  getData();
    
  },[monthlyPerformance])

  useEffect(()=>{

  },[])

  

  useEffect(()=>{


      getData()
 
  },[selectedKiosk,dates])



  


  const getData = () =>{
    setLoader(true)
    apiCounter++  
    axios.post(adminDashboardUrl,
      {
        dates :dates,
        kiosk:selectedKiosk? selectedKiosk: ''
      },
      {
      
      headers:{
        'Authorization' : token,
        
      }
    }).then(response =>{
      console.log(response)
      const data = response.data
      setTopSeller(data.topSeller)
      setSoldAndLeftover(data.soldAndLeftover)
      setOverallSaleAnalysis(data.overallSaleAnalysis)
      setSaleStats(data.saleStats)
      setMonthlyPerformance(data.monthlyPerformance)
      setTopFlavours(data.topFlavours)
      setActiveKiosk(data.activeKiosk)
      setActiveProduct(data.activeProduct)
      setTotalEmployee(data.totalEmployee)
      apiCounter--
      if(apiCounter==0){
        setLoader(false)
      }
      
      
    }).catch(error =>{
      console.log(error)
      apiCounter--
      if(apiCounter==0){
        setLoader(false)
      }
      
      
    })
  }

  

  
  const Sold_leftover_pie_colors = ['#F98925', '#232529'];

 
  const saleChannelColor = ['#F98925', '#232529', '#A51854'];
  if(loader){
      return (
      <Loader />
      )
  }
  else{
  return (
    <div id='dashboard' className='h-100 mr-sm-5 ml-sm-5'>
      
      <div className="row h-100">
        <div className="col h-100">
          <div className='row'>
            <div className="col-sm-3 mt-2">

              <CustomSelect value={selectedKiosk} handleOnChange = {setSelectedKiosk} text="All Kiosks"/>
            </div>

            <div className="col-sm-3 offset-sm-6 mt-2">

            <CustomDatePicker dates={dates} setDates ={setDates}/>
            
            </div>

          </div>

          {/* <div className="row dash-cards">
            <div className="col-sm-6 col-md-3 my-3">
              <div className="dash-card p-3">
                
                   <div className="d-flex flex-column justify-content-between h-100">
                  <div className="d-flex align-items-center">
                    <img src={bestPerformer} alt="" />
                    <p className='font-2 m-0 px-1'>Best Performer <span className='font-8'>(Not effected by kiosk)</span> </p>
                  </div>

                  <div>
                  {topSeller &&<p className='font-2 m-0'>{topSeller.kiosk}</p>}
                  {topSeller &&<p className='font-2 m-0'>Sold <span className='yellow-color'>{topSeller.sold}</span> <span className='px-3'>Leftovers <span className='yellow-color'>{topSeller.leftovers}</span></span> </p>}
                  </div>
                  {topSeller &&<span className='font-2'>Rs. {topSeller.total_price}</span>}
                </div>
                
                

              </div>
            </div>
            <div className="col-sm-6 col-md-3 my-3">
              <div className="dash-card p-3">
                <div className="d-flex flex-column h-100">
                  <CustomPieChart icon={pieIcon} title={'Sold & Leftover Stats'} data={soldAndLeftover} colors={Sold_leftover_pie_colors} />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 my-3">
              <div className="dash-card p-3">
                <div className="d-flex flex-column h-100">
                  <CustomPieChart icon={saleChannelPieIcon} title={'Overall Sales Analysis'} data={overallSaleAnalysis} colors={saleChannelColor} />
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 my-3">
              <div className="dash-card p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="d-flex align-items-center">
                    <img src={saleStatIcon} alt="" />
                    <p className='font-2 m-0 px-1'>Sale Stats</p>
                  </div>

                  <div>
                    <p className='font-2 m-0'>Rs. {saleStats.total_price}</p>
                    <p className='font-7 m-0'> <img src={saleStats.isIncrease? increaseIcon : decreaseIcon} alt="" /> <span style={{ color: saleStats.isIncrease  ? '#58D832' : '#D70F64' }}>{saleStats.percentage}% from last {saleStats.days} days</span> </p>
                    
                  </div>
                  
                  <span className='font-2'>Product Sold {saleStats.sold}</span>
                </div>

              </div>
            </div>

          </div> */}

          {/* <div className="row charts">
            <div className="col-sm-12 col-md-8 chart-div my-3 ">
              <div className='chart-card  h-100 p-3 perfomance-chart-div'>
                <div className='perfomance-chart h-100'>
                <div className="chart-title">
                  
                  <img src={bestPerformer} alt="" style={{ verticalAlign: 'middle' }} />
                  <span className='px-1 font-2'>Monthly Performance <span className='font-8'>(not effected by date)</span></span>
                </div>
                <div style={{ height: "90%" }}>
                  <CustomLineChart data={monthlyPerformance}/>
                </div>
                </div>
                
              </div>
            </div>
            <div className="col-sm-12 col-md-4 chart-div my-3">
              <div className='chart-card h-100 p-3'>
                <div className="chart-title">
                <img src={bestPerformer} alt="" style={{ verticalAlign: 'middle' }} />
                  <span className='px-1 font-2'>Top Selling Flavours </span>
                </div>
                <div style={{ height: "90%" }}>

                  <CustomBarChart data={topFlavours}/>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row mt-5">
            <div className="col-sm-6 col-md-3 pb-3">

            <div className="p-3 text-card kiosk-card">
                <p className='mb-1 font-1 semi-bold'>{activeKiosk}</p>
                <p className='mb-1 font-1 semi-bold'>Active Kiosks</p>
                <div className='d-flex justify-content-end'>
                  <img src={activeKioskIcon} alt="" />
                </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-3 pb-3">

            <div className="p-3 text-card product-card">
                <p className='mb-1 font-1 semi-bold'>{activeProduct}</p>
                <p className='mb-1 font-1 semi-bold'>Active Products</p>
                <div className='d-flex justify-content-end'>
                  <img src={productCardIcon} alt="" />
                </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-3 pb-3">

            <div className=" p-3 text-card employee-card">
                <p className='mb-1 font-1 semi-bold'>{totalEmployee}</p>
                <p className='mb-1 font-1 semi-bold'>Total Employees</p>
                <div className='d-flex justify-content-end'>
                  <img src={employeeCardIcon} alt="" />
                </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-3">

            <div className="p-3 text-card stats-card">
                <p className='mb-1 font-1 semi-bold'>{parseInt(saleStats.total_price).toLocaleString()}</p>
                <p className='mb-1 font-1 semi-bold'>Sale Stats(<span className='font-2'>PKR</span>)</p>
                <div className='d-flex justify-content-end'>
                  <img src={statsCardIcon} alt="" />
                </div>
            </div>
            </div>
          </div>

          <div className="row mt-4 dash-cards ">
            <div className="col-sm-4 dash  pb-3">
            <div className="dash-card h-100 p-3">
                <div className="d-flex flex-column h-100">
                  <CustomPieChart icon={pieIcon} title={'Sold & Leftover Stats'} data={soldAndLeftover} colors={Sold_leftover_pie_colors} />
                </div>
              </div>
            </div>
            <div className="col-sm-8 dash pb-3">
              <div className='dash-card h-100 p-3 perfomance-chart-div '>
                  <div className='perfomance-chart h-100'>
                  <div className="chart-title">
                    
                    {/* <img src={bestPerformer} alt="" style={{ verticalAlign: 'middle' }} /> */}
                    <span className='px-1 font-1 semi-bold'>Monthly Performance <span className='font-2' style={{fontWeight:"normal !important"}}>(not effected by date)</span></span>
                  </div>
                  <div style={{ height: "90%" }}>
                    <CustomLineChart data={monthlyPerformance}/>
                  </div>
                  </div>
                  
                </div>
            </div>

            
          </div>

          <div className="row mt-4 dash-cards ">
            <div className="col-sm-8 dash pb-3 ">
            <div className='dash-card h-100 p-3 perfomance-chart-div'>
              <div className="h-100 perfomance-chart">

                <div className="chart-title">
                {/* <img src={bestPerformer} alt="" style={{ verticalAlign: 'middle' }} /> */}
                  <span className='px-1 font-2'>Top Selling Flavours </span>
                </div>
                <div style={{ height: "90%" }}>

                  <CustomBarChart data={topFlavours}/>
                </div>
              </div>
              </div>
            </div>
            <div className="col-sm-4 dash pb-3">
            <div className="dash-card h-100 p-3 ">
                <div className="d-flex flex-column h-100">
                  <CustomPieChart icon={saleChannelPieIcon} title={'Overall Sales Analysis'} data={overallSaleAnalysis} colors={saleChannelColor} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
  }
}
