import React, { useEffect, useState } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import CancelButton from '../../Components/CancelButton/CancelButton'
import Loader from '../../Components/Loader/Loader'
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select'
import PlusButton from '../../Components/PlusButton/PlusButton'
import MinusButton from '../../Components/MinusButton/MinusButton'
import deleteIcon from '../../Assets/delete-icon.svg'
import editIcon from '../../Assets/edit-icon.svg'
import { permissionsContext } from '../../App'
import Swal from 'sweetalert2'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useContext } from 'react'
import { MDBTooltip } from 'mdb-react-ui-kit';


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const getDealUrl = axiosUrls.GetDeal
const getProductUrl = axiosUrls.Products
const addDealUrl = axiosUrls.AddDeal
const ediDealUrl = axiosUrls.EditDeal
const deleteDealUrl = axiosUrls.DeleteDeal

export default function Deals() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [dealData, setDealData] = useState([])
  const [isDetailModal, setIsDetailModal] = useState(false)
  const [detailIndex, setDetailIndex] = useState()
  const [isEditAndAddModal, setIsEditAndAddModal] = useState(false)
  const [editAndAddDeal, setEditAndAddDeal] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [productData, setProductData] = useState([])
  const [categories, setCategories] = useState([])
  const [errors, setErrors] = useState({})

  const permissions = useContext(permissionsContext)

  useEffect(() => {
    getProductApi()
    getDealApi()
    console.log(apiCounter,'------------------------------')

  }, [])

  // useEffect(() => {
  //   console.log(editAndAddDeal, '00000000000000000000000000000000000000')
  // }, [editAndAddDeal])


  useEffect(() => {
    if (detailIndex) {
      setIsDetailModal(true)
    }
  }, [detailIndex])

  // useEffect(() => {
    
    
  // }, [])

  const columns = [
    {
      label: 'Deal Name',
      field: 'dealName',
    }, {
      label: 'Date Added',
      field: 'dateAdded',
    }, {
      label: 'Price',
      field: 'dealPrice'
    }, {
      label: 'Items',
      field: 'details'
    },
    (permissions.includes('Can Edit Deal') || permissions.includes('Can Delete Deal')) && {
      label: 'Action',
      field: 'action'
    }
  ].filter(Boolean)

  const handleEditAndAdd = (e) => {
    const { name, value } = e.target
    setEditAndAddDeal(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleItemChange = (e) => {
    setEditAndAddDeal(prevState => ({
      ...prevState,
      ['products']: e
    }))

    // let products = []
    //   e.forEach(element => {
    //     products.push(element.value)
    //   });
    //   setEditAndAddDeal(prevState=>({
    //     ...prevState,
    //     ['products'] :products
    //   }))
  }



  const addItemToDeal = () => {
    if (editAndAddDeal.category) {
      let products = []
      if (editAndAddDeal.products) {

        editAndAddDeal.products.forEach(element => {
          products.push(element.value)
        });
      }
      let item = { category: editAndAddDeal.category, products: products, quantity: 1 }

      if (editAndAddDeal.dealItems) {

        setEditAndAddDeal(prevState => ({
          ...prevState,
          ['dealItems']: [...prevState.dealItems, item]
        }))
      }
      else {
        setEditAndAddDeal(prevState => ({
          ...prevState,
          ['dealItems']: [item]
        }))
      }
      setEditAndAddDeal(prevState => ({
        ...prevState,
        ['category']: ''
      }))
      setEditAndAddDeal(prevState => ({
        ...prevState,
        ['products']: ''
      }))



      // setEditAndAddDeal(prevState=>({
      //   ...prevState,
      //   ['category']:null
      // }))
      // setEditAndAddDeal(prevState=>({
      //   ...prevState,
      //   ['products']:null
      // }))


    }
  }

  const handleItemCancel = (e) => {
    e.preventDefault()
    setEditAndAddDeal(prevState => ({
      ...prevState,
      ['category']: '',
      ['products']: ''
    }))
  }

  const plusQuantity = (index) => {

    setEditAndAddDeal(prevState => {
      const updateData = { ...prevState }
      const itemToUpdate = updateData.dealItems[index]

      itemToUpdate.quantity = itemToUpdate.quantity + 1
      return updateData

    })
  }
  const minusQuantity = (index) => {
    setEditAndAddDeal(prevState => {
      const updateData = { ...prevState }
      const itemToUpdate = updateData.dealItems[index]
      if (itemToUpdate.quantity > 1) {

        itemToUpdate.quantity = itemToUpdate.quantity - 1
      }
      return updateData

    })
  }

  const deleteItemFromDeal = (index) => {
    setEditAndAddDeal(prevState => {
      const updatedState = { ...prevState }
      updatedState.dealItems = prevState.dealItems.filter((_, i) => i !== index);

      return updatedState;
    })
  }


  const getDealApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getDealUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      const data = response.data
      for (let i = 0; i < data.length; i++) {
        data[i]['dealItems'] = JSON.parse(data[i]['dealItems'])
        // data[i]['dealItems'] = JSON.parse(data[i]['dealItems'])
        data[i]['details'] = <button className='detail-btn' value={i} onClick={(e) => { setDetailIndex(e.target.value) }}>Detail</button>
        data[i]['action'] = <div className='d-flex align-items-center justify-content-start'>
          {
            permissions.includes('Can Edit Deal') &&
            <MDBTooltip tag='span' wrapperClass="" title="Edit Deal" placement='bottom'>
            <button onClick={(e) => { setIsEditAndAddModal(true); setEditAndAddDeal(data[i]); setIsEdit(true) }} style={{ backgroundColor: 'transparent' }} value={i}  >
              <img src={editIcon} alt="" />
            </button>
          </MDBTooltip>
             
          }
          {
            permissions.includes('Can Delete Deal') && 
            <MDBTooltip tag='span' wrapperClass="" title="Delete Deal" placement='bottom'>
       
                <button style={{ backgroundColor: 'transparent' }} onClick={()=>{deleteDealApi(data[i].id);}}><img src={deleteIcon} alt=""  /></button>
              </MDBTooltip>
          }




        </div>
      }

      setDealData({ columns: columns, rows: data })
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }


  const getProductApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(getProductUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      apiCounter--
      // const data = response.data.data

      // for(let i=0;i<data.length;i++){
      //       data[i]['action']= <div className='d-flex align-items-center justify-content-center'>

      //         <button onClick={(e)=>{setIsModalShow(true);setIsEdit(true);setEditAndNewProduct({product_name:data[i].productName,category:data[i].productCategory,price:data[i].price,id:data[i].id})}} style={{backgroundColor:'transparent'}} value={i}  >
      //           <img src={editIcon} alt="" />
      //           </button>
      //         <button style={{backgroundColor:'transparent'}} onClick={()=>{deleteProductApi(data[i].id)}}><img src={deleteIcon} alt="" /></button>
      //       </div>
      // }
      const data = response.data.data
      const productData = {}
      for (let i = 0; i < response.data.categories.length; i++) {
        productData[response.data.categories[i]] = [];

      }
      for (let j = 0; j < response.data.data.length; j++) {
        productData[response.data.data[j].productCategory].push({ value: response.data.data[j].productName, label: response.data.data[j].productName })
      }

      setProductData(productData)
      setCategories(response.data.categories)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }


  const addDealApi = (e) => {
    e.preventDefault()
    setLoader(true)
    apiCounter++
    axios.post(addDealUrl, editAndAddDeal, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      getDealApi()
      setEditAndAddDeal({})
      setIsEditAndAddModal(false)
      Swal.fire(
        'Success',
        'Deal added successfully',
        'success'
      )
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      if (error.response.status === 422) {

        setErrors(error.response.data.errors)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const editDealApi = (e) => {
    e.preventDefault()
    setLoader(true)
    apiCounter++
    axios.post(ediDealUrl, editAndAddDeal, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      apiCounter--
      setErrors([])
      setIsEdit(false)
      setEditAndAddDeal({})
      setIsEditAndAddModal(false)
      getDealApi()
      Swal.fire(
        'Success',
        'Deal is updated successfully',
        'success'
      )

      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
      }
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }

  const deleteDealApi = (id) => {

    if (window.confirm('Sure to want delete product')) {
      setLoader(true)
      apiCounter++
      axios.get(deleteDealUrl + '/' + id, {
        headers: {
          'Authorization': token
        }
      }).then(response => {
        console.log(response)
        Swal.fire(
          'Success',
          'Deal is deleted successfully',
          'success'
        )
        getDealApi()
        apiCounter--
        if (apiCounter === 0) {
          setLoader(false)
        }
      }).catch(error => {
        console.log(error)
        apiCounter--
        if (apiCounter === 0) {
          setLoader(false)
        }
      })
    }
  }






  if (loader) {
    return <Loader />
  }
  else {
    return (
      <div className="h-100">
        <div className="row">
          <div className="col-3 desktop">
            <span className='font-2 semi-bold'>Deals List</span>
          </div>

          <div className="col-sm-9" style={{ textAlign: 'end' }}>
            {/* <BlackButton text="Add Category" className="mr-3 font-2" style={{padding:'7px'}}/> */}
            <div className="d-flex justify-content-end">
              {
                permissions.includes('Can Add Deal') && <GradientButton text="Add New Deal" onClick={() => { setIsEditAndAddModal(true) }} />
              }

              <DownloadButton className="ml-3"  data={dealData} filename="dealsList.csv" />
            </div>
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={dealData} />
        </div>

        <div className="mobile row py-2">
          <div className="col">

            <p className='font-2 semi-bold mb-1'>Deals List</p>
          </div>
         

          {
            
            dealData.rows && dealData.rows.map((data, index) => {
              return <div className="col-12 " >
                <div className="mobile-item-box-div my-1">
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.dealName}</span>
                    <span className='font-2 d-flex '>
                      {
                        permissions.includes('Can Edit Deal') && <button onClick={(e) => { setIsEditAndAddModal(true); setEditAndAddDeal(data); setIsEdit(true) }} style={{ backgroundColor: 'transparent' }}  >
                          <img src={editIcon} alt="" />
                        </button>
                      }
                      {
                        permissions.includes("Can Delete Deal") && <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteDealApi(data.id) }}><img src={deleteIcon} alt="" /></button>
                      }


                    </span>

                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>{data.dateAdded}</span>
                    <span className='font-2'>Price: {data.dealPrice}</span>
                  </div>
                </div>

              </div>
            })
          }


        </div>


        <Modal
          show={isEditAndAddModal}
          onHide={() => { setIsEditAndAddModal(false); setIsEdit(false); setIsEditAndAddModal(false); setEditAndAddDeal({}); setErrors({}) }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <form className='p-3 h-100' onSubmit={isEdit ? editDealApi : addDealApi}>
              <p className='text-center font-2 semi-bold'>{isEdit ?'Edit Deal':'Add New Deal'}</p>


              <div className="row" >
                <div className="col-12 py-1">
                  <label htmlFor="deal_name" className='m-0 font-2'>Deal Name</label>
                  <input type="text" id="deal_name" name="dealName" value={editAndAddDeal.dealName} className={'form-control font-2 ' + (errors['dealName'] ? 'is-invalid' : '')} onChange={handleEditAndAdd} />
                  <div className="invalid-feedback">
                    {errors['dealName'] && errors['dealName']}
                  </div>
                </div>

                <div className="col-12 py-1">
                  <label htmlFor="deal-price" className='m-0 font-2'>Deal Price (PKR)</label>
                  <input type="number" id="deal-price" name="dealPrice" value={editAndAddDeal.dealPrice} className={'form-control font-2 ' + (errors['dealPrice'] ? 'is-invalid' : '')} onChange={handleEditAndAdd} onWheel={(e) => e.target.blur()}/>
                  <div className="invalid-feedback">
                    {errors['dealPrice'] && errors['dealPrice']}
                  </div>
                </div>





                <div className="col-12 py-1">

                  <label htmlFor="category" className='m-0 font-2'>Category</label>


                  <select name="category" value={editAndAddDeal.category} id="category" className='form-select w-100 font-2 is-invalid' style={{ padding: '6px 10px' }} aria-describedby="validationServer05Feedback" onChange={handleEditAndAdd} >
                    <option></option>
                    {
                      categories.map((category_name, index) => {
                        return <option value={category_name}>{category_name}</option>
                      })
                    }
                  </select>






                  <div className="invalid-feedback">
                    {errors['category'] && errors['category']}
                  </div>





                </div>
                {
                  editAndAddDeal.category && <div className="col-12 py-1">

                    <label htmlFor="category-product" className='m-0 font-2'>Category Product</label>


                    <Select isMulti options={productData[editAndAddDeal.category]} value={editAndAddDeal.products} name='category_product' id='category-product' onChange={handleItemChange}>

                    </Select>







                    {errors['category'] && <small style={{ color: '#dc3545' }} className='pb-1 pl-3'>{errors['category']}</small>}




                  </div>
                }

                <div className="col-12 py-1 pt-1" style={{ textAlign: 'end' }}>
                  <div className="d-flex justify-content-end">

                  <CancelButton text="Cancel" onClick={handleItemCancel} style={{ padding: '2px 7px' }} />
                  <BlackButton text={isEdit? 'Save':"Add"}  style={{  width: '70px',padding: '3.5px 7px'  }} className="font-2 h-100" onClick={addItemToDeal} />
                  </div>
                </div>



                <div className="col-12 py-1">
                  <p className='font-2 mb-2'>Deal Items</p>
                  {errors['dealItems'] && <small style={{ color: '#dc3545' }} className='pb-1 '>{errors['dealItems']}</small>}

                  {
                    editAndAddDeal.dealItems && editAndAddDeal.dealItems.map((data, index) => {
                      return <div className="row mt-1">
                        <div className="col-4 font-2 d-flex align-items-center">
                          {data.category}

                        </div>
                        <div className="col-4 font-2  ">{data.products.map((product)=>{return <p className='m-0 font-2'>{product}</p> })}</div>
                        {
                          console.log(data.products)
                        }
                        <div className="col-2 d-flex justify-content-between align-items-center p-0">
                          <MinusButton onClick={(e) => { minusQuantity(index); e.preventDefault() }} />
                          <span className='font-2'>{data.quantity}</span>

                          <PlusButton onClick={(e) => { plusQuantity(index); e.preventDefault() }} />



                        </div>
                        <div className="col-2 d-flex align-items-center" style={{ textAlign: 'end' }}>
                          <button style={{ backgroundColor: 'white' }} onClick={(e) => { deleteItemFromDeal(index); e.preventDefault() }}><img src={deleteIcon} alt="" /></button>
                        </div>

                      </div>
                    })
                  }

                </div>









              </div>

              <div className="d-flex justify-content-end mt-2">
                <CancelButton onClick={(e) => { e.preventDefault(); setIsEdit(false); setIsEditAndAddModal(false); setEditAndAddDeal({}); setErrors({}) }} />
                <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
              </div>


            </form>
          </Modal.Body>

        </Modal>

        <Modal
          show={isDetailModal}
          onHide={() => { setIsDetailModal(false);setDetailIndex() }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body>

            <div className='p-3'>

              <p className='text-center font-2 semi-bold'>Items Details</p>
              {
                isDetailModal && dealData.rows[detailIndex].dealItems.map((data, index) => {

                  return <div>
                    {
                      data.products.length === 0 ? <div key={index} className='row item-box my-3 p-2'>
                        <div className="col-1">
                          <span className='font-2'>{index + 1}</span>
                        </div>
                        <div className="col-10">
                          <p className='font-2 m-0'>{data.category}</p>

                        </div>
                        <div className="col-1" style={{ textAlign: 'end' }}>
                          <p className='font-2 m-0 '>{data.quantity}</p>
                        </div>
                      </div> : 
                        <div key={index} className='row item-box my-3 p-2'>
                          <div className="col-1">
                            <span className='font-2'>{index + 1}</span>
                          </div>
                          <div className="col-10">
                            {data.products.map((name, index1) => {
                              return <p className='font-2 m-0'>{name}</p>
                             })}
                          </div>
                          <div className="col-1" style={{ textAlign: 'end' }}>
                            <p className='font-2 m-0 '>{data.quantity}</p>
                          </div>
                        </div>
                      
                    }

                  </div>
                })

              }

            </div>









          </Modal.Body>

        </Modal>
      </div>
    )
  }
}
