import React from 'react'
import './loader.css'
import dwfAnimation from '../../Assets/dwf-animation.gif'
import dwfAnimationJson from '../../Assets/dwf-animation.json'
import Lottie from 'react-lottie';
export default function Loader() {

  const lottieOptions = {
    animationData: dwfAnimationJson,
    loop: true,
    autoplay: true,
  };


  return (
    <div className="loader-div" >
        <div className='animation-container d-flex h-100 w-100 align-items-center justify-content-center' >
          <div id="loader" >

            <Lottie  options={lottieOptions} />
          </div>
          {/* <img className='w-25' src={dwfAnimation} alt="" /> */}
        </div>

      </div>
  )
}
