import React, { useEffect, useState, useContext } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'
import CancelButton from '../../Components/CancelButton/CancelButton'
import { Modal } from "react-bootstrap";
import editIcon from "../../Assets/edit-icon.svg"
import deleteIcon from "../../Assets/delete-icon.svg"
import { TopBarTitleContext } from '../../App'
import { permissionsContext } from '../../App'
import noDataIcon from '../../Assets/no-sale-icon.png'
import { MDBTooltip } from 'mdb-react-ui-kit';

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getKioskUrl = axiosUrls.Kiosk
const addKioskUrl = axiosUrls.AddKiosk
const editKioskUrl = axiosUrls.EditKiosk
const deleteKioskUrl = axiosUrls.DeleteKiosk
const getKioskProductPirceUrl = axiosUrls.GetKioskPrice
const setKisokProductPrice = axiosUrls.ChangeKioskPrice
const getKioskDealPrice = axiosUrls.GetKioskDealPrice
const setKioskDealPrice = axiosUrls.SetKioskDealPrice


export default function Kiosk(props) {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0


    const [kioskData, setKioskData] = useState([])
    const [errors, setErrors] = useState({})
    const [editAndAddKiosk, setEditAndAddKiosk] = useState({})
    const [isEditAndAddModal, setIsEditAndAddModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isKioskDetail, setIsKioskDetail] = useState(false)
    const [kioskDetail, setKioskDetail] = useState({})

    const [isProductPriceChangeOpen, setisProductPriceChangeOpen] = useState(false)
    const [productPrice, setProductPrice] = useState([])

    const [isDealPriceOpen, setIsDealPriceOpen] = useState(false)
    const [dealPrice, setDealPrice] = useState([])

    const [topBarTitle, setTopBarTitle] = useContext(TopBarTitleContext)
    const permissions = useContext(permissionsContext)


    setTopBarTitle('User Management')


    useEffect(() => {
        getKioskApi()

    }, [])

    useEffect(() => {
        if (isProductPriceChangeOpen) {
            getKioskProductPriceApi();

        }
    }, [isProductPriceChangeOpen])

    useEffect(() => {
        if (isDealPriceOpen) {
            getKioskDealPriceApi();

        }
    }, [isDealPriceOpen])






    const columns = [
        {
            label: 'Kiosk Name',
            field: 'kioskName',
        }, {
            label: 'Employee Name',
            field: 'employeeName',
        }, {
            label: 'Location',
            field: 'location'
        }, {
            label: 'Start Time',
            field: 'startTime'
        }, {
            label: 'end Time',
            field: 'endTime'
        }, {
            label: 'Landlord Contact',
            field: 'landlordContact'
        }, {
            label: 'Rent',
            field: 'monthlyRent'
        }, {
            label: 'Commission%',
            field: 'commission'
        },
        (permissions.includes('Can Edit Kiosk') || permissions.includes('Can Delete Kiosk')) && {
            label: 'Action',
            field: 'action'
        }

    ].filter(Boolean)

    const handleKioskChange = (e) => {
        const { name, value } = e.target
        setEditAndAddKiosk(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleProductChange = (e) => {
        const { name, value } = e.target
        setProductPrice(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleDealChange = (e) => {
        const { name, value } = e.target
        setDealPrice(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    function convert12to24(time12h) {

        if (time12h) {

            return time12h
            // Extract hour, minute, and AM/PM indicator
            let [timePart, ampm] = time12h.split(' ');
            let [hour, minute] = timePart.split(':').map(Number);

            if(ampm == undefined){
                if(hour > 12){
                    hour = hour % 12 ;
                    ampm = 'pm'
                }else{
                    ampm  = 'am'
                }
            }
            
            const hourStr = hour.toString().padStart(2, '0');
            const minuteStr = minute.toString().padStart(2, '0');

            return `${hourStr}:${minuteStr}`;
        }
        
    }




    const getKioskApi = () => {
        setLoader(true)
        apiCounter++
        axios.get(getKioskUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            props.setKiosk(response.data)
            const data = response.data
            for (let i = 0; i < data.length; i++) {

                data[i]['action'] = <div className="d-flex align-items-center justify-content-start">
                    {
                        permissions.includes('Can Edit Kiosk') &&
                        <MDBTooltip tag='span' wrapperClass="" title="Edit Kiosk" placement='bottom'>
                            <button style={{ backgroundColor: 'transparent' }} onClick={() => { setIsEditAndAddModal(true); setIsEdit(true); setEditAndAddKiosk(data[i]) }}><img src={editIcon} alt="" /></button>
                        </MDBTooltip>
                    }
                    {
                        permissions.includes('Can Delete Kiosk') &&
                        <MDBTooltip tag='span' wrapperClass="" title="Delete Kiosk" placement='bottom'>

                            <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteKioskApi(data[i].id) }}><img src={deleteIcon} alt="" /></button>
                        </MDBTooltip>
                    }


                </div>
            }
            setKioskData({ columns: columns, rows: response.data })
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }



    const addKioskApi = (e) => {
        e.preventDefault()
        setLoader(true)
        apiCounter++
        axios.post(addKioskUrl, editAndAddKiosk, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            getKioskApi()
            setEditAndAddKiosk({})
            setErrors({})
            setIsEditAndAddModal(false)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })
    }
    const editKioskApi = (e) => {
        e.preventDefault()
        setLoader(true)
        apiCounter++
        axios.post(editKioskUrl + '/' + editAndAddKiosk.id, editAndAddKiosk, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            getKioskApi()
            setEditAndAddKiosk({})
            setErrors({})
            setIsEditAndAddModal(false)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        })

    }
    const deleteKioskApi = (id) => {
        if (window.confirm('Want to delete kiosk')) {
            setLoader(true)
            apiCounter++
            axios.get(deleteKioskUrl + '/' + id, {
                headers: {
                    'Authorization': token
                }
            }).then(response => {
                console.log(response)
                getKioskApi()

                setErrors({})

                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }

            }).catch(error => {
                console.log(error)
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors)
                }
                apiCounter--
                if (apiCounter === 0) {
                    setLoader(false)
                }

            })
        }

    }

    const getKioskProductPriceApi = () => {
        setLoader(true)
        apiCounter++
        axios.post(getKioskProductPirceUrl, {
            kioskName: editAndAddKiosk.kioskName
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            // setisProductPriceChangeOpen(false)
            setErrors([])
            var data = response.data
            data['kioskName'] = editAndAddKiosk.kioskName
            setProductPrice(response.data)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            else {
                setErrors([])
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        })
    }

    const setKioskProductPriceApi = (e) => {
        e.preventDefault()
        setLoader(true)
        apiCounter++

        axios.post(setKisokProductPrice, productPrice, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setErrors([])
            setisProductPriceChangeOpen(false)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            else {
                setErrors([])
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        })
    }

    const getKioskDealPriceApi = () => {
        setLoader(true)
        apiCounter++
        axios.post(getKioskDealPrice, {
            kioskName: editAndAddKiosk.kioskName
        }, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            // setisProductPriceChangeOpen(false)
            setErrors([])
            var data = response.data
            data['kioskName'] = editAndAddKiosk.kioskName
            setDealPrice(response.data)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            else {
                setErrors([])
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        })
    }

    const setKioskDealPriceApi = (e) => {
        e.preventDefault()
        setLoader(true)
        apiCounter++

        axios.post(setKioskDealPrice, dealPrice, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setErrors([])
            setIsDealPriceOpen(false)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            else {
                setErrors([])
            }
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        })
    }





    if (loader) {
        return <Loader />
    }
    else {


        return (
            <div className="h-100">
                <div className="row">
                    <div className="col-3 desktop">
                        <span className='font-2 semi-bold'>Kiosk</span>
                    </div>

                    <div className="col-sm-9">
                        <div className="d-flex justify-content-end">
                            {
                                permissions.includes('Can Add Kiosk') && <GradientButton text="Add New Kiosk" onClick={() => { setIsEditAndAddModal(true) }} />
                            }


                            <DownloadButton className="ml-3" data={kioskData} filename="KioskReport.csv" />
                        </div>
                    </div>



                </div>
                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={kioskData} />
                </div>

                <div className="mobile row py-2">
                    <div className="col">

                        <p className='font-2 semi-bold mb-1'>Kiosks <span className='font-2'>(No. of Kiosk = {kioskData.rows && kioskData.rows.length})</span></p>
                    </div>

                    {
                        kioskData.rows && kioskData.rows.map((data, index) => {
                            return <div className="col-12 "  >
                                <div className="mobile-item-box-div my-1" onClick={() => { setKioskDetail(data); setIsKioskDetail(true) }}>
                                    <div className="d-flex justify-content-between py-1">
                                        <span className='font-2'>{data.kioskName}</span>
                                        <span className='font-2 d-flex '>
                                            <button style={{ backgroundColor: 'transparent' }} onClick={() => { setIsEditAndAddModal(true); setIsEdit(true); setEditAndAddKiosk(data) }}><img src={editIcon} alt="" /></button>
                                            <button style={{ backgroundColor: 'transparent' }} onClick={() => { deleteKioskApi(data.id) }}><img src={deleteIcon} alt="" /></button>
                                        </span>

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className='font-2'>{data.employeeName}</span>
                                        <span className='font-2'>{data.timings}</span>
                                    </div>
                                </div>

                            </div>
                        })
                    }


                </div>

                <Modal
                    show={isEditAndAddModal}
                    onHide={() => { setIsEditAndAddModal(false); setIsEdit(false); setEditAndAddKiosk({}); setErrors({}) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <form className='p-3 h-100' onSubmit={isEdit ? editKioskApi : addKioskApi}>
                            <p className='text-center font-2 semi-bold'>{isEdit ? "Edit Kiosk" : "Add New Kiosk"}</p>


                            <div className="row" >
                                <div className="col-12 py-1">
                                    <label htmlFor="kiosk_name" className='m-0 font-2'>Kiosk Name</label>
                                    <input type="text" id="kiosk_name" name="kioskName" value={editAndAddKiosk.kioskName} className={'form-control font-2 ' + (errors['kioskName'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['kioskName'] && errors['kioskName']}
                                    </div>
                                </div>


                                <div className="col-12 py-1">
                                    <label htmlFor="location" className='m-0 font-2'>Location</label>
                                    <input type="text" id="location" name="location" value={editAndAddKiosk.location} className={'form-control font-2 ' + (errors['location'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['location'] && errors['location']}
                                    </div>
                                </div>


                                <div className="col-6 py-1">
                                    <label htmlFor="start_time" className='m-0 font-2'>Start Time</label>
                                    <input type="time" id="start_time" name="startTime" value={convert12to24(editAndAddKiosk.startTime)} className={'form-control font-2 ' + (errors['startTime'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['startTime'] && errors['startTime']}
                                    </div>
                                </div>

                                <div className="col-6 py-1">
                                    <label htmlFor="end_time" className='m-0 font-2'>End Time</label>
                                    <input type="time" id="end_time" name="endTime" value={convert12to24(editAndAddKiosk.endTime)} className={'form-control font-2 ' + (errors['endTime'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['endTime'] && errors['endTime']}
                                    </div>
                                </div>

                                <div className="col-6 py-1">
                                    <label htmlFor="landlord" className='m-0 font-2'>Landlord Contact No</label>
                                    <input type="text" id="landlord" name="landlordContact" value={editAndAddKiosk.landlordContact} className={'form-control font-2 ' + (errors['landlordContact'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['landlordContact'] && errors['landlordContact']}
                                    </div>
                                </div>

                                <div className="col-6 py-1">
                                    <label htmlFor="rent" className='m-0 font-2'>Monthly Rent</label>
                                    <input type="text" id="rent" name="monthlyRent" value={editAndAddKiosk.monthlyRent} className={'form-control font-2 ' + (errors['monthlyRent'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['monthlyRent'] && errors['monthlyRent']}
                                    </div>
                                </div>

                                <div className="col-6 py-1">
                                    <label htmlFor="commisson" className='m-0 font-2'>Commission %</label>
                                    <input type="text" id="commission" name="commission" value={editAndAddKiosk.commission} className={'form-control font-2 ' + (errors['commission'] ? 'is-invalid' : '')} onChange={handleKioskChange} />
                                    <div className="invalid-feedback">
                                        {errors['commission'] && errors['commission']}
                                    </div>
                                </div>
                                <div className="col-12 py-2">
                                    <div className="row ">
                                        <div className="col-6">
                                            {permissions.includes('Can Edit Products Price For Kiosk') && isEdit && <GradientButton text="Edit Product Price" className="font-2" style={{ Width: '70px' }} type='button' onClick={(e) => { e.preventDefault(); setisProductPriceChangeOpen(true) }} />}

                                        </div>

                                        <div className="col-6">
                                            {permissions.includes('Can Edit Deal Price For Kiosk') && isEdit && <GradientButton text="Edit Deal Price" className="font-2" style={{ minWidth: '70px' }} type='button' onClick={(e) => { e.preventDefault(); setIsDealPriceOpen(true) }} />}

                                        </div>
                                    </div>
                                </div>






                            </div>


                            <div className="d-flex justify-content-end mt-2">
                                <CancelButton style={{ minWidth: 'fit-content' }} onClick={(e) => { e.preventDefault(); setIsEdit(false); setIsEditAndAddModal(false); setEditAndAddKiosk({}); setErrors({}) }} />
                                <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
                            </div>





                        </form>
                    </Modal.Body>
                </Modal>


                <Modal
                    show={isKioskDetail}
                    onHide={() => { setIsKioskDetail(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <div className='p-3 h-100' >
                            <p className='text-center font-2 semi-bold'>Kiosk Detail</p>

                            <div className="row" >
                                <div className="col-12">
                                    <label className='m-0 font-2'>Kiosk Name</label>
                                    <input value={kioskDetail.kioskName} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Employee Name</label>
                                    <input type="text" value={kioskDetail.employeeName} className='form-control font-2 ' readOnly={true} />

                                </div>

                                <div className="col-12">
                                    <label className='m-0 font-2'>Location</label>
                                    <input type="text" value={kioskDetail.location} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Timings</label>
                                    <input type="text" value={kioskDetail.timings} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Landlord Contact</label>
                                    <input type="text" value={kioskDetail.landlordContact} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Rent</label>
                                    <input type="text" value={kioskDetail.monthlyRent} className='form-control font-2 ' readOnly={true} />

                                </div>
                                <div className="col-12">
                                    <label className='m-0 font-2'>Commission%</label>
                                    <input type="text" value={kioskDetail.commission} className='form-control font-2 ' readOnly={true} />

                                </div>
                            </div>




                        </div>
                    </Modal.Body>
                </Modal>


                <Modal
                    show={isProductPriceChangeOpen}
                    onHide={() => { setisProductPriceChangeOpen(false); setProductPrice([]) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <form className='p-3 h-100' onSubmit={setKioskProductPriceApi}>
                            <p className='text-center font-2 semi-bold'>Product Price</p>

                            <div className="row" >
                                <div className="col-12">
                                    <label className='m-0 font-2'>Kiosk Name</label>
                                    <input value={productPrice.kioskName} className='form-control font-2 ' readOnly={true} />

                                </div>
                                {
                                    !productPrice && <div className='d-flex flex-column align-items-center w-100'>
                                        <img className='w-75' src={noDataIcon} alt="" />
                                        <p className='font-2 semi-bold'>No product is added please add product.</p>
                                    </div>
                                }
                                {
                                    productPrice && Object.keys(productPrice).map((key, index) => {
                                        return key != 'kioskName' && <div className="col-12">
                                            <label className='m-0 font-2'>{key}</label>
                                            <input type="text" value={productPrice[key]} name={key} className={'form-control font-2 ' + (errors[key] ? 'is-invalid' : '')} onChange={handleProductChange} required />
                                            <div className="invalid-feedback">
                                                {errors[key] && errors[key]}
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                            {
                                productPrice && <div className="d-flex justify-content-end mt-2">
                                    <CancelButton onClick={(e) => { e.preventDefault(); setisProductPriceChangeOpen(false); setErrors({}) }} />
                                    <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
                                </div>
                            }






                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={isDealPriceOpen}
                    onHide={() => { setIsDealPriceOpen(false); setDealPrice([]) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

                        <form className='p-3 h-100' onSubmit={setKioskDealPriceApi}>
                            <p className='text-center font-2 semi-bold'>Deal Price</p>

                            <div className="row" >
                                <div className="col-12">
                                    <label className='m-0 font-2'>Kiosk Name</label>
                                    <input value={dealPrice.kioskName} className='form-control font-2 ' readOnly={true} />

                                </div>
                                {
                                    !dealPrice && <div className='d-flex flex-column align-items-center w-100'>
                                        <img className='w-75' src={noDataIcon} alt="" />
                                        <p className='font-2 semi-bold'>No deal is added please add deal.</p>
                                    </div>
                                }
                                {
                                    dealPrice && Object.keys(dealPrice).map((key, index) => {
                                        return key != 'kioskName' && <div className="col-12">
                                            <label className='m-0 font-2'>{key}</label>
                                            <input type="text" value={dealPrice[key]} name={key} className={'form-control font-2 ' + (errors[key] ? 'is-invalid' : '')} onChange={handleDealChange} required />
                                            <div className="invalid-feedback">
                                                {errors[key] && errors[key]}
                                            </div>
                                        </div>
                                    })
                                }

                            </div>

                            {dealPrice && <div className="d-flex justify-content-end mt-2">
                                <CancelButton onClick={(e) => { e.preventDefault(); setIsDealPriceOpen(false); setDealPrice([]); setErrors({}) }} />
                                <BlackButton text="Add" style={{ width: '70px' }} type='submit' />
                            </div>}




                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
