import React, { useState, useEffect } from 'react'


import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker'
import Loader from '../../Components/Loader/Loader'
import { subDays } from 'date-fns'
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

import { permissionsContext } from '../../App'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useContext } from 'react'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const requestInventoryUrl = axiosUrls.DailyRequestedInv


export default function InventoryRequest() {

    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [dates, setDates] = useState([subDays(new Date(), 30), new Date()])
    const [requestInventoryData, setrequestedInventoryData] = useState([])
    const [isDetail, setIsDetail] = useState(false)
    const [detailData , setDetailData] = useState()

    const [toAssignData,setToAssignData] = useState()

    const permissions = useContext(permissionsContext)

    const navigate = useNavigate()




    useEffect(() => {
        requestInventoryApi()

    }, [dates])


    useEffect(()=>{
        console.log(requestInventoryData)
    },[requestInventoryData])


    useEffect(() => {

        if (toAssignData) {
            sessionStorage.setItem('items', JSON.stringify(toAssignData.items))
            sessionStorage.setItem('inv_id', toAssignData.id)
            sessionStorage.setItem('kiosk', toAssignData.kioskName)
            navigate('/inventory/inventory')

        }
    }, [toAssignData])


    const columns = [
        {
            label: 'Kiosk Name',
            field: 'kioskName',

        },
        {
            label: 'Date',
            field: 'date',

        },
        {
            label: 'Time',
            field: 'time',

        },
        {
            label: 'Details',
            field: 'details',

        },

        permissions.includes('Can Add Daily Inventory') && {
            label: 'Action',
            field: 'action',

        },


    ].filter(Boolean)


    const requestInventoryApi = () => {
        setLoader(true);
        apiCounter++;
        axios
          .get(requestInventoryUrl + '/' + dates, {
            headers: {
              'Authorization': token
            }
          })
          .then(response => {
            console.log(response);
            const data = response.data.map((item, index) => {
              item['details'] = (
                <button
                  className='detail-btn font-2'
                  value={index}
                  onClick={() => {
                    
                    setIsDetail(true);
                    setDetailData(item)
                    
                  }}
                >
                  Details
                </button>
              );
      
              if (permissions.includes('Can Add Daily Inventory')) {
                item['action'] = item.is_assigned === 'no' ? (
                  <button
                    className='detail-btn font-2'
                    value={index}
                    onClick={() => {
                      setToAssignData(item);
                    }}
                  >
                    Assign
                  </button>
                ) : (
                  'Assigned'
                );
              }
      
              return item;
            });
      
            setrequestedInventoryData({ columns: columns, rows: data });
      
            apiCounter--;
            if (apiCounter === 0) {
              setLoader(false);
            }
          })
          .catch(error => {
            console.log(error);
            apiCounter--;
            if (apiCounter === 0) {
              setLoader(false);
            }
          });
      };


    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className="h-100">
                <div className="row reverse-col">
                    <div className="col-sm-8 my-1 desktop">
                        <span className='font-2 semi-bold'>Inventory Request</span>
                    </div>
                    <div className="col-sm-4 my-1">
                        <div className="row">
                            <div className="col-sm-10 pr-0 col-9">
                                <CustomDatePicker dates={dates} setDates={setDates} />
                            </div>
                            <div className="col-sm-2 col-3" style={{ textAlign: 'end' }}>
                                {/* <div className="d-flex justify-content-end"> */}

                                <DownloadButton data={requestInventoryData} filename="RequestedInventoryReport.csv" />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='desktop' style={{ height: '95%' }}>
                    <TableWithPagination data={requestInventoryData} />
                </div>


                <div className="mobile row py-2">
                    <div className="col">

                        <p className='font-2 semi-bold mb-1'>Inventory Request</p>
                    </div>

                    {
                        requestInventoryData.rows && requestInventoryData.rows.map((data, index) => {
                            return <div className="col-12 " >
                                <div className="mobile-item-box-div my-1" onClick={(e) => { setDetailData(data); setIsDetail(true) }}>
                                    <div className="d-flex justify-content-between py-1">
                                        <span className='font-2'>{data.kioskName}</span>
                                        {permissions.includes('Can Add Daily Inventory') && <span className='font-2'>{data.is_assigned==='yes'? 'Assigned':<button style={{ backgroundColor: 'transparent', color: '#FC526E' }} onClick={(e) => { setToAssignData(data) }}>Assign</button>}</span>}

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className='font-2'>{data.date}</span>
                                        <span className='font-2'>Time: {data.time}</span>
                                    </div>
                                </div>
                            </div>
                        })
                    }


                </div>

                <Modal
                    show={isDetail}
                    onHide={() => { setIsDetail(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered

                >

                    <Modal.Body style={{ maxHeight: "80vh", overflow: 'auto' }}>

                        <div className='p-3 h-100' >
                            <p className='text-center font-2 semi-bold'>Items Detail</p>
                            {isDetail &&
                                detailData.items.map((data, index) => {
                                    return <div className='row item-box my-2 p-2'>
                                        <div className="col-1 pr-0 d-flex align-items-center">
                                            <span className='font-2'>{index + 1}</span>
                                        </div>
                                        <div className="col-10 d-flex align-items-center">
                                            <p className='font-2 m-0'>{data.item_Name}</p>

                                        </div>
                                        <div className="col-1 pl-0 d-flex align-items-center justify-content-end">
                                            <p className='font-2 m-0 '>{data.Quantity}</p>
                                        </div>


                                    </div>
                                })

                            }



                        </div>







                    </Modal.Body>

                </Modal>
            </div>
        )
    }
}
