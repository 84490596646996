import React, { useEffect, useState } from 'react'
import './Attendance.css'
import GradientButton from '../../Components/GradientButton/GradientButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import Loader from '../../Components/Loader/Loader'
import { Modal } from "react-bootstrap";

import tickIcon from '../../Assets/tick.svg'
import crossIcon from '../../Assets/cross.svg'
import clockIcon from '../../Assets/clock-icon.svg'




import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const getAttandanceUrl = axiosUrls.Attendance


export default function Attendance() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [attendanceData, setAttendanceData] = useState([])
  const [moreData, setMoreData] = useState([])
  const [isMore, setIsMore] = useState(false)

  useEffect(() => {
    getAttandanceApi()
  }, [])

  // useEffect(() => {
  //   console.log(moreData)
  // }, [moreData])

  const assignMoreData = (data, i, key) => {

    if(Object.keys(data[i].clockData).includes(key)){

      setMoreData({ employeeName: data[i].employeeName, kioskName: data[i].kioskName, totalHours: data[i].clockData[key].totalHours, clockTimes: data[i].clockData[key].clockTimes, date: key })
      setIsMore(true)
    }
  }

  const getAttandanceApi = () => {
    setLoader(true)
    apiCounter++
    axios.post(getAttandanceUrl, {
    }, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response, 'response')
      const data = response.data.data

      for (let i = 0; i < data.length; i++) {
        Object.keys(data[i].attendance).map((key, index) => {
          data[i][key] = <button style={{ backgroundColor: 'transparent' }} onClick={() => { assignMoreData(data, i, key) }}>
            <img src={data[i].attendance[key] ? tickIcon : crossIcon} alt="" />
          </button>
        })

        delete data[i].attendance;

      }
      setAttendanceData({ columns: response.data.columns, rows: response.data.data })
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }
  if (loader) {
    return <Loader />
  }
  else {
    return (
      <div className="h-100">
        <div className="row">
          <div className="col-3">
            <span className='font-2 semi-bold'>Attandance</span>
          </div>

          <div className="col-9" style={{ textAlign: 'end' }}>

            <DownloadButton className="ml-3" style={{ padding: '7px' }} data={attendanceData} filename="AttendanceReport.csv" />
          </div>



        </div>
        <div style={{ height: '95%' }}>
          <TableWithPagination data={attendanceData} />
        </div>

        <Modal
          show={isMore}
          onHide={() => { setIsMore(false) }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <div className='p-3 h-100' >
              <p className='text-center font-2 semi-bold'>Attendance Details</p>

              {
                moreData && <div>

                  <div className="row" >
                    <div className="col-6">
                      <span className='font-2'>Employee Name</span>
                      <div className='item-div font-2'>{moreData.employeeName}</div>
                      <div className='font-2 py-3' style={{fontWeight:'bold'}}>Date : {moreData.date && moreData.date.replace(/\_/g,'-')}</div>
                      
                    </div>

                    <div className="col-6 pl-0">
                      <span className='font-2'>Kiosk Name</span>
                      <div className='item-div font-2'>{moreData.kioskName}</div>
                      <div className='font-2 py-3'>Activity</div>
                    </div>


                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between clock-div">
                            <span>Clock In</span> <span> {moreData.clockTimes && moreData.clockTimes[0].clock_in_time.replace(' ', ' | ')}</span>
                            
                          </div>
                        </div>
                        <div className="col-12 text-center d-flex justify-content-center my-3" >
                          <div className={'time-circle ' + (moreData.clockTimes && (moreData.clockTimes.length===0 || moreData.clockTimes[moreData.clockTimes.length-1].clock_out_time === null?'bg-danger text-white':'')) }>
                           
                            {
                              moreData.clockTimes && (moreData.clockTimes.length===0 || moreData.clockTimes[moreData.clockTimes.length-1].clock_out_time === null ?"didn't clock out":moreData.totalHours && moreData.totalHours.replace(':',' hrs ')+' min')
                            }
                            
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-between clock-div">
                            <span>Clock Out</span> <span>{moreData.clockTimes && moreData.clockTimes[moreData.clockTimes.length - 1].clock_out_time && moreData.clockTimes[moreData.clockTimes.length - 1].clock_out_time.replace(' ', ' | ')}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 activity-div p-3">
                      {
                        moreData.clockTimes && moreData.clockTimes.map((data, index) => {
                          return <div>
                            <div className="clock-stauts-div ">
                              <div className="circle"></div>
                              <span className='font-2 pl-1'>Clock In</span>
                              <div className="d-flex justify-content-between align-items-center">
                                <span className='col-6 d-flex align-items-center'><img className='px-1' src={clockIcon} alt="" /> {data.clock_in_time && data.clock_in_time.replace(' ', ' | ')}</span>
                                <span className='text-truncate'>{data.clock_in_location}</span>
                              </div>
                            </div>
                            {data.clock_out_time && <div className="clock-stauts-div py-2">
                              <div className="circle"></div>
                              <span className='font-2 pl-1'>Clock Out</span>
                              <div className="d-flex justify-content-between">
                                <span className='col-6 d-flex align-items-center'><img className='px-1' src={clockIcon} alt="" /> {data.clock_out_time && data.clock_out_time.replace(' ', ' | ')}</span>
                                {/* <span className='col-6 text-truncate'>{data.clock_in_location}</span> */}
                              </div>
                            </div>
                        }
                          </div>
                        })
                      }






                    </div>
                  </div>



                </div>
              }






            </div>
          </Modal.Body>
        </Modal>


      </div>
    )
  }
}
