import React, { useState } from 'react'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import Loader from '../../Components/Loader/Loader'
import Swal from 'sweetalert2'

import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'
import { useEffect } from 'react'

var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;

const getPercentageUrl = axiosUrls.getPercentage
const setPercentageUrl = axiosUrls.setPercentage


export default function SetPercentage() {
    const [loader, setLoader] = useState(false)
    let apiCounter = 0

    const [percentageData, setPercentageData] = useState([])
    const [errors, setErrors] = useState([])
    const [totalPercentage, setTotalPercentage] = useState(0)

    useEffect(() => {
        getPercentageApi()
    }, [])

    useEffect(()=>{
        

            var total = 0
            Object.keys(percentageData).map((key,index)=>{
                total += parseInt(percentageData[key])
            })
            setTotalPercentage(total)
        
    },[percentageData])


    const handlePercentageChagne =(e)=>{
        
        const {name,value} = e.target

        setPercentageData(prevState =>({
            ...prevState,
            [name]:value
        }))
    }

    const getPercentageApi = () => {
        setLoader(true)
        apiCounter++
        axios.get(getPercentageUrl, {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            console.log(response)
            setPercentageData(response.data)

            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        }).catch(error => {
            console.log(error)
            apiCounter--
            if (apiCounter === 0) {
                setLoader(false)
            }

        })
    }

    const setPercentageApi = (e) =>{
        e.preventDefault()
        setLoader(true)
        apiCounter++
        axios.post(setPercentageUrl,percentageData,{
            headers:{
                'Authorization' : token
            }
        }).then(response => {
            console.log(response)
            setErrors([])
            Swal.fire(
                'Success',
                'New percentage is set.',
                'success'
              )
            apiCounter--
            if(apiCounter === 0){
                setLoader(false)
            }
        }).catch(error =>{
            console.log(error)

            apiCounter --
            if(apiCounter === 0){
                setLoader(false)
            }
            if(error.response.status === 422){
                setErrors(error.response.data.errors)
            }
            else{
                setErrors([])
            }
        })
    }
    if (loader) {
        return <Loader />
    }
    else {
        return (
            <div className='h-100'>
                <div className="row">
                    <div className="col-6 desktop">
                        <span className='font-2 semi-bold'>Set Product Percentage</span><br />

                        
                    </div>

                    <div className="col-sm-6" style={{ textAlign: 'end' }}>
                        {/* <BlackButton text="Add Category" className="mr-3 font-6" style={{padding:'7px'}}/> */}
                        <div className='d-flex justify-content-end'>
                            {/* <DownloadButton className="ml-3" /> */}
                        </div>
                    </div>



                </div>
                <form className='my-4' onSubmit={setPercentageApi}>
                        <div className='font-2 semi-bold my-3 '  >Total percentage = {totalPercentage} out of 100 {errors['total_percentage'] && <div className='text-danger'>{errors['total_percentage']}</div>}</div>
                        
                <div className="row">
                    {
                        Object.keys(percentageData).map((key,index)=> {

                            return <div className="col-4">
                                <label htmlFor={key}>{key}</label>
                                <input type="text" name={key} value={percentageData[key]} onChange={handlePercentageChagne} className={'form-control font-2 ' +(errors[key]?'is-invalid':'')} />
                                <div className="invalid-feedback">{errors[key] && errors[key]}</div>
                            </div>
                        })
                    }
                </div>
                <div className='d-flex justify-content-end my-3'>
                    <BlackButton text="Submit" type="submit" style={{padding:'5px'}} />
                </div>
                </form>
            </div>
        )
    }

}
