import React, { useState, useEffect } from 'react'
import GradientButton from '../../Components/GradientButton/GradientButton'
import BlackButton from '../../Components/BlackButton/BlackButton'
import DownloadButton from '../../Components/DownloadButton/DownloadButton'
import TableWithPagination from '../../Components/TableWithPagination/TableWithPagination'
import Loader from '../../Components/Loader/Loader'


import axios from '../../Api/Axios'
import * as axiosUrls from '../../Api/AxiosUrls'


var tok = localStorage.getItem('dwf-token')
var token = 'Bearer ' + tok;


const liveProductSaleUrl = axiosUrls.liveProductSale

export default function ProductSale() {
  const [loader, setLoader] = useState(false)
  let apiCounter = 0

  const [liveProductSaleData, setLiveProductSaleData] = useState([])


  useEffect(() => {
    liveProductSaleApi()
  }, [])

  const columns = [
    {
      label: 'Product Name',
      field: 'productName',
    }, {
      label: 'Kiosk Name',
      field: 'kioskName',
    }, {
      label: 'Time',
      field: 'time'
    }, {
      label: 'Quantity',
      field: 'quantity'
    }
  ]

  const liveProductSaleApi = () => {
    setLoader(true)
    apiCounter++
    axios.get(liveProductSaleUrl, {
      headers: {
        'Authorization': token
      }
    }).then(response => {
      console.log(response)
      setLiveProductSaleData({ columns: columns, rows: response.data })
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    }).catch(error => {
      console.log(error)
      apiCounter--
      if (apiCounter === 0) {
        setLoader(false)
      }
    })
  }
  if (loader) {
    return <Loader />
  }
  else {
    return (
      <div className="h-100">
        <div className="row">
          <div className="col-9">
            <span className='font-2 semi-bold'>Live Products Sale</span>
          </div>

          <div className="col-3" style={{ textAlign: 'end' }}>

            <DownloadButton className="ml-3" data={liveProductSaleData} filename='LiveProductSaleReport.csv' />
          </div>



        </div>
        <div className='desktop' style={{ height: '95%' }}>
          <TableWithPagination data={liveProductSaleData} />
        </div>
        <div className="mobile row py-2">
          <div className="col">
            <p className='font-2 semi-bold mb-1'>Deals List</p>
          </div>

          {
            liveProductSaleData.rows && liveProductSaleData.rows.map((data, index) => {
              return <div className="col-12 " >
                <div className='mobile-item-box-div my-1'>
                  <div className="d-flex justify-content-between py-1">
                    <span className='font-2'>{data.productName}</span>
                    <span className='font-2 d-flex '>
                      Qunatity: {data.quantity}
                    </span>

                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='font-2'>{data.kioskName}</span>
                    <span className='font-2'>{data.time}</span>
                  </div>
                </div>

              </div>
            })
          }


        </div>
      </div>
    )
  }
}
